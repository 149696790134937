// Note: these SVG icons need to be set with a viewBox="0 0 13 13"
// TODO: Check if easy not to have this constraint? Doesn't matter much though...
const inProgressIconPaths = {
  circle:
    "M7.195 0.287109V1.48711C9.829 1.81111 11.695 4.20511 11.371 6.83911C11.095 9.02311 9.379 10.7451 7.195 11.0151V12.2151C10.495 11.8551 12.865 8.89111 12.505 5.59711C12.193 2.80111 9.991 0.587109 7.195 0.287109ZM5.995 0.287109C4.819 0.395109 3.709 0.857109 2.797 1.60711L3.655 2.53111C4.327 1.99111 5.137 1.64311 5.995 1.52311V0.287109ZM1.951 2.48911C1.195 3.40111 0.739 4.51111 0.625 5.68711H1.825C1.939 4.83511 2.275 4.02511 2.809 3.34711L1.951 2.48911ZM0.631 6.88711C0.751 8.06311 1.213 9.17311 1.957 10.0851L2.809 9.22711C2.281 8.54911 1.939 7.73911 1.831 6.88711H0.631ZM3.631 10.1091L2.797 10.9311C3.703 11.6871 4.819 12.1611 5.995 12.2871V11.0871C5.143 10.9791 4.333 10.6371 3.655 10.1091H3.631Z",
  man: "M7.30598 4.08943C7.66086 4.08943 7.95714 3.7899 7.95714 3.43827C7.95714 3.07362 7.66086 2.78711 7.30598 2.78711C6.94459 2.78711 6.65482 3.07362 6.65482 3.43827C6.65482 3.7899 6.94459 4.08943 7.30598 4.08943ZM6.13063 8.6085L6.45621 7.18246L7.14319 7.83362V9.78711H7.79435V7.34525L7.10738 6.69409L7.30598 5.71734C7.72598 6.20571 8.37714 6.5313 9.09668 6.5313V5.88013C8.47482 5.88013 7.95714 5.55455 7.69342 5.09223L7.36784 4.57781C7.23761 4.37595 7.04226 4.25223 6.81761 4.25223C6.71668 4.25223 6.65482 4.27827 6.55389 4.27827L4.86412 4.99455V6.5313H5.51528V5.41781L6.09807 5.1899L5.57714 7.83362L3.9818 7.50804L3.85156 8.1592L6.13063 8.6085Z",
  complete:
    "M6.80643 0.425781V0.437799V1.6396C9.44439 1.96409 11.3132 4.36169 10.9887 6.99964C10.7123 9.18692 8.99371 10.9235 6.80643 11.1819V12.3837C10.1114 12.0532 12.515 9.12082 12.1845 5.81587C11.9141 2.96159 9.6487 0.708205 6.80643 0.425781ZM5.60463 0.443808C4.43287 0.55798 3.3152 1.00866 2.40183 1.76579L3.26111 2.65513C3.93412 2.11431 4.74534 1.76579 5.60463 1.64561V0.443808ZM1.55455 2.61306C0.797419 3.52643 0.346743 4.6381 0.226562 5.81587H1.42837C1.54254 4.96259 1.87904 4.15137 2.41384 3.47235L1.55455 2.61306ZM8.30868 4.31361L5.37629 7.24601L4.10238 5.9721L3.46542 6.60906L5.37629 8.51992L8.94564 4.95057L8.30868 4.31361ZM0.232572 7.01767C0.352752 8.19544 0.815446 9.3071 1.56056 10.2205L2.41384 9.36118C1.88505 8.68217 1.54254 7.87095 1.43437 7.01767H0.232572ZM3.26111 10.2445L2.40183 11.0677C3.30919 11.8249 4.42686 12.2996 5.60463 12.4258V11.224C4.75135 11.1158 3.94013 10.7733 3.26111 10.2445Z",
};

export default inProgressIconPaths;
