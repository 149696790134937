import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import MissionOverview from "layouts/missions/VelocityMission/components/MissionOverview";
import MissionDataGrid from "layouts/missions/VelocityMission/components/MissionDataGrid";

import KpiPrSizeCard from "layouts/projects/components/cards/KpiPrSizeCard";
import darkModeColors from "assets/theme-dark/base/colors";
import TeamAvatarName from "components_si/TeamAvatarName";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Subtitle from "components_si/Subtitle";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import SvgIcon from "@mui/material/SvgIcon";
import InfoButton from "layouts/projects/components/InfoButton";
import services from "utils/services";
import inProgressIconPaths from "assets/images/small-logos/in-progress-icon-paths";
import { mdiCalendarStar } from "@mdi/js";
import dayjs from "dayjs";

const { text, background, border, success, info } = darkModeColors;

// Move this to a utils function OR make a template for the missions
function getMissionStatus(startDate, endDate) {
  const currentDay = dayjs().valueOf();
  const missionStart = dayjs(startDate).valueOf();
  const missionEnd = dayjs(endDate).valueOf();

  if (currentDay < missionStart) {
    return {
      icon: <path d={inProgressIconPaths.circle} fill={info.main} />,
      label: "Not Started",
      calendarColor: success.main,
    };
  } else if (currentDay <= missionEnd) {
    return {
      icon: (
        <>
          <path d={inProgressIconPaths.circle} fill={success.main} />
          <path d={inProgressIconPaths.man} fill={success.main} />
        </>
      ),
      label: "In Progress",
      calendarColor: success.main,
    };
  } else {
    return {
      icon: <path d={inProgressIconPaths.complete} fill={success.main} />,
      label: "Completed",
      calendarColor: success.main,
    };
  }
}

function VelocityMission({ teamId, service, teamName, avatarColor, configuration }) {
  const missionStatus = getMissionStatus(configuration.startDate, configuration.endDate);
  const includeMonth =
    dayjs(configuration.startDate).month() === dayjs(configuration.endDate).month();

  return (
    <DashboardLayout>
      <Grid container>
        <Grid
          item
          sx={{
            pt: 3.25,
            pl: 3,
            borderBottom: 0.5,
            borderLeft: 0.5,
            borderColor: border.light,
          }}
        >
          <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={2}>
            <TeamAvatarName teamName={teamName} avatarColor={avatarColor} />
            <MDTypography variant="h2">{teamName} &gt; Mission &gt; Velocity Mastery</MDTypography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ py: 2, alignItems: "center" }}>
            <Tooltip title="Mission state">
              <Chip
                icon={
                  <SvgIcon viewBox="0 0 13 13" fontSize="medium">
                    {missionStatus.icon}
                  </SvgIcon>
                }
                sx={{ px: 0.5, color: text.subtitle, backgroundColor: background.hover }}
                label={missionStatus.label}
              />
            </Tooltip>
            <Tooltip title="Mission duration">
              {/* TODO: ARTIST_CONNECT_CHANGE */}
              <Chip
                icon={
                  <SvgIcon fontSize="medium">
                    <path d={mdiCalendarStar} fill={missionStatus.calendarColor} />
                  </SvgIcon>
                }
                sx={{ px: 0.5, color: text.subtitle, backgroundColor: background.hover }}
                label={`${dayjs(configuration.startDate).format("MMM D")} - ${dayjs(
                  configuration.endDate
                ).format(includeMonth ? "D" : "MMM D")}`}
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Subtitle>Overview</Subtitle>
        </Grid>
        <Grid item xs={6}>
          <MissionOverview target={configuration.target} />
        </Grid>
        <Grid item xs={6}>
          <KpiPrSizeCard teamId={teamId} service={service} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            px: 3,
            borderLeft: 0.5,
            borderColor: border.light,
            backgroundColor: background.subtitle,
          }}
        >
          {/* TODO: Consider making a title component -- right now
        these titles are a bit inconsistent in terms of height because some have icons in them and some don't
        https://github.com/setorihats/setori-insights/pull/699 */}
          <Stack
            direction="row"
            sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            <MDTypography color="info" variant="h3">
              Team
            </MDTypography>
            <Stack direction="row">
              <Box
                sx={{
                  py: 0.5,
                  pl: 0.5,
                }}
              >
                <InfoButton
                  title="Mission metrics"
                  message={
                    <>
                      <MDTypography variant="body2" gutterBottom>
                        The velocity mastery mission focuses on:
                      </MDTypography>
                      <MDTypography variant="body2" gutterBottom>
                        <Box px={2}>
                          <List sx={{ listStyleType: "disc" }}>
                            <ListItem sx={{ display: "list-item" }}>
                              <strong># of PRs:</strong> The number of pull requests opened
                            </ListItem>
                            <ListItem sx={{ display: "list-item" }}>
                              <strong>PR size:</strong> The average size of pull requests opened.
                              The size of a PR is the sum of all lines of code added or deleted.
                            </ListItem>
                          </List>
                        </Box>
                      </MDTypography>
                      <MDTypography variant="body2" gutterBottom>
                        This table will only include data for PRs that were opened between the
                        mission start and end date
                      </MDTypography>
                    </>
                  }
                />
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <MissionDataGrid
            teamId={teamId}
            service={service}
            startDate={dayjs(configuration.startDate).format("YYYY-MM-DD")}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

// Typechecking props
VelocityMission.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  teamName: PropTypes.string.isRequired,
  avatarColor: PropTypes.string.isRequired,
  configuration: PropTypes.exact({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    target: PropTypes.number,
  }).isRequired,
};

export default VelocityMission;
