import darkModeColors from "assets/theme-dark/base/colors";

const { secondary, success, warning, error } = darkModeColors;

// The higher the value, the worse the risk level
const Risk = {
  Good: 0, // Green
  Unknown: 1, // Grey
  Warning: 2, // Yellow
  Critical: 3, // Red
};

// Badge colors as defined by colors.js theme badgeColors
function riskToBadgeColor(risk) {
  switch (risk) {
    case Risk.Good:
      return success.main;
    case Risk.Unknown:
      return secondary.main;
    case Risk.Warning:
      return warning.main;
    case Risk.Critical:
      return error.main;
    default:
      console.error("unsupported risk type");
      return secondary.main;
  }
}

/**
 * @param {*} riskBreakdown - an ordered list of objects with at least a
 * `riskLevel` key whose value corresponds to a {@link RiskEnum}.
 * The order is based on how important the different risks are.
 * @returns The index of the overall story risk driver (which is just the highest
 * risk color item we find. If there are multiple that are the highest, we pick
 * the first one which is why the order matters)
 */
function getOverallRiskDriver(riskBreakdown) {
  // assert(riskBreakdown.length > 0);
  let highestRiskIdxSoFar = 0;
  for (let i = 1; i < riskBreakdown.length; i++) {
    // If current risk is higher than the highest so far risk, set highest to current
    if (riskBreakdown[i].riskLevel > riskBreakdown[highestRiskIdxSoFar].riskLevel) {
      highestRiskIdxSoFar = i;
    }
  }
  return highestRiskIdxSoFar;
}

export { Risk, getOverallRiskDriver, riskToBadgeColor };
