// Base Styles
import colors from "assets/theme-dark/base/colors";

const { primary, text } = colors;

const badge = {
  styleOverrides: {
    badge: {
      backgroundColor: primary.main,
      color: text.title,
    },
  },
};

export default badge;
