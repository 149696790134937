import dayjs from "dayjs";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import darkModeColors from "assets/theme-dark/base/colors";

// Utilities
import { useApi } from "utils/apiUtils";
import SIDataGrid from "components_si/SIDataGridPro";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";
import { MetricsEnum, TiersEnum, evaluateTier } from "utils/tierUtils";

import AuthorAvatarName from "components_si/AuthorAvatarName";
import services from "utils/services";

const { icon, success, warning, error } = darkModeColors;

function formatSecondsWithTierBackground(data, type) {
  const status = evaluateTier(data, type);
  if (status === TiersEnum.STier) {
    return (
      <Stack direction="row">
        {/* TODO: Think about making visibility hidden dynamic (e.g., based on user choice, or smartly not to overwhelm user with too many dots) */}
        {/* <Box visibility="hidden"> */}
        <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="3" fill={success.main} />
        </svg>
        {/* </Box> */}
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  if (status === TiersEnum.Average) {
    return (
      <Stack direction="row">
        <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="3" fill={warning.main} />
        </svg>
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  if (status === TiersEnum.Poor) {
    return (
      <Stack direction="row">
        {/* TODO: Think about making visibility hidden dynamic (e.g., based on user choice, or smartly not to overwhelm user with too many dots) */}
        <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="3" fill={error.main} />
        </svg>
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  if (status === TiersEnum.None) {
    return (
      <Stack direction="row">
        <Box visibility="hidden">
          {/* TODO: Think about making visibility hidden dynamic (e.g., based on user choice, or smartly not to overwhelm user with too many dots) */}
          <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="3" fill={icon.main} />
          </svg>
        </Box>
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  return <Box>Loading...</Box>;
}

export default function CycleTimeDataGrid({ teamId, service, filtInput }) {
  const { data, loading } = useApi({
    url: `/api/teams/${teamId}/prs_computed_table?service=${service}`,
    defaultData: [],
  });

  const columns = [
    {
      field: "pr_author_name",
      headerName: "Author",
      flex: 1,
      minWidth: 180,
      maxWidth: 250,
      renderCell: (params) => (
        <AuthorAvatarName
          author={params.row.pr_author_name}
          authorUrl={params.row.pr_author_url}
          authorAvatarUrl={params.row.pr_author_avatar_url}
        />
      ),
    },
    // TODO: Currently not using this (April 19th, 2023). Remove at some point if not useful in the future
    // {
    //   field: "date_week_start",
    //   headerName: "Week PR created",
    //   flex: 1,
    //   minWidth: 180,
    //   maxWidth: 200,
    // },
    {
      field: "pr_created_at",
      headerName: "Date",
      type: "date",
      flex: 1,
      minWidth: 180,
      maxWidth: 200,
      renderCell: (params) =>
        params.row.pr_created_at !== null
          ? dayjs(params.row.pr_created_at).format("YYYY-MM-DD [at] HH:mm")
          : "",
    },
    {
      field: "pr_title",
      headerName: "Title",
      flex: 1,
      minWidth: 400,
      maxWidth: 500,
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params) => params.row.state.charAt(0).toUpperCase() + params.row.state.slice(1),
    },
    {
      field: "pr_lines_changed",
      headerName: "Additions & deletions",
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params) => params.row.pr_lines_changed?.toLocaleString("en-US"), // Formatting for the cell in the DataGrid
    },
    {
      field: "pr_comments",
      headerName: "Comments",
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params) => params.row.pr_comments?.toLocaleString("en-US"), // Formatting for the cell in the DataGrid
    },
    {
      field: "coding_time",
      headerName: "Coding time",
      flex: 1,
      minWidth: 120,
      maxWidth: 200,
      type: "number", // Need to specify that valueGetter returns a number for sorting
      headerAlign: "left", // Need to explicitly align left, since type: "number" aligns right
      align: "left", // Need to explicitly align left, since type: "number" aligns right
      valueGetter: (params) => params.row.coding_time_epoch, // Value used for sorting and export
      // TODO: Commented out for now, as it is better to have seconds in export. In the future, want to have both formatted column AND seconds in the column. Goal would be to use something like this: https://codesandbox.io/s/kcfz39?file=/demo.tsx (controlled visible columns) and have extra columns that are only shown on export. Unfortunately, can't import GridColumnVisibilityModel currently; MUI might be broken
      // valueFormatter: (params) => convertMillisecondsToReadableTime(params.value * 1000), // Formatting the value for csv export
      renderCell: (params) =>
        formatSecondsWithTierBackground(params.row.coding_time_epoch, MetricsEnum.CodingTime), // Formatting for the cell in the DataGrid
    },
    {
      field: "pickup_time",
      headerName: "Pickup time",
      flex: 1,
      minWidth: 120,
      maxWidth: 200,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => params.row.pickup_time_epoch,
      // valueFormatter: (params) => convertMillisecondsToReadableTime(params.value * 1000),
      renderCell: (params) =>
        formatSecondsWithTierBackground(params.row.pickup_time_epoch, MetricsEnum.PickupTime),
    },
    {
      field: "review_time",
      headerName: "Review time",
      flex: 1,
      minWidth: 120,
      maxWidth: 200,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => params.row.review_time_epoch,
      // valueFormatter: (params) => convertMillisecondsToReadableTime(params.value * 1000),
      renderCell: (params) =>
        formatSecondsWithTierBackground(params.row.review_time_epoch, MetricsEnum.ReviewTime),
    },
    {
      field: "cycle_time",
      headerName: "Total cycle time",
      flex: 1,
      minWidth: 120,
      maxWidth: 200,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => params.row.cycle_time_epoch,
      valueFormatter: (params) => convertMillisecondsToReadableTime(params.value * 1000),
      renderCell: (params) =>
        formatSecondsWithTierBackground(params.row.cycle_time_epoch, MetricsEnum.CycleTime),
    },
    {
      field: "base_ref",
      headerName: "Base Ref",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => params.row.base_ref,
    },
    {
      field: "head_ref",
      headerName: "Head Ref",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.head_ref,
    },
  ];

  // If the filter is null, do not render the component
  if (!filtInput) {
    return null;
  }
  return (
    <SIDataGrid
      showExportAndFilter
      columns={columns}
      rows={data}
      onRowClick={(params) => window.open(params.row.pr_url)}
      initialState={{
        filter: {
          filterModel: {
            items: filtInput,
          },
        },
        columns: {
          columnVisibilityModel: {
            base_ref: false,
            head_ref: false,
          },
        },
      }}
      sx={{ border: 0 }}
      loading={loading}
    />
  );
}

CycleTimeDataGrid.defaultProps = {
  filtInput: [],
};

CycleTimeDataGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  filtInput: PropTypes.arrayOf(PropTypes.object),
};
