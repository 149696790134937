import { useState } from "react";

import PropTypes from "prop-types";
import { Chip, Menu, MenuItem, Tooltip } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

export default function ChipDropdown({ items, selectedItem, onChange, tooltipText }) {
  const [anchorElement, setAnchorElement] = useState(null);
  const handleClick = (item) => {
    if (onChange !== undefined) {
      setAnchorElement(null);
      onChange(item.value);
    }
  };

  return (
    <>
      <Tooltip title={tooltipText !== undefined ? tooltipText(`${selectedItem}`) : ""}>
        <Chip
          label={`${items && items.find((item) => item.value === `${selectedItem}`)?.label}`}
          variant="outlined"
          color="primary"
          size="small"
          onClick={items.length === 1 ? undefined : (e) => setAnchorElement(e.currentTarget)}
          onDelete={items.length === 1 ? undefined : (e) => setAnchorElement(e.currentTarget)}
          deleteIcon={<KeyboardArrowDown />}
          sx={{
            fontSize: "14px",
            fontFamily: "Roboto",
            marginRight: "12px",
          }}
        />
      </Tooltip>
      {items.length > 1 && (
        <Menu
          anchorEl={anchorElement}
          open={anchorElement !== null}
          onClose={() => setAnchorElement(null)}
        >
          {items.map((item) => (
            <MenuItem value={item.value} key={item.value} onClick={() => handleClick(item)}>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}

ChipDropdown.defaultProps = {
  items: [
    { label: "weekly", value: "week" },
    { label: "monthly", value: "month" },
  ],
  selectedItem: "week",
  onChange: undefined,
  tooltipText: undefined,
};

ChipDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selectedItem: PropTypes.string,
  onChange: PropTypes.func,
  tooltipText: PropTypes.func,
};
