/**
 * Layout for the integrations page
 */

import PropTypes from "prop-types";

import { useApi, useApiFetch } from "utils/apiUtils";
import IntegrationCard from "layouts/integrations/components/IntegrationCard";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import getAuthURL, { GITHUB_OAUTH_APP_CONFIG } from "utils/oauthUtils";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import darkModeColors from "assets/theme-dark/base/colors";

// Images
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
import { mdiGithub, mdiJira, mdiCalendar, mdiGitlab } from "@mdi/js";
import shortcutIcon from "assets/images/small-logos/shortcut";

const { border } = darkModeColors;

const IS_DELETION_ENABLED = process.env.NODE_ENV === "development";

// Perhaps should use for these: https://www.npmjs.com/package/validator
const jiraUrlRegex = /^(https:\/\/)?[^/. ]+\.atlassian\.net$/;
// const emailRegex = /^[\w._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/im;
const urlRegex = /^(https:\/\/)?[^/ ]+$/;

function GitlabIntegrationCard({ isConnected, onUpdateIntegration }) {
  const { apiFetch } = useApiFetch();
  const [shouldShowMessage, setShouldShowMessage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [token, setToken] = useState(null);
  const [serverUrl, setServerUrl] = useState(null);

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = (_event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setToken(null);
    setServerUrl(null);
    setIsDialogOpen(false);
  };

  const handleSubmit = async (event) => {
    // Don't reload the page on submit
    event.preventDefault();

    if ((serverUrl != null && serverUrl !== "" && !serverUrl.match(urlRegex)) || token == null) {
      // TODO: should show some indication of why you can't submit (required fields)
      return;
    }

    await apiFetch("/api/integrations?service=gitlab", {
      method: "POST",
      body: new URLSearchParams(
        serverUrl == null
          ? { token }
          : {
              token,
              server_url: serverUrl,
            }
      ),
    });

    if (onUpdateIntegration) {
      onUpdateIntegration();
    }

    setToken(null);
    setServerUrl(null);
    setIsDialogOpen(false);
  };

  const handleClick = async () => {
    if (isConnected) {
      if (IS_DELETION_ENABLED) {
        await apiFetch("/api/integrations?service=gitlab", {
          method: "DELETE",
        });
        if (onUpdateIntegration) {
          onUpdateIntegration();
        }
      } else {
        setShouldShowMessage(true);
      }
    } else {
      handleClickOpen();
    }
  };

  return (
    <>
      <IntegrationCard
        title="Gitlab"
        subtitle={isConnected ? "Connected" : "Connect Now"}
        icon={mdiGitlab}
        description="Git management service"
        onClick={handleClick}
      />
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <DialogTitle>Configure Gitlab</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To generate an API token, follow{" "}
              <b>
                <a
                  href="https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html#create-a-personal-access-token"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontWeight: "bold", color: "white" }}
                >
                  these steps
                </a>
              </b>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Gitlab Personal Access Token"
              type="password"
              fullWidth
              variant="outlined"
              onChange={(event) => setToken(event.target.value)}
            />
            <DialogContentText pt={3}>
              If you host a self-managed gitlab server, then you need to provide your gitlab server
              url (e.g., https://gitlab.setori.ai)
            </DialogContentText>
            <TextField
              margin="dense"
              id="serverUrl"
              label="Gitlab Server URL (optional)"
              fullWidth
              variant="outlined"
              error={serverUrl && !serverUrl.match(urlRegex)}
              helperText={
                serverUrl == null || serverUrl === "" || serverUrl.match(urlRegex)
                  ? ""
                  : "Please enter a valid server url (e.g., https://gitlab.setori.ai)"
              }
              onChange={(event) => {
                setServerUrl(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
      <MDSnackbar
        icon="notifications"
        title="Contact us"
        content={
          <span>
            To offboard integrations, please contact us at{" "}
            <a href="mailto:info@setori.ai" style={{ color: "white" }}>
              <b>info@setori.ai</b>
            </a>
          </span>
        }
        open={shouldShowMessage}
        onClose={() => {
          setShouldShowMessage(false);
        }}
        close={() => {
          setShouldShowMessage(false);
        }}
      />
    </>
  );
}

GitlabIntegrationCard.defaultProps = {
  onUpdateIntegration: null,
};

GitlabIntegrationCard.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  onUpdateIntegration: PropTypes.func,
};

function ShortcutIntegrationCard({ isConnected, onUpdateIntegration }) {
  const { apiFetch } = useApiFetch();
  const [shouldShowMessage, setShouldShowMessage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [token, setToken] = useState(null);

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = (_event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setToken(null);
    setIsDialogOpen(false);
  };

  const handleSubmit = async (event) => {
    // Don't reload the page on submit
    event.preventDefault();

    if (token == null) {
      return;
    }

    await apiFetch("/api/integrations?service=shortcut", {
      method: "POST",
      body: new URLSearchParams({ token }),
    });

    if (onUpdateIntegration) {
      onUpdateIntegration();
    }

    setToken(null);
    setIsDialogOpen(false);
  };

  const handleClick = async () => {
    if (isConnected) {
      if (IS_DELETION_ENABLED) {
        await apiFetch("/api/integrations?service=shortcut", {
          method: "DELETE",
        });
        if (onUpdateIntegration) {
          onUpdateIntegration();
        }
      } else {
        setShouldShowMessage(true);
      }
    } else {
      handleClickOpen();
    }
  };

  return (
    <>
      <IntegrationCard
        title="Shortcut"
        subtitle={isConnected ? "Connected" : "Connect Now"}
        icon={shortcutIcon}
        description="Docs, issue-tracking, and sprint planning"
        onClick={handleClick}
      />
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <DialogTitle>Configure Shortcut</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To generate an API token, go{" "}
              <b>
                <a
                  href="https://app.shortcut.com/settings/account/api-tokens"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontWeight: "bold", color: "white" }}
                >
                  here
                </a>
              </b>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Shortcut Access Token"
              type="password"
              fullWidth
              variant="outlined"
              onChange={(event) => setToken(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
      <MDSnackbar
        icon="notifications"
        title="Contact us"
        content={
          <span>
            To offboard integrations, please contact us at{" "}
            <a href="mailto:info@setori.ai" style={{ color: "white" }}>
              <b>info@setori.ai</b>
            </a>
          </span>
        }
        open={shouldShowMessage}
        onClose={() => {
          setShouldShowMessage(false);
        }}
        close={() => {
          setShouldShowMessage(false);
        }}
      />
    </>
  );
}

ShortcutIntegrationCard.defaultProps = {
  onUpdateIntegration: null,
};

ShortcutIntegrationCard.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  onUpdateIntegration: PropTypes.func,
};

function JiraIntegrationCard({ isConnected, onUpdateIntegration }) {
  const { apiFetch } = useApiFetch();
  const [shouldShowMessage, setShouldShowMessage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [hostname, setHostname] = useState(null);

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = (_event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    setToken(null);
    setEmail(null);
    setHostname(null);
    setIsDialogOpen(false);
  };

  const handleSubmit = async (event) => {
    // Don't reload the page on submit
    event.preventDefault();

    if (hostname == null || !hostname.match(jiraUrlRegex) || email == null) {
      return;
    }

    await apiFetch("/api/integrations?service=jira", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        email,
        hostname,
      }),
    });

    if (onUpdateIntegration) {
      onUpdateIntegration();
    }

    setToken(null);
    setEmail(null);
    setHostname(null);
    setIsDialogOpen(false);
  };

  const jiraOnClick = async () => {
    if (isConnected) {
      if (IS_DELETION_ENABLED) {
        await apiFetch("/api/integrations?service=jira", {
          method: "DELETE",
        });
        if (onUpdateIntegration) {
          onUpdateIntegration();
        }
      } else {
        setShouldShowMessage(true);
      }
    } else {
      handleClickOpen();
    }
  };

  return (
    <>
      <IntegrationCard
        title="Jira"
        subtitle={isConnected ? "Connected" : "Connect Now"}
        icon={mdiJira}
        description="Issue-tracking and sprint planning"
        onClick={jiraOnClick}
      />
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <DialogTitle>Configure Jira</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Find or create your personal access token in{" "}
              <a
                href="https://id.atlassian.com/manage-profile/security/api-tokens"
                target="_blank"
                rel="noreferrer"
                style={{ fontWeight: "bold", color: "white" }}
              >
                Atlassian Settings
              </a>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Jira Access Token"
              type="password"
              fullWidth
              variant="outlined"
              onChange={(event) => setToken(event.target.value)}
            />
            <DialogContentText pt={3}>
              The email address you use for your Jira account
            </DialogContentText>
            <TextField
              margin="dense"
              id="name"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              onChange={(event) => setEmail(event.target.value)}
            />
            <DialogContentText pt={3}>Jira installation or cloud hostname</DialogContentText>
            <TextField
              margin="dense"
              id="name"
              label="Hostname"
              type="text"
              placeholder="your-company.atlassian.net"
              fullWidth
              variant="outlined"
              error={hostname && !hostname.match(jiraUrlRegex)}
              helperText={
                hostname && !hostname.match(jiraUrlRegex)
                  ? "Please enter a valid atlassian.net url (e.g., setori.atlassian.net)"
                  : ""
              }
              onChange={(event) => {
                let url = event.target.value;
                if (!url.startsWith("https://")) {
                  url = `https://${url}`;
                }
                setHostname(url);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
      <MDSnackbar
        icon="notifications"
        title="Contact us"
        content={
          <span>
            To offboard integrations, please contact us at{" "}
            <a href="mailto:info@setori.ai" style={{ color: "white" }}>
              <b>info@setori.ai</b>
            </a>
          </span>
        }
        open={shouldShowMessage}
        onClose={() => {
          setShouldShowMessage(false);
        }}
        close={() => {
          setShouldShowMessage(false);
        }}
      />
    </>
  );
}

JiraIntegrationCard.defaultProps = {
  onUpdateIntegration: null,
};

JiraIntegrationCard.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  onUpdateIntegration: PropTypes.func,
};

function Integrations() {
  const { user } = useAuth0();
  const { apiFetch } = useApiFetch();
  const { data: integrationsData, refresh } = useApi({ url: "/api/integrations" });

  const [shouldShowMessage, setShouldShowMessage] = useState(false);

  const isGithubOAuthAppIntegrationConnected = integrationsData?.github === "connected";
  const githubAppConnectedState = integrationsData?.github_app;
  // const isSlackIntegrationConnected = "slack" in integrationsData;
  const isJiraIntegrationConnected = integrationsData?.jira === "connected";
  const isShortcutIntegrationConnected = integrationsData?.shortcut === "connected";
  const isGoogleCalendarIntegrationConnected = integrationsData?.google_calendar === "connected";
  const isGitlabIntegrationConnected = integrationsData?.gitlab === "connected";

  // const slackOnClick = async () => {
  //   if (isSlackIntegrationConnected) {
  //     if (IS_DELETION_ENABLED) {
  //       await apiFetch("/api/integrations?service=slack", {
  //         method: "DELETE",
  //       });
  //       refresh();
  //     } else {
  //       setShouldShowMessage(true);
  //     }
  //   } else {
  //     // for example, auth0|61342c348923de (though, I think this changes if we add some things like login with google)
  //     // TODO: can we do this without passing in the userid just with the access token?
  //     const userId = user.sub;
  //     window.location.href = `${getApiUrl()}/oauth/initiate?id=${userId}&service=slack`;
  //   }
  // };

  const githubOAuthAppOnClick = async () => {
    if (isGithubOAuthAppIntegrationConnected) {
      if (IS_DELETION_ENABLED) {
        await apiFetch("/api/integrations?service=github", {
          method: "DELETE",
        });
        refresh();
      } else {
        setShouldShowMessage(true);
      }
    } else {
      // for example, auth0|61342c348923de (though, I think this changes if we add some things like login with google)
      // TODO: can we do this without passing in the userid just with the access token?
      const userId = user.sub;
      const response = await apiFetch(`/oauth_initiate?id=${userId}&service=github`);
      window.location.href = getAuthURL(GITHUB_OAUTH_APP_CONFIG, response.state);
    }
  };

  let githubAppSubtitle;
  if (githubAppConnectedState === "connected") {
    githubAppSubtitle = "Connected";
    // TODO: This isn't really "requested" so just show Connect Now still.
    // For example, if the org owner rejects the request, it is no longer a request.
    // There doesn't seem to be webhooks for rejections or even requests.
    // The next step with this is to figure out if there are api requests I can make
    // to get the request status.
    // } else if (githubAppConnectedState === "requested") {
    //   githubAppSubtitle = "Requested";
  } else {
    githubAppSubtitle = "Connect Now";
  }

  const githubAppOnClick = async () => {
    if (process.env.REACT_APP_ENV === "production") {
      window.location.href = "https://github.com/apps/setori-insights/installations/new";
    } else if (
      process.env.REACT_APP_ENV === "staging" ||
      process.env.REACT_APP_ENV === "development"
    ) {
      window.location.href = "https://github.com/apps/setori-insights-staging/installations/new";
    } else {
      console.error("Need to configure a REACT_APP_ENV properly");
    }
  };

  const onGoogleCalendarClick = async () => {
    if (isGoogleCalendarIntegrationConnected) {
      if (IS_DELETION_ENABLED) {
        await apiFetch("/api/integrations/google/google_calendar", {
          method: "DELETE",
        });
        refresh();
      } else {
        setShouldShowMessage(true);
      }
    } else {
      const response = await apiFetch("/oauth_initiate/google-calendar", {
        method: "GET",
      });
      window.location.href = response.url;
    }
  };

  if (integrationsData === null) {
    return null;
  }

  return (
    <DashboardLayout>
      <Box
        sx={{
          pt: 3.25,
          pb: 3.25,
          pl: 2,
          borderBottom: 0.5,
          borderLeft: 0.5,
          borderColor: border.light,
        }}
      >
        <MDTypography variant="h2">Integrations</MDTypography>
      </Box>
      <Box>
        <Grid container>
          {/* Commenting out slack for now until we enable it in the future */}
          {/* <Grid item xs={3}>
            <IntegrationCard
              title="Slack"
              subtitle={isSlackIntegrationConnected ? "Connected" : "Connect Now"}
              icon={logoSlack}
              description="Channel-based messaging platform"
              onClick={slackOnClick}
            />
          </Grid> */}
          {isGithubOAuthAppIntegrationConnected && (
            <Grid item xs={4}>
              <IntegrationCard
                title="GitHub Legacy Integration"
                subtitle={isGithubOAuthAppIntegrationConnected ? "Connected" : "Connect Now"}
                icon={mdiGithub}
                description="Git management service"
                onClick={githubOAuthAppOnClick}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <IntegrationCard
              title="GitHub"
              subtitle={githubAppSubtitle}
              icon={mdiGithub}
              description="Git management service"
              onClick={githubAppOnClick}
            />
          </Grid>
          <Grid item xs={4}>
            <GitlabIntegrationCard
              isConnected={isGitlabIntegrationConnected}
              onUpdateIntegration={refresh}
            />
          </Grid>
          <Grid item xs={4}>
            <JiraIntegrationCard
              isConnected={isJiraIntegrationConnected}
              onUpdateIntegration={refresh}
            />
          </Grid>
          <Grid item xs={4}>
            <ShortcutIntegrationCard
              isConnected={isShortcutIntegrationConnected}
              onUpdateIntegration={refresh}
            />
          </Grid>
          {process.env.NODE_ENV === "development" && (
            <Grid item xs={4}>
              <IntegrationCard
                title="Google Calendar"
                subtitle={isGoogleCalendarIntegrationConnected ? "Connected" : "Connect Now"}
                description="Calendar, events, and meetings"
                icon={mdiCalendar}
                onClick={onGoogleCalendarClick}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <MDSnackbar
        icon="notifications"
        title="Contact us"
        content={
          <span>
            To offboard integrations, please contact us at{" "}
            <a href="mailto:info@setori.ai" style={{ color: "white" }}>
              <b>info@setori.ai</b>
            </a>
          </span>
        }
        open={shouldShowMessage}
        onClose={() => {
          setShouldShowMessage(false);
        }}
        close={() => {
          setShouldShowMessage(false);
        }}
      />
    </DashboardLayout>
  );
}

export default Integrations;
