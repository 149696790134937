import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Box } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useApi, useApiFetch } from "utils/apiUtils";
import BasicSettingsLayout from "layouts/settings/components/BasicSettings";

const riskMapping = {
  days_without_review_threshold: {
    label: "Days before a 1st review",
    unit: "days",
  },
  days_in_review_threshold: {
    label: "Days spent in review",
    unit: "days",
  },
  comments_threshold: {
    label: "# of comments on a PR",
    unit: "comments",
  },
};

// Sensible defaults
// TODO: Make a value that is also used in AtRiskPRsTable
const defaultSettings = {
  days_without_review_threshold: 1,
  days_in_review_threshold: 2,
  comments_threshold: 10,
};

function RiskRow({ settingType, value, handleChangeValue }) {
  return (
    <Box mb={3}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={8}>
          <h4>{riskMapping[settingType].label}</h4>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id={settingType}
            label={riskMapping[settingType].unit}
            value={value}
            type="number"
            onChange={(e) => handleChangeValue(Number(e.target.value))}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

RiskRow.propTypes = {
  settingType: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
};

function RiskSettings({ teamId }) {
  const { apiFetch: fetch } = useApiFetch();
  const [settings, setSettings] = useState(defaultSettings);
  const [saved, setSaved] = useState(false);

  const { data, refresh } = useApi({
    url: `/api/teams/${teamId}/risk`,
  });

  useEffect(() => {
    if (data) {
      setSettings({
        days_without_review_threshold:
          data.days_without_review_threshold || defaultSettings.days_without_review_threshold,
        days_in_review_threshold:
          data.days_in_review_threshold || defaultSettings.days_in_review_threshold,
        comments_threshold: data.comments_threshold || defaultSettings.comments_threshold,
      });
    }
  }, [data]);

  const handleSave = async () => {
    await fetch(`/api/teams/${teamId}/risk`, {
      method: "POST",
      body: new URLSearchParams(settings),
    });
    refresh();
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 2000); // display "Saved!" for a few seconds after hitting the save button
  };

  if (data == null) {
    return <div>Loading...</div>;
  }

  const configurables = Object.entries(settings).map(([settingType, value]) => (
    <RiskRow
      key={settingType}
      settingType={settingType}
      value={value}
      handleChangeValue={(newValue) =>
        setSettings((prevSettings) => ({ ...prevSettings, [settingType]: newValue }))
      }
    />
  ));

  return (
    // Hack fix to add margins
    <Box mx={30}>
      <BasicSettingsLayout
        title="Dashboard: At Risk Pull Requests"
        description="Set the thresholds at which PRs are flagged as risky"
        headers={[
          <Grid item xs={8} key={0}>
            <MDTypography variant="h3">Trigger</MDTypography>
          </Grid>,
          <Grid item xs={4} key={1}>
            <MDTypography variant="h3">Bigger or equal to</MDTypography>
          </Grid>,
        ]}
        configurables={configurables}
        handleSave={handleSave}
        saved={saved}
        defaultRender={<div />}
      />
    </Box>
  );
}

RiskSettings.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default RiskSettings;
