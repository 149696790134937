import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import TeamAvatarName from "components_si/TeamAvatarName";
import Stack from "@mui/material/Stack";
import { useMaterialUIController, setMiniSidenav } from "context";
import { TextField } from "@mui/material";

function TeamSelect({ teamData, teamIdx, onTeamIdxChange }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  return (
    <TextField
      select
      label={miniSidenav ? undefined : "Team"}
      id="project-select"
      value={teamIdx}
      onChange={
        onTeamIdxChange != null
          ? (event) => {
              onTeamIdxChange(event.target.value);
            }
          : undefined
      }
      sx={{
        "& .MuiSvgIcon-root.MuiSelect-icon": {
          opacity: miniSidenav ? 0 : 1,
          transition: "opacity 0.3s ease-in-out",
        },
      }}
      SelectProps={{
        sx: {
          height: 40,
        },
        onOpen: () => {
          setMiniSidenav(dispatch, false);
        },
      }}
    >
      {teamData.map(({ team_id: teamId, team_name: teamName, avatar_color: avatarColor }, idx) => (
        <MenuItem key={teamId} value={idx}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TeamAvatarName
              teamName={teamName}
              avatarColor={avatarColor}
              sx={{ height: 24, width: 24, fontSize: 12 }}
            />
            {!miniSidenav && <span>{teamName}</span>}
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  );
}

TeamSelect.defaultProps = {
  teamData: null,
  teamIdx: null,
  onTeamIdxChange: null,
};

TeamSelect.propTypes = {
  teamData: PropTypes.arrayOf(PropTypes.object),
  teamIdx: PropTypes.number,
  onTeamIdxChange: PropTypes.func,
};

export default TeamSelect;
