import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useState, useEffect } from "react";
import { useApi } from "utils/apiUtils";
import { mdiGitlab } from "@mdi/js";
import MDTypography from "components/MDTypography";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";
import services from "utils/services";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";

import BenchmarkIcon from "components_si/BenchmarkIcon";
import {
  useEditingContext,
  EditingStates,
  getDraftCardSetting,
  editCardSetting,
  CardsEnum,
} from "components_si/EditingContext";
import BasicKpiCard from "./BasicKpiCard";
import DeploymentsDataGrid from "../DeploymentsDataGrid";

const CARD_DESCRIPTION = {
  title: "Deployment frequency", // using created_at field
  shortDefinition: "Number of succcessful deployments",
  longDefinition: (
    <>
      <MDTypography variant="body2" gutterBottom>
        The number of successful deployments.
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        We compare you to industry benchmarks:
        <Box px={2}>
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>S-tier: </strong>&gt; 5 deployments per week (more than daily)
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Average: </strong>1-5 deployments per week
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Poor: </strong>&lt; 1 deployment per week (less than weekly)
            </ListItem>
          </List>
        </Box>
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        Teams that successfully deploy to production more often can better respond to customer
        needs. Deployment frequency is a DORA metric.
      </MDTypography>
    </>
  ),
  benchmarkTooltip: {
    stier: "> 5 deployments per week",
    average: "1-5 deployments per week",
    poor: "< 1 deployments per week",
  },
  unit: "deployments",
  unitSingular: "deployment",
  highchartsTitle: "Total deployments created",
};

/**
 * @param {*} jsonBlob - A data object that must have date_week_start, count
 * @param {number} numPoints - Optional parameter, the number of data points to return. If not provided, returns all data points available in jsonBlob
 * @returns an object with an x- and a y-coordinate of length numPoints
 */
function getCardData(jsonBlob, numPoints) {
  if (jsonBlob === null) {
    return null;
  }
  let jsonBlobTruncated = jsonBlob;
  if (numPoints !== undefined && numPoints < jsonBlob.length) {
    jsonBlobTruncated = jsonBlob.slice(jsonBlob.length - numPoints, jsonBlob.length);
  }

  const cardData = jsonBlobTruncated.map((x) => [
    dayjs(x.date_point_start).valueOf(),
    x.num_deployments * 1,
  ]);
  return cardData;
}

const DEFAULT_FILTERS = [
  {
    id: 1,
    columnField: "status",
    operatorValue: "equals",
    value: "success",
  },
];

function KpiDeploymentsCard({
  teamId,
  dateRange,
  bucket: bucketProp,
  service,
  bucketDropdownOptions,
}) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  const [bucket, setBucket] = useState(null);
  useEffect(() => setBucket(bucketProp), [bucketProp]);

  // these seem fairly ubiquitous, maybe put them in "shell" card and pass in CardsEnum type?
  const getBucketWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving:
        return getDraftCardSetting(draft, CardsEnum.DeploymentsCreated)?.bucket ?? bucket;
      case EditingStates.Normal:
        return bucket;
      default:
        console.log("Invalid editing states mode");
        return bucket;
    }
  };

  const setBucketWrapper = (newBucket) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        editCardSetting(dispatch, CardsEnum.DeploymentsCreated, { bucket: newBucket });
        break;
      }
      case EditingStates.Normal: {
        setBucket(newBucket);
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setBucket(newBucket);
      }
    }
  };

  const startDate = encodeURIComponent(dateRange[0].toISOString());
  const endDate = encodeURIComponent(dateRange[1].toISOString());
  const { data } = useApi({
    url: `/api/teams/${teamId}/deployments_count?service=${service}&bucket=${getBucketWrapper()}&start=${startDate}&end=${endDate}`,
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/deployments_count?service=${service}&bucket=all&start=${startDate}&end=${endDate}`,
  });
  const { data: teamFilters } = useApi({
    url: `/api/teams/${teamId}/settings/filters`,
  });
  const [filt, setFilt] = useState([...DEFAULT_FILTERS]);
  useEffect(() => {
    setFilt([...DEFAULT_FILTERS, ...(teamFilters ?? [])]);
  }, [teamFilters]);

  return (
    <BasicKpiCard
      cardType={CardsEnum.DeploymentsCreated}
      data={getCardData(data)}
      aggregateData={aggregateData?.[0]?.num_deployments}
      bucket={getBucketWrapper()}
      onSelectBucket={(currBucket) => setBucketWrapper(currBucket)}
      renderBenchmark={(dataPoint, numWeeks) => (
        <Box pt={1}>
          <BenchmarkIcon
            tier={evaluateTier(dataPoint, MetricsEnum.DeploymentsCreated, numWeeks)}
            tooltip={CARD_DESCRIPTION.benchmarkTooltip}
          />
        </Box>
      )}
      description={CARD_DESCRIPTION}
      teamId={teamId}
      dataSourceIcon={mdiGitlab}
      popupContent={
        <DeploymentsDataGrid
          teamId={teamId}
          filtInput={filt}
          anchorColumn="created_at"
          sortField="created_at"
          service={service}
        />
      }
      onClickDataPoint={(x) => {
        const filterStartDate = dayjs(x).format("YYYY-MM-DD");
        let filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");
        if (getBucketWrapper() === "week") {
          filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");
        } else if (getBucketWrapper() === "month") {
          filterEndDate = dayjs(x).add(1, "month").format("YYYY-MM-DD");
        } else if (getBucketWrapper() === null) {
          // TODO: handle loading state
        } else {
          console.log("unsupported bucket type");
        }

        setFilt([
          ...DEFAULT_FILTERS,
          ...(teamFilters ?? []),
          {
            id: DEFAULT_FILTERS.length + teamFilters.length + 1,
            columnField: "created_at",
            operatorValue: "onOrAfter",
            value: filterStartDate,
          },
          {
            id: DEFAULT_FILTERS.length + teamFilters.length + 2,
            columnField: "created_at",
            operatorValue: "before",
            value: filterEndDate,
          },
        ]);
      }}
      onClosePopup={() => {
        setFilt([...DEFAULT_FILTERS, ...(teamFilters ?? [])]);
      }}
      bucketDropdownOptions={bucketDropdownOptions}
    />
  );
}

KpiDeploymentsCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
  bucketDropdownOptions: [
    { label: "weekly", value: "week" },
    { label: "monthly", value: "month" },
  ],
};

KpiDeploymentsCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
  bucketDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
};

export default KpiDeploymentsCard;
