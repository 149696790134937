const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

// Ordered in ascending order
const TIME_UNITS = [
  { unit: "ms", unitPlural: "ms", conversionRate: 1 },
  { unit: "sec", unitPlural: "sec", conversionRate: 1000 },
  { unit: "min", unitPlural: "min", conversionRate: 60 },
  { unit: "hr", unitPlural: "hrs", conversionRate: 60, includeNextSmallestUnit: true },
  { unit: "day", unitPlural: "days", conversionRate: 24, includeNextSmallestUnit: true },
];

// TODO: try one of the recommendations here: https://momentjs.com/docs/#/-project-status/recommendations/
// maybe dayjs since we already use that

/**
 * Same as convertMillisecondsToReadableTime but returns an ordered array of
 * objects with a time (value) and a unit.
 *
 * @returns an array of objects with time and unit keys. Guaranteed to always
 *   return at least one object in array.
 */
function convertMillisecondsToReadableTimeAndUnits(milliseconds, timeUnits = TIME_UNITS) {
  // If time is 0, return "" instead of 0 ms
  if (milliseconds === 0) {
    return [{ time: "", unit: "" }];
  }

  // Divide down the number with the next conversion rate until it cannot make a
  // whole number for the next unit
  let unitIndex = 0;
  let numSoFar = milliseconds;
  for (let i = 0; i < timeUnits.length; i++) {
    const { conversionRate } = timeUnits[i];

    const potentialNumber = numSoFar / conversionRate;
    if (potentialNumber < 1) {
      break;
    } else {
      unitIndex = i;
      numSoFar = potentialNumber;
    }
  }

  const {
    unit: currentUnit,
    unitPlural: currentUnitPlural,
    conversionRate: currentUnitConversionRate,
    includeNextSmallestUnit = false,
  } = timeUnits[unitIndex];

  // e.g., Instead of just returning "3 days", return "2 days, 23 hours"
  if (includeNextSmallestUnit) {
    let whole = Math.floor(numSoFar);
    const remainder = numSoFar - whole;

    // Convert the next number back up to the right unit
    // (e.g., hours if we take the example above)
    let nextNum = remainder * currentUnitConversionRate;

    // We don't want a situation where it shows "2 days, 24 hours" because
    // the next number is rounded up from 23.999 hours when it should say 3 days
    nextNum = Math.round(remainder * currentUnitConversionRate);
    if (nextNum === currentUnitConversionRate) {
      whole += 1;
      nextNum = 0;
    }

    // If the nextNumber would be 0, don't bother showing it
    if (nextNum !== 0) {
      const { unit: nextLowestUnit, unitPlural: nextLowestUnitPlural } = timeUnits[unitIndex - 1];
      return [
        {
          time: whole,
          unit: whole !== 1 ? currentUnitPlural : currentUnit,
        },
        {
          time: nextNum,
          unit: nextNum > 1 ? nextLowestUnitPlural : nextLowestUnit,
        },
      ];
    }
    return [
      {
        time: whole,
        unit: whole !== 1 ? currentUnitPlural : currentUnit,
      },
    ];
  }

  // If we are just returning one unit, just round it (up or down)
  const whole = Math.round(numSoFar);
  return [{ time: whole, unit: whole !== 1 ? currentUnitPlural : currentUnit }];
}

/**
 * @param {number} milliseconds
 * @param {Object[]} timeUnits - an ordered array of objects which are used to
 *  configure which time units should be returned and how they should be
 *  formatted.
 *
 *  The order must be in ascending unit size.
 *
 *  Keys in each object:
 *    unit: {string} (required) must be larger unit than milliseconds e.g., "days"
 *    unitPlural: {string} (required) e.g., "days"
 *    conversionRate: {number} (required) The amount of the unit before this that's
 *      required to convert to this unit.
 *      If the unit is the first one in the array, the unit before this is
 *      assumed to be milliseconds.
 *    includeNextSmallestUnit: {boolean} (optional) Defaults to false.
 *      If this is false for "day", 1.3 days appear as "1 day".
 *      If this is true for "day", 1.3 days would return "1 day, 7 hrs"
 *
 * @return a readable time string where the unit of time is whatever the highest
 * possible time unit that results in a number >= 1. Only among the units in
 * `timeUnits`. If the given number of milliseconds is already < 1,
 * we return that.
 */
function convertMillisecondsToReadableTime(milliseconds, timeUnits = TIME_UNITS) {
  const timeAndUnits = convertMillisecondsToReadableTimeAndUnits(milliseconds, timeUnits);
  return timeAndUnits.map(({ time, unit }) => `${time} ${unit}`).join(", ");
}

export {
  MILLISECONDS_IN_A_DAY,
  convertMillisecondsToReadableTimeAndUnits,
  convertMillisecondsToReadableTime,
};
