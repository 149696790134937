import PropTypes from "prop-types";
import dayjs from "dayjs";
import Highcharts from "highcharts";

import { useState, useEffect } from "react";
import { useApi } from "utils/apiUtils";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";

import MDTypography from "components/MDTypography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import {
  useEditingContext,
  EditingStates,
  getDraftCardSetting,
  editCardSetting,
  CardsEnum,
} from "components_si/EditingContext";
import BenchmarkIcon from "components_si/BenchmarkIcon";
import services from "utils/services";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";
import GraphTypeEnum from "layouts/projects/utils/GraphTypeEnum";
import GithubKpiCard from "./GithubKpiCard";

const CARD_DESCRIPTION = {
  title: "Coding time",
  shortDefinition:
    "The time from when a PR is first opened to when a first review or comment is given",
  longDefinition: (
    <>
      <MDTypography variant="body2" gutterBottom>
        Time from first commit to PR. If there is no review, time from first commit to merge.
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        We compare you to industry benchmarks:
        <Box px={2}>
          {/* TODO: Declare benchmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>S-tier: </strong>&lt; 12 hours
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Average: </strong>12-36 hours
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Poor: </strong> &gt; 36 hours
            </ListItem>
          </List>
        </Box>
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        Teams with shorter coding time tend to have a higher velocity, enabling them to better
        respond to customer needs. There is also usually less conflicts when merging code into a
        main branch.
      </MDTypography>
    </>
  ),
  benchmarkTooltip: {
    stier: "< 12 hours",
    average: "12-36 hours",
    poor: "> 36 hours",
  },
  unit: "",
  unitSingular: "",
  highchartsTitle: "Average coding time",
};

/**
 * @param {*} jsonBlob - A data object that must have date_point_start, lines_changed_weekly_avg_per_pr
 * @param {number} numPoints - Optional parameter, the number of data points to return. If not provided, returns all data points available in jsonBlob
 * @returns an object with an x- and a y-coordinate of length numPoints
 */
function processCardData(jsonBlob, numPoints) {
  if (jsonBlob === null) {
    return null;
  }

  const cardData = [];
  let jsonBlobTruncated = jsonBlob;

  if (numPoints !== undefined && numPoints < jsonBlob.length) {
    jsonBlobTruncated = jsonBlob.slice(jsonBlob.length - numPoints, jsonBlob.length);
  }

  const cardDataX = jsonBlobTruncated.map((x) => dayjs(x.date_point_start).valueOf());
  const cardDataY = jsonBlobTruncated.map((y) => y.coding_time_weekly_avg_seconds);

  for (let i = 0; i < cardDataX.length; i++) {
    cardData[i] = [cardDataX[i], cardDataY[i]];
  }
  return cardData;
}

function processAggregateData(aggregateData) {
  const val = aggregateData?.[0]?.coding_time_weekly_avg_seconds;

  if (val) {
    return parseFloat(val);
  }

  return val;
}

function processScatterData(data, prData, bucket) {
  const dates = processCardData(data).map((item) => item[0]);

  return dates.map((date, index) => {
    const prDataTransformed = prData
      .filter((item) => {
        const createdAt = dayjs(item.pr_created_at).valueOf(); // Convert to milliseconds
        const periodStart = dayjs(date).valueOf(); // Convert to milliseconds
        let periodEnd;
        if (bucket === "month") {
          periodEnd = dayjs(date).add(1, "month").valueOf(); // Add 1 month
        } else if (bucket === "week") {
          periodEnd = dayjs(date).add(7, "day").valueOf(); // Add 7 days
        } else {
          console.log("unsupported bucket size in PR size card - can only be weekly or monthly");
          // fall back to a week
          periodEnd = dayjs(date).add(7, "day").valueOf();
        }

        return createdAt >= periodStart && createdAt < periodEnd;
      })
      .map((item) => [index, parseInt(item.coding_time_epoch, 10), item.pr_title]); // casting to int HighCharts can't render with strings

    return {
      name: date.toString(),
      data: prDataTransformed,
    };
  });
}

function DurationMetricDisplay({ data, _unitDescription }) {
  let formattedDatapoint = "--";

  if (data || data === 0) {
    formattedDatapoint = convertMillisecondsToReadableTime(data * 1000);

    return (
      <Stack direction="row" sx={{ alignItems: "baseline" }}>
        <MDTypography variant="h1">{formattedDatapoint}</MDTypography>
      </Stack>
    );
  }
}

DurationMetricDisplay.defaultProps = {
  _unitDescription: "",
};

// Typechecking props
DurationMetricDisplay.propTypes = {
  data: PropTypes.number.isRequired,
  _unitDescription: PropTypes.shape({
    unit: PropTypes.string,
    unitSingular: PropTypes.string,
  }),
};

function FormatTooltip({ date, data, bucketSize, _unitDescription }) {
  const startDate = dayjs(date);
  let formattedDatapoint = "--";

  if (data || data === 0) {
    formattedDatapoint = convertMillisecondsToReadableTime(data * 1000);
  }

  switch (bucketSize) {
    case "week": {
      const endDate = startDate.add(6, "day");

      // Check if the months are different
      const includeMonth = startDate.month() !== endDate.month();

      // Format the end date depending on whether the months are different
      const endDateFormatted = includeMonth
        ? Highcharts.dateFormat("%b %e", endDate)
        : Highcharts.dateFormat("%e", endDate);

      return `<b>${Highcharts.dateFormat(
        "%b %e",
        startDate
      )} - ${endDateFormatted}</b></br>${formattedDatapoint}`;
    }
    case "month": {
      return `<b>${Highcharts.dateFormat("%B %Y", startDate)}</b></br> ${formattedDatapoint}`;
    }
    default: {
      return `<b>${Highcharts.dateFormat("%b %e", startDate)}</b></br> ${formattedDatapoint}`;
    }
  }
}

function FormatTooltipScatter({ title, data, _unitDescription }) {
  let formattedDatapoint = "--";

  if (data) {
    formattedDatapoint = convertMillisecondsToReadableTime(data * 1000);
  }
  // TODO: Is there anything else we want to include in this tooltip?
  return `<b>${title}</b></br>${formattedDatapoint}`;
}

function KpiCodingTimeCard({
  teamId,
  dateRange,
  bucket: bucketProp,
  graph: graphProp,
  service,
  targetLine,
  targetScatter,
}) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  // TODO: should handle the null case as a loading state to prevent a flicker
  const [cardState, setCardState] = useState({ bucket: null, graph: null });
  useEffect(() => {
    setCardState({ bucket: bucketProp, graph: graphProp });
  }, [bucketProp, graphProp]);

  const getStateWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.CodingTime);
        return {
          ...cardState,
          ...settings,
        };
      }
      case EditingStates.Normal:
        return cardState;
      default:
        console.log("Invalid editing states mode");
        return cardState;
    }
  };

  const setStateWrapper = (newCardState) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.CodingTime);
        const newSettings = {
          ...cardState,
          ...settings,
          ...newCardState,
        };
        editCardSetting(dispatch, CardsEnum.CodingTime, newSettings);
        break;
      }
      case EditingStates.Normal: {
        setCardState({
          ...cardState,
          ...newCardState,
        });
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setCardState({
          ...cardState,
          ...newCardState,
        });
      }
    }
  };

  const [startDate, endDate] = dateRange;
  const encodedStartDate = encodeURIComponent(startDate.toISOString());
  const encodedEndDate = encodeURIComponent(endDate.toISOString());
  const { data } = useApi({
    url: `/api/teams/${teamId}/cycle_time?service=${service}&bucket=${
      getStateWrapper().bucket
    }&start=${encodedStartDate}&end=${encodedEndDate}`,
    defaultData: [],
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/cycle_time?service=${service}&bucket=all&start=${encodedStartDate}&end=${encodedEndDate}`,
    defaultData: [],
  });
  const { data: prData } = useApi({
    url: `/api/teams/${teamId}/prs_computed_table?service=${service}`,
    defaultData: [],
  });

  return (
    <GithubKpiCard
      cardType={CardsEnum.CodingTime}
      data={processCardData(data)}
      scatterData={processScatterData(data, prData, getStateWrapper().bucket)}
      bucket={getStateWrapper().bucket}
      graph={getStateWrapper().graph}
      onSelectBucket={(newBucket) => setStateWrapper({ bucket: newBucket })}
      onSelectGraph={(newGraph) => setStateWrapper({ graph: newGraph })}
      renderMetricDisplay={DurationMetricDisplay}
      formatTooltip={FormatTooltip}
      formatTooltipScatter={FormatTooltipScatter}
      aggregateData={processAggregateData(aggregateData)}
      renderBenchmark={(dataPoint, numWeeks, dataPointIdx) => {
        if (dataPointIdx >= 0 && data[dataPointIdx].coding_time_weekly_avg_seconds === 0) {
          // The height of the actually filled out benchmark -- otherwise this would be null
          // TODO: Probably better to just make the graph stick to the bottom of the cell instead
          return <Box height={35.5} />;
        }
        return (
          <Box pt={1}>
            <BenchmarkIcon
              tier={evaluateTier(dataPoint, MetricsEnum.CodingTime, numWeeks)}
              tooltip={CARD_DESCRIPTION.benchmarkTooltip}
            />
          </Box>
        );
      }}
      graphDropdownOptions={[
        { label: "line", value: GraphTypeEnum.Line },
        { label: "scatter", value: GraphTypeEnum.Scatter },
      ]}
      description={CARD_DESCRIPTION}
      teamId={teamId}
      service={service}
      actualStartDate={startDate}
      targetLine={targetLine}
      targetScatter={targetScatter}
    />
  );
}

// Setting default values for the props
KpiCodingTimeCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
  graph: GraphTypeEnum.Line,
  targetLine: null,
  targetScatter: null,
};

// Typechecking props
KpiCodingTimeCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
  graph: PropTypes.oneOf(Object.values(GraphTypeEnum)),
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  targetLine: PropTypes.number,
  targetScatter: PropTypes.number,
};

export default KpiCodingTimeCard;
