// @mui material components
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import darkModeColors from "assets/theme-dark/base/colors";

const { border } = darkModeColors;

function MissionOverview() {
  return (
    <Box
      sx={{
        py: 1,
        pl: 3,
        borderBottom: 0.5,
        borderLeft: 0.5,
        borderColor: border.light,
      }}
    >
      <MDTypography variant="body1" gutterBottom>
        <strong>Mission:</strong> Reduce pickup time - the time from when a PR is opened to a first
        comment or review
      </MDTypography>
      <MDTypography variant="body1" gutterBottom>
        <strong>Target:</strong> Have an average pickup time of 12 hours or less
      </MDTypography>
    </Box>
  );
}

export default MissionOverview;
