import { MILLISECONDS_IN_A_DAY, convertMillisecondsToReadableTime } from "utils/timeUtils";
import { Risk, getOverallRiskDriver } from "./riskUtils";

/**
 * if already merged and still no reviews, red -- merged without a review
 * if pickup time was / is long
 *   if still waiting for review (as in review not started still and not merged yet), red
 *   else, yellow
 * else if not long, green
 *
 * @param {*} now - The Date we calculate risk against. We pass in now so that
 *   it's accurate against a time used for other calculations.
 * @returns an object with text and riskLevel keys. Can return undefined if
 *   there is no pickup time (i.e., if it's already been merged and there are
 *   no reviews)
 */
function computePickupTimeRisk(
  { pr_created_at: prCreatedAt, review_start_time: reviewStartTime, merged_at: mergedAt, draft },
  now,
  daysWithoutReviewThreshold
) {
  // // if already merged and still no reviews, undefined
  // if (mergedAt !== null && reviewStartTime === null) {
  //   return {
  //     text: "Merged without a review",
  //     riskLevel: Risk.Critical,
  //   };
  // }

  // if in draft, then you can't have pickup risks
  // We could start the clock of reviewStartTime as the last time the PR was moved out of draft,
  // but some people still expect reviews in draft.
  if (draft) {
    return undefined;
  }

  const parsedPRCreatedTime = new Date(prCreatedAt);
  // as in, it hasn't been merged already and still hasn't gotten a review
  const stillWaitingForReview = mergedAt === null && reviewStartTime === null;
  const currentPickupEndTime = stillWaitingForReview ? now : new Date(reviewStartTime);

  // Should always be a positive pickup time
  const pickupTimeInMs = currentPickupEndTime - parsedPRCreatedTime;
  const pickupTimeInDays = pickupTimeInMs / MILLISECONDS_IN_A_DAY;
  const prettyPickupTimeInDays = convertMillisecondsToReadableTime(pickupTimeInMs, [
    { unit: "day", unitPlural: "days", conversionRate: MILLISECONDS_IN_A_DAY },
  ]);

  if (pickupTimeInDays >= daysWithoutReviewThreshold) {
    if (stillWaitingForReview) {
      return {
        text: `Pickup: ${prettyPickupTimeInDays} and waiting`,
        riskLevel: Risk.Critical,
      };
    }
    return {
      text: `Pickup: ${prettyPickupTimeInDays} to complete`,
      riskLevel: Risk.Warning,
    };
  }

  return {
    text: `${prettyPickupTimeInDays} before review was started`,
    riskLevel: Risk.Good,
  };
}

/**
 * if not yet merged yet and still no reviews, undefined -- review time hasn't started yet
 * if already merged and still no reviews, undefined -- merged without a review
 *   (but not red because handled in pickup time)
 * if already merged and review end time is after merge, yellow
 * if review time was / is long
 *   if still counting (as in not merged yet), red
 *   else, yellow
 * else if not long, green
 *
 * @param {*} now - The Date we calculate risk against. We pass in now so that
 *   it's accurate against a time used for other calculations.
 * @returns an object with text and riskLevel keys.
 */
function computeReviewTimeRisk(
  { review_start_time: reviewStartTime, merged_at: mergedAt, review_end_time: _reviewEndTime },
  now,
  daysInReviewThreshold
) {
  const isMerged = mergedAt !== null;

  // if not yet merged yet and still no reviews, undefined -- review time hasn't started yet
  if (!isMerged && reviewStartTime === null) {
    return undefined;
  }

  // if already merged and still no reviews, red -- merged without a review
  if (isMerged && reviewStartTime === null) {
    // Not the cleanest code, but we return undefined here instead of critical
    // because the risk should already be picked up by the lack of a pickup time
    // We could make this cleaner by surfacing the alert twice and de-duping later
    return undefined;
  }

  // Note: if you're in draft and you do have a reviewStartTime, then we consider that as
  // starting a review regardless (which is different than pickup time where we filter out
  // drafts). This is to accomodate the team workflow where drafts are used to indicate
  // "this is not ready to be merged / is not fully complete code, but you can still give me
  // comments on the early approach. I don't have an expectation that you'll review the PR
  // by a certain time and I'm not blocked by your lack of review"

  // // if already merged and review end time is after merge
  // if (isMerged && new Date(reviewEndTime) > new Date(mergedAt)) {
  //   // TODO: In the unit tests, I don't test for the differing text here, just for the riskLevel.
  //   // This is where we should potentially add enums for all the different risks
  //   // (which may help dedupe if we wanted to do that)
  //   //
  //   // If all reviews happened after merge
  //   if (new Date(reviewStartTime) > new Date(mergedAt)) {
  //     return {
  //       text: "Only reviewed after merged",
  //       riskLevel: Risk.Warning,
  //     };
  //   }
  //   return {
  //     text: "Reviewed after merged",
  //     riskLevel: Risk.Warning,
  //   };
  // }

  const parsedReviewStartTime = new Date(reviewStartTime);
  const currentReviewEndTime = isMerged ? new Date(mergedAt) : now;

  // Should always be a positive review time
  const reviewTimeInMs = currentReviewEndTime - parsedReviewStartTime;
  const reviewTimeInDays = reviewTimeInMs / MILLISECONDS_IN_A_DAY;
  const prettyReviewTimeInDays = convertMillisecondsToReadableTime(reviewTimeInMs, [
    { unit: "day", unitPlural: "days", conversionRate: MILLISECONDS_IN_A_DAY },
  ]);

  if (reviewTimeInDays >= daysInReviewThreshold) {
    if (!isMerged) {
      return {
        text: `Review: ${prettyReviewTimeInDays} in progress`,
        riskLevel: Risk.Critical,
      };
    }
    return {
      text: `Review: ${prettyReviewTimeInDays} to complete`,
      riskLevel: Risk.Warning,
    };
  }

  return {
    text: `${prettyReviewTimeInDays} in review before closed`, // and was reviewed before merging
    riskLevel: Risk.Good,
  };
}

/**
 * Calculate risk based on number of comments and PR status.
 *
 * @param {*} pr_comments - number of comments on the PR.
 * @param {*} merged_at - PR merge date. Null if still open.
 * @returns an object with text and riskLevel keys.
 */

function computeCommentsRisk({ pr_comments: prComments, merged_at: mergedAt }, commentsThreshold) {
  if (prComments >= commentsThreshold) {
    if (mergedAt === null) {
      return {
        text: `Review: ${prComments} comments and still open`,
        riskLevel: Risk.Critical,
      };
    }
    return {
      text: `Review: ${prComments} comments before resolved`,
      riskLevel: Risk.Warning,
    };
  }

  return {
    text: "Review: Acceptable number of comments",
    riskLevel: Risk.Good,
  };
}

/**
 * This function calculates the risk for pull requests.
 *
 * @see {@link computeStoryRisks} as this function parallels that function
 * except for pull requests. In fact, we should consider generalizing this if
 * the pattern continues.
 *
 * @param {*} pullRequest
 * @param {*} now - The Date we calculate risk against. We pass in now so that
 *   it's accurate against a time used for other calculations. Defaults to now
 *   if you don't pass it in.
 */
function computePRRisks(
  pullRequestAtRisk,
  now = new Date(),
  daysWithoutReviewThreshold,
  daysInReviewThreshold,
  commentsThreshold
) {
  const riskBreakdown = [];

  // Order matters here -- highest priority risk to lowest
  //
  // Review time risk is deliberately placed before pickup time risk because
  // if the PR has already finished calculating the pickup time (a review has
  // started), then review time is the new highest risk
  const reviewTimeRisk = computeReviewTimeRisk(pullRequestAtRisk, now, daysInReviewThreshold);
  if (reviewTimeRisk !== undefined) {
    riskBreakdown.push(reviewTimeRisk);
  }

  const pickupTimeRisk = computePickupTimeRisk(pullRequestAtRisk, now, daysWithoutReviewThreshold);
  if (pickupTimeRisk !== undefined) {
    riskBreakdown.push(pickupTimeRisk);
  }

  const commentsRisk = computeCommentsRisk(pullRequestAtRisk, commentsThreshold);
  if (commentsRisk !== undefined) {
    riskBreakdown.push(commentsRisk);
  }

  return {
    riskBreakdown,
    riskDriverIndex: getOverallRiskDriver(riskBreakdown),
  };
}

export {
  computePRRisks,
  // Exported just for testing
  // TODO: use rewire or something to do this instead:
  // https://www.samanthaming.com/journal/2-testing-non-exported-functions/
  computePickupTimeRisk,
  computeReviewTimeRisk,
  computeCommentsRisk,
};
