import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import Icon from "@mdi/react";
import { mdiCloseCircleOutline } from "@mdi/js";

// Utilities
import { useApi, useApiFetch } from "utils/apiUtils";
import { computeStoryRisks } from "../utils/ticketRiskUtils";
import { Risk, riskToBadgeColor } from "../utils/riskUtils";

// Setori Components
import RiskItem from "./RiskItem";

function AtRiskTicketsTable({ teamId }) {
  const { apiFetch: fetch } = useApiFetch();
  const { data: inProgressTickets, refresh } = useApi({
    url: `/api/teams/${teamId}/tickets_at_risk`,
    defaultData: [],
    dependencies: [teamId],
  });

  const columns = [
    { Header: "Ticket", accessor: "riskItem", width: "45%", align: "left" },
    { Header: "Risk", accessor: "status", align: "center" },
    { Header: "", accessor: "action", align: "center" },
  ];

  const rows = inProgressTickets.reduce((filtered, ticket) => {
    const { riskBreakdown, riskDriverIndex } = computeStoryRisks(ticket);
    const riskDriver = riskBreakdown[riskDriverIndex];
    // Filter out tickets where the risk is good
    // Was originally supposed to just be in progress tickets
    if (riskDriver.riskLevel === Risk.Good) {
      return filtered;
    }
    const onClickMarkAsMitigated = async () => {
      await fetch(`/api/teams/${teamId}/tickets_at_risk/${ticket.id}`, {
        method: "POST",
        body: new URLSearchParams({
          changed_at: Date.now(),
          state: "mitigated", // potentially other states later
          risk: riskDriver.text,
        }),
      });
      refresh();
    };

    filtered.push({
      riskItem: <RiskItem author={ticket.owner_name} title={ticket.name} url={ticket.app_url} />,
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={riskDriver.text}
            color={riskToBadgeColor(riskDriver.riskLevel)}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <MDTypography
          sx={{ cursor: "pointer" }}
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={onClickMarkAsMitigated}
        >
          <Icon path={mdiCloseCircleOutline} size={0.8} />
        </MDTypography>
      ),
    });
    return filtered;
  }, []);

  return (
    <MDBox pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

AtRiskTicketsTable.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default AtRiskTicketsTable;
