// Material Dashboard 2 React Base Styles
import colors from "assets/theme-dark/base/colors";

const { text, transparent } = colors;

const textField = {
  styleOverrides: {
    root: {
      color: text.main,
      backgroundColor: transparent.main,
    },
  },
};

export default textField;
