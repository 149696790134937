/**
 * The base colors for the dashboard.
 * You can add new color using this file.
 * You can customized the colors for the entire dashboard using this file.
 */

const colors = {
  // Linear 2023 color scheme (~black)
  background: {
    default: "#191A23",
    hover: "#1F202E", // Linear color for hover of tickets, converted to HSV and V increased from 16.5% to 17%
    lines: "#838496",
    subtitle: "#1F2028",
    sidenav: "#15161F",
    sidenavActive: "#2C2F35", // This is the color of an active item in the sidebar
    popup: "#2C2F35", // This is the color of a popup (e.g., calendar)
    snackbar: "#2C2F35",
    card: "#191A23", // TODO: Find where this is used, switch to default
  },

  // Linear design (~white)
  text: {
    main: "#D2D3E0", // Used for lots of text (e.g., body). ~white
    title: "#EEEFFC", // Used for emphasis (e.g., title). ~white, slight lighter than main
    subtitle: "#838496", // Same color as info
    popup: "#858699", // Used for lots of text (e.g., body) in a popup (e.g., info popup)
    focus: "#EEEFFC", // Used for hovering on text-variant Buttons (text is the default variant)
  },

  icon: {
    main: "#838496", // Grey with a tinge of purple
    focus: "#EEEFFC", // Same as text.title
  },

  menu: {
    selected: "#2C2F35", // Same as background.sidenavActive
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#FFFFFF",
    focus: "#FFFFFF",
    muted1: "#FFFFFF80",
    muted2: "#FFFFFF66",
  },

  // Used in MDTypography
  // Linear ~white with a tinge of purple for titles
  headers: {
    main: "#EEEFFC",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#575BC7", // Purple from Linear
    focus: "#3C3F8B",
  },

  graph: {
    spline: "#B7BEFF",
    area: "#6D5C92",
    breakpoint: "#18192247",
  },

  secondary: {
    main: "#D2D3E0",
    focus: "#8f93a9",
  },

  border: {
    main: "#838496", // Linear slight purple
    light: "#2C2E3F", // Lighter border for the overall layout
  },

  info: {
    main: "#838496",
    focus: "#D2D3E0",
  },

  success: {
    main: "#34A853",
    focus: "#67bb6a",
  },

  warning: {
    main: "#FBBC05",
    focus: "#fc9d26",
  },

  error: {
    main: "#D81F1F",
    focus: "#f65f53",
  },

  light: {
    main: "#EEEFFC",
    focus: "#EEEFFC",
  },

  dark: {
    main: "#838496",
    focus: "#838496",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#EC407A",
      state: "#D81B60",
    },

    secondary: {
      main: "#747b8a",
      state: "#495361",
    },

    // Gradient of the dashboard button - purple
    info: {
      main: "#575BC7",
      state: "#838496",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#323a54",
      state: "#1a2035",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#0838496",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#838496",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },

  glass: {
    background: "rgba(49, 63, 79, 0.45)",
    border: "0.5px solid #2C3947",
    filter: "blur(0.5px)",
    backdropFilter: "blur(3px)",
    /* Note: backdrop-filter has minimal browser support */
  },
};

export default colors;
