import dayjs from "dayjs";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import darkModeColors from "assets/theme-dark/base/colors";
import SIDataGrid from "components_si/SIDataGridPro";
import services from "utils/services";

// Utilities
import { useApi } from "utils/apiUtils";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";
import { MetricsEnum, TiersEnum, evaluateTier } from "utils/tierUtils";

import AuthorAvatarName from "components_si/AuthorAvatarName";

const { icon, success, warning, error } = darkModeColors;

// TODO: Move this to a utils function
function formatSecondsWithTierBackground(data, type) {
  const status = evaluateTier(data, type);
  if (status === TiersEnum.STier) {
    return (
      <Stack direction="row">
        {/* TODO: Think about making visibility hidden dynamic (e.g., based on user choice, or smartly not to overwhelm user with too many dots) */}
        {/* <Box visibility="hidden"> */}
        <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="3" fill={success.main} />
        </svg>
        {/* </Box> */}
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  if (status === TiersEnum.Average) {
    return (
      <Stack direction="row">
        <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="3" fill={warning.main} />
        </svg>
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  if (status === TiersEnum.Poor) {
    return (
      <Stack direction="row">
        {/* TODO: Think about making visibility hidden dynamic (e.g., based on user choice, or smartly not to overwhelm user with too many dots) */}
        <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="3" fill={error.main} />
        </svg>
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  if (status === TiersEnum.None) {
    return (
      <Stack direction="row">
        <Box visibility="hidden">
          {/* TODO: Think about making visibility hidden dynamic (e.g., based on user choice, or smartly not to overwhelm user with too many dots) */}
          <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="3" fill={icon.main} />
          </svg>
        </Box>
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  return <Box>Loading...</Box>;
}

export default function PickupsDataGrid({ teamId, service, filtInput }) {
  const { data, loading } = useApi({
    url: `/api/teams/${teamId}/prs_computed_table?service=${service}`,
    defaultData: [],
  });

  const columns = [
    {
      field: "reviewer_name",
      headerName: "Reviewer",
      flex: 1,
      minWidth: 180,
      maxWidth: 250,
      renderCell: (params) => (
        <AuthorAvatarName
          author={params.row.reviewer_name}
          authorAvatarUrl={params.row.reviewer_avatar_url}
        />
      ),
    },
    {
      field: "pr_created_at",
      headerName: "Date PR opened",
      type: "date",
      flex: 1,
      minWidth: 180,
      maxWidth: 200,
      renderCell: (params) =>
        params.row.pr_created_at !== null
          ? dayjs(params.row.pr_created_at).format("YYYY-MM-DD [at] HH:mm")
          : "",
    },
    {
      field: "pr_author_name",
      headerName: "Author",
      flex: 1,
      minWidth: 120,
      maxWidth: 200,
    },
    {
      field: "pr_title",
      headerName: "PR title",
      flex: 1,
      minWidth: 400,
      maxWidth: 500,
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params) => params.row.state.charAt(0).toUpperCase() + params.row.state.slice(1),
    },
    {
      field: "pr_lines_changed",
      headerName: "Additions & deletions",
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params) => params.row.pr_lines_changed?.toLocaleString("en-US"), // Formatting for the cell in the DataGrid
    },
    {
      field: "pr_comments",
      headerName: "Comments",
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      maxWidth: 200,
      renderCell: (params) => params.row.pr_comments?.toLocaleString("en-US"), // Formatting for the cell in the DataGrid
    },
    {
      field: "pickup_time",
      headerName: "Pickup time",
      flex: 1,
      minWidth: 120,
      maxWidth: 200,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => params.row.pickup_time_epoch,
      // valueFormatter: (params) => convertMillisecondsToReadableTime(params.value * 1000),
      renderCell: (params) =>
        formatSecondsWithTierBackground(params.row.pickup_time_epoch, MetricsEnum.PickupTime),
    },
  ];
  // If the filter is null, do not render the component
  if (!filtInput) {
    return null;
  }
  return (
    <SIDataGrid
      showExportAndFilter
      columns={columns}
      rows={data}
      onRowClick={(params) => window.open(params.row.pr_url)}
      initialState={{
        filter: {
          filterModel: {
            items: filtInput,
          },
        },
      }}
      sx={{ border: 0 }}
      loading={loading}
    />
  );
}

PickupsDataGrid.defaultProps = {
  filtInput: [],
};

PickupsDataGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  filtInput: PropTypes.arrayOf(PropTypes.object),
};
