import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function getApiUrl() {
  return process.env.REACT_APP_API_URL;
}

/**
 * @param {*} url
 * @param {*} getAccessTokenSilently - from the useAuth auth0 react sdk.
 *  It feels like there should be a better way to do this.
 * @param {*} options
 * @return Parsed JSON. Note: this will error if the response is not json.
 */
async function _fetchWithAccessTokenBearer(url, getAccessTokenSilently, options = {}) {
  const { scope, ...fetchOptions } = options;
  const accessToken = await getAccessTokenSilently({
    audience: "https://setori.ai/api",
    scope,
  });
  const res = await fetch(`${getApiUrl()}${url}`, {
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      // Add the Authorization header to the existing headers
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: "include",
  });
  return res.json();
}

const useApiFetch = () => {
  const { getAccessTokenSilently } = useAuth0();
  return {
    apiFetch: async (url, options = {}) =>
      _fetchWithAccessTokenBearer(url, getAccessTokenSilently, options),
  };
};

// Modified from:
// https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token
const useApi = ({ url, defaultData = null, options = {}, dependencies = [] }) => {
  const { apiFetch } = useApiFetch();
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: defaultData,
  });
  const [refreshIndex, setRefreshIndex] = useState(0);
  useEffect(() => {
    (async () => {
      try {
        if (url === undefined) {
          return;
        }

        setState({
          ...state,
          data: await apiFetch(url, options),
          error: null,
          loading: false,
        });
      } catch (error) {
        setState({
          ...state,
          error,
          loading: false,
        });
      }
    })();
  }, [refreshIndex, url, ...dependencies]);

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
};

export { useApi, useApiFetch };
