import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useState, useEffect } from "react";
import { useApi } from "utils/apiUtils";
import { mdiJira } from "@mdi/js"; // https://materialdesignicons.com/
import MDTypography from "components/MDTypography";
import { MetricsEnum } from "utils/tierUtils";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";

import {
  useEditingContext,
  EditingStates,
  getDraftCardSetting,
  editCardSetting,
  CardsEnum,
} from "components_si/EditingContext";
import SprintIssueCycleTimeDataGrid from "../SprintIssueCycleTimeDataGrid";
import SprintKpiCard from "./SprintKpiCard";

function CARD_DESCRIPTION(points) {
  return {
    title: `Sprint completion`,
    shortDefinition: `% of Jira ${points ? "points" : "issues"} completed`,
    longDefinition: (
      <>
        <MDTypography variant="body2" gutterBottom>
          The {points ? "points" : "issues"} added to a sprint that were completed in that same
          sprint (i.e., moved to a status of Done) divided by all the {points ? "points" : "issues"}{" "}
          added to that same sprint.
        </MDTypography>
        <MDTypography variant="body2" gutterBottom>
          We compare you to industry benchmarks:
          <Box px={2}>
            {/* TODO: Declare benchmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
            <List sx={{ listStyleType: "disc" }}>
              <ListItem sx={{ display: "list-item" }}>
                <strong>S-tier: </strong>&gt;
                {points ? "75% of points" : "75% of issues"} completed per sprint
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <strong>Average: </strong>
                {points ? "50-75% of points" : "50-75% of issues"} completed per sprint
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <strong>Poor: </strong>&lt;
                {points ? "50% of points" : "50% of issues"} completed per sprint
              </ListItem>
            </List>
          </Box>
        </MDTypography>
        <MDTypography variant="body2" gutterBottom>
          Elite teams usually do not expect to finish all the work included in a sprint.
        </MDTypography>
        <MDTypography variant="body2" gutterBottom>
          If there is a sudden change in the share of sprint work completed, this could be a leading
          indicator of planning, project, or team challenges and worth investigating.
        </MDTypography>
        {/* <MDTypography variant="body2" gutterBottom>
          Note: If a Jira issue is moved to a Done status and then moved out of a Done status (e.g.,
          it&apos;s moved back to Todo), it will no longer be counted as completed and old data
          points may be altered.
        </MDTypography> */}
      </>
    ),
    benchmarkTooltip: {
      stier: `> ${points ? "75% of points" : "75% of issues"} completed per week`,
      average: `${points ? "50-75% of points" : "50-75% of issues"} completed per week`,
      poor: `< ${points ? "50% of points" : "50% of issues"} completed per week`,
    },
    titleUnit: `% of ${points ? "points" : "issues"} completed`,
    unit: `% of ${points ? "points" : "issues"}`,
    unitSingular: `% of ${points ? "points" : "issues"}`,
    highchartsTitle: `Sprint completion`,
  };
}

// State for the filter in the Data Popup
const DEFAULT_ISSUES_COMPLETED_FILTER = {
  // Ids are required by DataGridPro when passing in more than one filter
  id: 1,
  columnField: "current_status_category",
  operatorValue: "equals",
  value: "DONE",
};

function KpiPercentIssuesCompletedCard({
  teamId,
  dateRange,
  bucket: bucketProp, // just in case we support more buckets in the future
  points: pointsProp,
  bucketDropdownOptions,
}) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  // TODO: should handle the null case as a loading state to prevent a flicker
  const [cardState, setCardState] = useState({ bucket: null, points: false });
  useEffect(() => {
    setCardState({
      bucket: bucketProp,
      points: pointsProp,
    });
  }, [bucketProp, pointsProp]);

  const getStateWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.IssuesCompletedPercent);
        return {
          ...cardState,
          ...settings,
        };
      }
      case EditingStates.Normal:
        return cardState;
      default:
        console.log("Invalid editing states mode");
        return cardState;
    }
  };

  const setStateWrapper = (newState) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.IssuesCompletedPercent);
        const newSettings = {
          ...cardState,
          ...settings,
          ...newState,
        };
        editCardSetting(dispatch, CardsEnum.IssuesCompletedPercent, newSettings);
        setCardState(newSettings);
        break;
      }
      case EditingStates.Normal: {
        setCardState({ ...cardState, ...newState });
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setCardState({ ...cardState, ...newState });
      }
    }
  };

  const startDate = encodeURIComponent(dateRange[0].toISOString());
  const endDate = encodeURIComponent(dateRange[1].toISOString());
  const { data } = useApi({
    url: `/api/teams/${teamId}/issues_completed_percent?all=false&start=${startDate}&end=${endDate}&points=${
      getStateWrapper().points
    }`,
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/issues_completed_percent?all=true&start=${startDate}&end=${endDate}&points=${
      getStateWrapper().points
    }`,
  });
  const [filt, setFilt] = useState([DEFAULT_ISSUES_COMPLETED_FILTER]);

  return (
    <SprintKpiCard
      cardType={CardsEnum.IssuesCompletedPercent}
      rawData={data}
      aggregateData={aggregateData?.[0][getStateWrapper().points ? "points" : "count"]}
      description={CARD_DESCRIPTION(getStateWrapper().points)}
      onSelectBucket={(newBucket) => setStateWrapper({ bucket: newBucket })}
      setFilt={setFilt}
      onClosePopup={() => {
        setFilt([DEFAULT_ISSUES_COMPLETED_FILTER]);
      }}
      popupContent={
        <SprintIssueCycleTimeDataGrid
          teamId={teamId}
          filtInput={filt}
          anchorColumn="date_week_done"
          sortField="last_done_time"
        />
      }
      unit={getStateWrapper().points ? "points" : "count"}
      bucketDropdownOptions={bucketDropdownOptions}
      metricsType={
        getStateWrapper().points
          ? MetricsEnum.PointsCompletedPercent
          : MetricsEnum.IssuesCompletedPercent
      }
      dataSourceIcon={mdiJira}
      defaultFilters={[DEFAULT_ISSUES_COMPLETED_FILTER]}
      columnField="last_done_time"
      onSelectDataType={(currType) => setStateWrapper({ points: currType === "points" })}
      dataDropdownOptions={[
        { label: "issues", value: "issues" },
        { label: "points", value: "points" },
      ]}
      dataType={getStateWrapper().points ? "points" : "issues"}
    />
  );
}

KpiPercentIssuesCompletedCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
  points: false,
  bucketDropdownOptions: [{ label: "sprintly", value: "sprint" }],
};

KpiPercentIssuesCompletedCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
  points: PropTypes.bool,
  bucketDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default KpiPercentIssuesCompletedCard;
