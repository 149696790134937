// Base Styles
import colors from "assets/theme-dark/base/colors";
// import borders from "assets/theme-dark/base/borders";

const { border, background } = colors;
// const { borderWidth } = borders;

const card = {
  styleOverrides: {
    root: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minWidth: 0,
      border: `0.5px solid ${border.light}`,
      borderRight: 0,
      borderTop: 0,
      borderRadius: 0,
      wordWrap: "break-word",
      backgroundColor: background.default,
      boxShadow: "none",
      overflow: "visible",
      "&:hover": {
        backgroundColor: background.hover,
        cursor: "default",
      },
    },
  },
};

export default card;
