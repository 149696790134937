import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";
import typography from "assets/theme-dark/base/typography";

import pxToRem from "assets/theme-dark/functions/pxToRem";

const { primary, background, text, grey, transparent, border } = colors;
const { borderRadius } = borders;
const { size } = typography;

const inputOutlined = {
  styleOverrides: {
    root: {
      backgroundColor: background.subtitle,
      fontSize: size.sm,
      borderRadius: borderRadius.md,

      "&:hover": {
        backgroundColor: background.hover,
      },

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: border.light,
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: primary.main,
        },
      },
    },

    notchedOutline: {
      borderColor: border.light,
    },

    input: {
      color: text.main,
      padding: pxToRem(12),
      backgroundColor: transparent.main,

      "&::-webkit-input-placeholder": {
        color: grey[100],
      },
    },

    inputSizeSmall: {
      fontSize: size.xs,
      padding: pxToRem(10),
    },

    multiline: {
      color: grey[700],
      padding: 0,
    },
  },
};

export default inputOutlined;
