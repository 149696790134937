import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useApi } from "utils/apiUtils";

import SIDataGrid from "components_si/SIDataGridPro";

import { convertMillisecondsToReadableTime } from "utils/timeUtils";

// possible anchor columns
const ANCHOR_COLUMNS = ["date_week_start", "date_week_done"];

export default function IssueCycleTimeDataGrid({ teamId, filtInput, anchorColumn, sortField }) {
  const { data: rawData, loading } = useApi({
    url: `/api/teams/${teamId}/issues`,
    defaultData: { data: [], statuses: [] },
    dependencies: [teamId],
  });
  const { data, statuses } = rawData;

  // For each row of the data, add all the global statuses as keys and point to the values
  // so that they're detected as columns in the data grid
  const transformedData = data.map((row) => {
    const statusColumns = {};
    // For each of the global list of statuses
    for (let i = 0; i < statuses.length; i++) {
      const { status } = statuses[i];
      statusColumns[status] = row.statuses[status]?.time_spent_seconds;

      if (status in row) {
        // The rare situation where someone has named their jira status the same
        // thing as one of the other keys in the row -- maybe add a prefix if we
        // actually encounter this issue
        console.error("The status appears as a field in the endpoint");
      }
    }

    return {
      ...row,
      ...statusColumns,
    };
  });

  const columns = [
    // TODO: Currently not using this (April 19th, 2023). Remove at some point if not useful in the future
    // {
    //   field: "date_week_done",
    //   headerName: "Week completed",
    //   flex: 1,
    //   maxWidth: 200,
    // },
    // {
    //   field: "date_week_start",
    //   headerName: "Week started",
    //   flex: 1,
    //   maxWidth: 200,
    // },
    {
      field: "current_assignee",
      headerName: "Assigned to",
      flex: 1,
      maxWidth: 200,
      renderCell: (params) => params.row.current_assignee ?? "--",
    },
    {
      field: "first_in_progress_time",
      headerName: "Date started",
      type: "date",
      flex: 1,
      maxWidth: 200,
      renderCell: (params) =>
        params.row.first_in_progress_time !== null
          ? dayjs(params.row.first_in_progress_time).format("YYYY-MM-DD [at] HH:mm")
          : "",
    },
    {
      field: "last_done_time",
      headerName: "Date completed",
      type: "date",
      flex: 1,
      maxWidth: 200,
      renderCell: (params) =>
        params.row.last_done_time !== null
          ? dayjs(params.row.last_done_time).format("YYYY-MM-DD [at] HH:mm")
          : "",
    },
    {
      field: "issue",
      headerName: "Issue",
      flex: 1,
      minWidth: 300,
      // The wrapping of the key with []s is the same treatment that Jira does
      // for their window.title but we can do better by styling it differently
      valueGetter: (params) => `[${params.row.key}] ${params.row.summary}`,
    },
    {
      field: "current_status",
      headerName: "Current status",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "current_status_category",
      headerName: "Status Category",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "story_points",
      headerName: "Story points",
      flex: 1,
      maxWidth: 200,
    },
  ];

  columns.push(
    ...statuses.map(({ status, category }) => ({
      field: status,
      headerName: status,
      flex: 1,
      maxWidth: 200,
      description: `Status category: ${category
        // convert from SCREAMING_SNAKE_CASE to Title Case
        .toLowerCase()
        .split("_")
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ")}`,
      valueGetter: (params) => {
        const timeSpentSeconds = params.row[params.field];
        return timeSpentSeconds;
      },
      renderCell: (params) => {
        const timeSpent = params.row[params.field];
        if (timeSpent === null || timeSpent === undefined) {
          return undefined;
        }
        return convertMillisecondsToReadableTime(timeSpent * 1000);
      },
    }))
  );

  return (
    <SIDataGrid
      showExportAndFilter
      columns={columns}
      rows={transformedData}
      onRowClick={(params) => window.open(params.row.issue_url)}
      initialState={{
        columns: {
          // Hide the unselected anchor columns
          columnVisibilityModel: ANCHOR_COLUMNS.filter((val) => val !== anchorColumn).reduce(
            (acc, val) => {
              acc[val] = false;
              return acc;
            },
            {
              current_status: false,
            }
          ),
        },
        sorting: {
          sortModel: sortField ? [{ field: sortField, sort: "asc" }] : [],
        },
        filter: {
          filterModel: {
            items: filtInput,
          },
        },
      }}
      sx={{ border: 0 }}
      getRowId={(row) => row.jira_issue_id}
      loading={loading}
    />
  );
}

IssueCycleTimeDataGrid.defaultProps = {
  filtInput: [],
  anchorColumn: undefined,
  sortField: undefined,
};

IssueCycleTimeDataGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
  filtInput: PropTypes.arrayOf(PropTypes.object),
  anchorColumn: PropTypes.oneOf(ANCHOR_COLUMNS),
  // TODO: Let's add the "Week of " text on the frontend instead of calculating them on
  // the backend. Then we can remove the sortField here.
  sortField: PropTypes.string,
};
