// The KPI cards use HighCharts. HighCharts can be customized with options.
// This file contains the default options settings for the dark and light mode basic KPI cards

import dayjs from "dayjs";
import Highcharts from "highcharts";
import darkModeColors from "assets/theme-dark/base/colors";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";

const { text, border, background, graph } = darkModeColors;

//
//
// BASIC LINE KPI CARD DARK MODE
//
//

const chartOptionsDarkMode = (
  kpiName,
  kpiData,
  onClickDataPoint,
  onMouseOverDataPoint,
  onMouseOutDataPoint,
  target
) => ({
  chart: {
    type: "areaspline",
    backgroundColor: "transparent",
    height: 100,
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  colors: [graph.spline],
  credits: {
    enabled: false,
  },
  // No chart title
  title: {
    text: "",
  },
  // Removes the legend at the bottom of the chart
  legend: {
    enabled: false,
  },
  // Datetime enables automatic formatting of dates on the x-axis
  xAxis: {
    type: "datetime",
    visible: false,
  },
  yAxis: {
    title: "",
    visible: true,
    lineColor: border.main,
    labels: {
      enabled: false,
    },
    lineWidth: 0,
    tickWidth: 0,
    gridLineWidth: 0,
    // Enables the data to take all available space
    endOnTick: false,
    plotLines: [
      {
        value: target,
        color: border.light,
        dashStyle: "dash",
        width: 2,
      },
    ],
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    series: {
      cursor: "pointer",
      lineWidth: 1,
      point: {
        events: {
          click() {
            onClickDataPoint(this.x, this.index);
          },
          mouseOver() {
            onMouseOverDataPoint(this.x, this.y);
          },
          mouseOut() {
            onMouseOutDataPoint();
          },
        },
      },
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 0.9 },
        stops: [
          [0, graph.area],
          [0.6, graph.breakpoint],
          [1, background.default],
        ],
      },
      // Remove the datapoint markers on the graph, except on hover
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
  // Data for the graph
  series: [
    {
      name: kpiName,
      data: kpiData,
    },
  ],
});

//
//
// BASIC LINE KPI CARD DARK MODE ON HOVER
//
//

const chartOptionsDarkModeOnHover = (
  kpiName,
  kpiData,
  onClickDataPoint,
  onMouseOverDataPoint,
  onMouseOutDataPoint,
  bucket,
  kpiUnit,
  formatTooltip,
  target
) => ({
  chart: {
    type: "areaspline",
    backgroundColor: "transparent",
    height: 120,
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  colors: [graph.spline], // graph.spline css color
  credits: {
    enabled: false,
  },
  // No chart title
  title: {
    text: "",
  },
  // Removes the legend at the bottom of the chart
  legend: {
    enabled: false,
  },
  // Datetime enables automatic formatting of dates on the x-axis
  xAxis: {
    type: "datetime",
    // ON HOVER mode turns on the x-axis
    lineColor: border.main,
    tickColor: border.main,
    labels: {
      style: {
        color: border.main,
      },
      formatter() {
        if (bucket === "week") {
          return `${Highcharts.dateFormat("%b %e", this.value)}`;
        }
        // Would be better to just take the original default "best guess"
        let monthString = `${Highcharts.dateFormat("%b '%Y", this.value)}`;
        monthString = `${monthString.substring(0, 5)}${monthString.substring(7, 9)}`;
        return monthString;
      },
    },
  },
  yAxis: {
    title: "",
    visible: true,
    lineColor: border.main,
    labels: {
      enabled: false,
    },
    lineWidth: 0,
    tickWidth: 0,
    gridLineWidth: 0,
    // Enables the data to take all available space
    endOnTick: false,
    plotLines: target
      ? [
          {
            value: target, // The y value where the line will be drawn
            color: border.light,
            dashStyle: "dash",
            width: 2,
            label: {
              text: `<span style="display: inline-block; border: 1px solid ${border.light}; background-color: ${border.subtitle}; padding: 2px 4px; border-radius: 3px;">target</span>`,
              useHTML: true,
              align: "center",
              verticalAlign: "middle",
              x: 0,
              y: 0, // Need to explicity set to 0, default is something else
              style: {
                fontFamily: '"Cascadia Code", Menlo, "Lucida Console", Consolas, monospace',
                color: border.main,
              },
            },
          },
        ]
      : [],
  },
  tooltip: {
    // Custom formatter function for the tooltip
    formatter() {
      return formatTooltip({
        date: this.x,
        data: this.y,
        bucketSize: bucket,
        unitDescription: kpiUnit,
      });
    },
    borderRadius: 10,
    backgroundColor: background.subtitle,
    borderColor: border.light,
    style: {
      color: text.title,
      opacity: 0.9,
    },
    // Remove the shadow around the tooltip
    shadow: false,
  },
  plotOptions: {
    series: {
      cursor: "pointer",
      lineWidth: 1,
      point: {
        events: {
          click() {
            onClickDataPoint(this.x, this.index);
          },
          mouseOver() {
            onMouseOverDataPoint(this.index, this.y);
          },
          mouseOut() {
            onMouseOutDataPoint();
          },
        },
      },
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 0.9 }, // vector for gradient
        stops: [
          [0, graph.area], // start point graph.area css color
          [0.8, graph.breakpoint],
          [1, background.default], // endpoint for gradient
        ],
      },
      // Remove the datapoint markers on the graph, except on hover
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
  // Data for the graph
  series: [
    {
      name: kpiName,
      data: kpiData,
    },
  ],
});

//
//
// BASIC BAR CHART KPI CARD DARK MODE
//
//

const chartOptionsBarDarkMode = (
  kpiName,
  kpiData,
  onClickDataPoint,
  onMouseOverDataPoint,
  onMouseOutDataPoint,
  isStacked
) => ({
  chart: {
    type: "column",
    backgroundColor: "transparent",
    height: 100,
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    },
    events: {
      load() {
        if (isStacked) {
          this.series[0].update({
            color: background.default,
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  // No chart title
  title: {
    text: "",
  },
  // Removes the legend at the bottom of the chart
  legend: {
    enabled: false,
  },
  xAxis: {
    type: "category",
    categories: kpiData ? Object.values(kpiData).map((x) => x.name) : [],
    lineColor: border.main,
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    title: {
      text: "",
    },
    visible: false,
    // Removes gridlines
    gridLineWidth: 0,
    // Enables the data to take all available space
    endOnTick: false,
    // Custom function to format miliseconds into readable y-axis, example output: 27h, 35m
    labels: {
      formatter() {
        const time = convertMillisecondsToReadableTime(this.value);
        return `${time}`;
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    series: {
      cursor: "pointer",
      stacking: "normal",
      lineWidth: 1,
      point: {
        events: {
          click() {
            onClickDataPoint(this.x, this.index);
          },
          mouseOver() {
            onMouseOverDataPoint(this.x, this.y);
          },
          mouseOut() {
            onMouseOutDataPoint();
          },
        },
      },
      borderColor: graph.spline,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, graph.area],
          [0.6, graph.breakpoint],
          [1, background.default],
        ],
      },
    },
  },
  // Data for the graph
  series: [
    ...(isStacked && kpiData
      ? [
          {
            name: "empty",
            data:
              kpiData !== null
                ? Object.values(kpiData)
                    .map((x) => [x.name, x.data === 0 ? 0 : 100 - x.data])
                    .sort((x) => x.start_date)
                : [],
            dashStyle: "longdash",
          },
        ]
      : []),
    {
      name: kpiName,
      data: kpiData
        ? Object.values(kpiData)
            .map((x) => [x.name, x.data])
            .sort((x) => x.start_date)
        : [],
    },
  ],
});

//
//
// BASIC BAR KPI CARD DARK MODE ON HOVER
//
//

const chartOptionsBarDarkModeOnHover = (
  kpiName,
  kpiData,
  onClickDataPoint,
  onMouseOverDataPoint,
  onMouseOutDataPoint,
  kpiUnit,
  formatTooltip,
  isStacked
) => ({
  chart: {
    type: "column",
    backgroundColor: "transparent",
    height: 120,
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    events: {
      load() {
        if (isStacked) {
          this.series[0].update({
            color: background.default,
            states: {
              hover: {
                enabled: false,
                color: background.default,
              },
            },
          });
        }
      },
    },
  },
  credits: {
    enabled: false,
  },
  // No chart title
  title: {
    text: "",
  },
  // Removes the legend at the bottom of the chart
  legend: {
    enabled: false,
  },
  // Datetime enables automatic formatting of dates on the x-axis
  xAxis: {
    type: "category",
    categories: kpiData ? Object.values(kpiData).map((x) => x.name) : [],
    // ON HOVER mode turns on the x-axis\
    lineColor: border.main,
    tickColor: border.main,
    labels: {
      style: {
        color: border.main,
      },
    },
  },
  yAxis: {
    title: {
      text: "",
    },
    visible: false,
    // Removes gridlines
    gridLineWidth: 0,
    // Enables the data to take all available space
    endOnTick: false,
    // Custom function to format miliseconds into readable y-axis, example output: 27h, 35m
    labels: {
      formatter() {
        const time = convertMillisecondsToReadableTime(this.value);
        return `${time}`;
      },
    },
  },
  tooltip: {
    // Custom formatter function for the tooltip
    positioner: isStacked
      ? function positioner(labelWidth, labelHeight, point) {
          return {
            // magic centering number :)
            x: point.plotX - labelWidth / 2.5,
            y:
              this.chart.hoverPoint.series.yAxis.toPixels(this.chart.hoverPoint.stackTotal, true) +
              labelHeight / 2.5,
          };
        }
      : undefined,
    formatter() {
      return formatTooltip({
        date: this.x,
        unitDescription: kpiUnit,
      });
    },
    borderRadius: 10,
    backgroundColor: background.subtitle,
    borderColor: border.light,
    style: {
      color: text.title,
      opacity: 0.9,
    },
    // Remove the shadow around the tooltip
    shadow: false,
  },
  plotOptions: {
    series: {
      cursor: "pointer",
      stacking: "normal",
      lineWidth: 1,
      point: {
        events: {
          click() {
            onClickDataPoint(this.x, this.index);
          },
          mouseOver() {
            onMouseOverDataPoint(this.index, this.y);
          },
          mouseOut() {
            onMouseOutDataPoint();
          },
        },
      },
      borderColor: graph.spline,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, graph.area],
          [0.6, graph.breakpoint],
          [1, background.default],
        ],
      },
    },
  },
  // Data for the graph
  series: [
    ...(isStacked && kpiData
      ? [
          {
            name: "empty",
            data:
              kpiData !== null
                ? Object.values(kpiData)
                    .map((x) => [x.name, x.data === 0 ? 0 : 100 - x.data])
                    .sort((x) => x.start_date)
                : [],
            dashStyle: "longdash",
          },
        ]
      : []),
    {
      name: kpiName,
      data: kpiData
        ? Object.values(kpiData)
            .map((x) => [x.name, x.data])
            .sort((x) => x.start_date)
        : [],
    },
  ],
});

//
//
// BASIC LINE KPI CARD LIGHT MODE
//
//

const chartOptionsLightMode = (kpiName, kpiData, onClickDataPoint) => ({
  chart: {
    type: "areaspline",
    backgroundColor: "transparent",
    height: 100,
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  credits: {
    enabled: false,
  },
  // No chart title
  title: {
    text: "",
  },
  // Removes the legend at the bottom of the chart, since only displaying cycle time
  legend: {
    enabled: false,
  },
  // Datetime enables automatic formatting of dates on the x-axis
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    title: {
      text: "",
    },
    visible: false,
    // Removes gridlines
    gridLineWidth: 0,
    // Enables the data to take all available space
    endOnTick: false,
  },
  tooltip: {
    // Custom formatter function for the tooltip
    formatter() {
      return `<b>${Highcharts.dateFormat("Week of %b %d", this.x)}</b></br>${kpiName}: ${Math.round(
        this.y
      ).toLocaleString("en-US")}`;
    },
    // Remove the shadow around the tooltip
    shadow: false,
  },
  plotOptions: {
    series: {
      cursor: "pointer",
      point: {
        events: {
          click() {
            onClickDataPoint(this.x, this.index);
          },
        },
      },
      fillColor: {
        linearGradient: [0, 0, 0, 350],
        stops: [
          [0, Highcharts.getOptions().colors[0]],
          [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get("rgba")],
        ],
      },
      // Remove the datapoint markers on the graph, except on hover
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
  // Data for the graph
  series: [
    {
      name: kpiName,
      data: kpiData,
    },
  ],
});

//
//
// BASIC SCATTER KPI CARD DARK MODE
//
//

const chartOptionsScatterDarkMode = (
  _kpiName,
  kpiData,
  onClickDataPoint,
  _onMouseOverDataPoint,
  _onMouseOutDataPoint,
  target
) => ({
  chart: {
    type: "scatter",
    backgroundColor: "transparent",
    height: 100,
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  credits: {
    enabled: false,
  },
  // No chart title
  title: {
    text: "",
  },
  // Removes the legend at the bottom of the chart
  legend: {
    enabled: false,
  },
  // Datetime enables automatic formatting of dates on the x-axis
  xAxis: {
    // TODO: Remove this on hover
    visible: false,
    // categories: ["Week of Apr 17", "Week of Apr 24", "Week of Apr 31", "Week of May 7"],
    categories:
      kpiData !== null
        ? kpiData.map((item) => Highcharts.dateFormat("%b %d", Number(item.name)))
        : [],
    lineColor: border.main,
    tickColor: border.main,
    lineWidth: 0,
    labels: {
      style: {
        color: border.main,
      },
    },
  },
  yAxis: {
    title: "",
    visible: true,
    lineColor: border.main,
    labels: {
      enabled: false,
    },
    lineWidth: 0,
    tickWidth: 0,
    gridLineWidth: 0,
    // Enables the data to take all available space
    endOnTick: false,
    plotLines: [
      {
        value: target,
        color: border.light,
        dashStyle: "dash",
        width: 2,
      },
    ],
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    scatter: {
      cursor: "pointer",
      color: graph.area,
      opacity: 0.8,
      jitter: {
        x: 0.1,
        y: 0,
      },
      point: {
        events: {
          click() {
            onClickDataPoint(this.x, this.index);
          },
        },
      },
      // Remove the datapoint markers on the graph, except on hover
      marker: {
        symbol: "circle",
        radius: 3,
        lineColor: graph.spline,
        lineWidth: 0.2,
      },
    },
  },
  series: kpiData,
});

//
//
// BASIC SCATTER KPI CARD DARK MODE ON HOVER
//
//

const chartOptionsScatterDarkModeOnHover = (
  _kpiName,
  kpiData,
  onClickDataPoint,
  _onMouseOverDataPoint,
  _onMouseOutDataPoint,
  kpiUnit,
  formatTooltip,
  target
) => ({
  chart: {
    type: "scatter",
    backgroundColor: "transparent",
    height: 120,
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  credits: {
    enabled: false,
  },
  // No chart title
  title: {
    text: "",
  },
  // Removes the legend at the bottom of the chart
  legend: {
    enabled: false,
  },
  // Datetime enables automatic formatting of dates on the x-axis
  xAxis: {
    visible: true,
    // categories: ["Week of Apr 17", "Week of Apr 24", "Week of Apr 31", "Week of May 7"],
    categories:
      kpiData !== null
        ? kpiData.map((item) => Highcharts.dateFormat("%b %d", Number(item.name)))
        : [],
    lineColor: border.main,
    tickColor: border.main,
    labels: {
      style: {
        color: border.main,
      },
    },
  },
  yAxis: {
    title: "",
    visible: true,
    lineColor: border.main,
    labels: {
      enabled: false,
    },
    lineWidth: 0,
    tickWidth: 0,
    gridLineWidth: 0,
    // Enables the data to take all available space
    endOnTick: false,
    plotLines: target
      ? [
          {
            value: target, // The y value where the line will be drawn
            color: border.light,
            dashStyle: "dash",
            width: 2,
            label: {
              text: `<span style="display: inline-block; border: 1px solid ${border.light}; background-color: ${border.subtitle}; padding: 2px 4px; border-radius: 3px;">target</span>`,
              useHTML: true,
              align: "center",
              verticalAlign: "middle",
              x: 0,
              y: 0, // Need to explicity set to 0, default is something else
              style: {
                fontFamily: '"Cascadia Code", Menlo, "Lucida Console", Consolas, monospace',
                color: border.main,
              },
            },
          },
        ]
      : [],
  },
  tooltip: {
    formatter() {
      return formatTooltip({
        title: kpiData[this.point.x].data[this.point.index][2],
        data: this.y,
        unitDescription: kpiUnit,
      });
    },
    borderRadius: 10,
    backgroundColor: background.subtitle,
    borderColor: border.light,
    style: {
      color: text.title,
      opacity: 0.9,
    },
    // Remove the shadow around the tooltip
    shadow: false,
  },
  plotOptions: {
    scatter: {
      cursor: "pointer",
      color: graph.area,
      opacity: 0.8,
      jitter: {
        x: 0.1,
        y: 0,
      },
      point: {
        events: {
          click() {
            // Get the pr_title associated with the clicked data point
            onClickDataPoint(kpiData[this.x].data[this.index][2], this.index);
          },
        },
      },
      // Remove the datapoint markers on the graph, except on hover
      marker: {
        symbol: "circle",
        radius: 3,
        lineColor: graph.spline,
        lineWidth: 0.2,
      },
    },
  },
  series: kpiData,
});

//
//
// CYCLE TIME LINE KPI CARD DARK MODE
//
//

const chartOptionsCycleTimeDarkMode = (
  kpiName,
  kpiData,
  onClickDataPoint,
  onMouseOverDataPoint,
  onMouseOutDataPoint
) => ({
  chart: {
    type: "areaspline",
    backgroundColor: "transparent",
    height: 200,
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  colors: [graph.spline],
  credits: {
    enabled: false,
  },
  // No chart title
  title: {
    text: "",
  },
  // Removes the legend at the bottom of the chart, since only displaying cycle time
  legend: {
    enabled: false,
  },
  // Datetime enables automatic formatting of dates on the x-axis
  xAxis: {
    type: "datetime",
    tickPixelInterval: 125,
    visible: false,
  },
  yAxis: {
    title: {
      text: "",
    },
    visible: false,
    // Removes gridlines
    gridLineWidth: 0,
    // Enables the data to take all available space
    endOnTick: false,
    // Custom function to format miliseconds into readable y-axis, example output: 27h, 35m
    labels: {
      formatter() {
        const time = convertMillisecondsToReadableTime(this.value);
        return `${time}`;
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    series: {
      cursor: "pointer",
      lineWidth: 1,
      point: {
        events: {
          click() {
            onClickDataPoint(this.x, this.index);
          },
          mouseOver() {
            onMouseOverDataPoint(this.x);
          },
          mouseOut() {
            onMouseOutDataPoint();
          },
        },
      },
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 0.9 },
        stops: [
          [0, graph.area],
          [0.8, graph.breakpoint],
          [1, background.default],
        ],
      },
      // Remove the datapoint markers on the graph, except on hover
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
  // Data for the graph
  series: [
    {
      name: kpiName,
      data: kpiData,
    },
  ],
});

//
//
// CYCLE TIME LINE KPI CARD DARK MODE ON HOVER
//
//

const chartOptionsCycleTimeDarkModeOnHover = (
  kpiName,
  kpiData,
  onClickDataPoint,
  onMouseOverDataPoint,
  onMouseOutDataPoint,
  bucket
) => ({
  chart: {
    type: "areaspline",
    backgroundColor: "transparent",
    height: 220,
    style: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  colors: [graph.spline],
  credits: {
    enabled: false,
  },
  // No chart title
  title: {
    text: "",
  },
  // Removes the legend at the bottom of the chart, since only displaying cycle time
  legend: {
    enabled: false,
  },
  // Datetime enables automatic formatting of dates on the x-axis
  xAxis: {
    type: "datetime",
    tickPixelInterval: 125,
    lineColor: border.main,
    tickColor: border.main,
    labels: {
      style: {
        color: border.main,
      },
      formatter() {
        if (bucket === "week") {
          return `${Highcharts.dateFormat("%b %e", this.value)}`;
        }
        // Would be better to just take the original default "best guess"
        let monthString = `${Highcharts.dateFormat("%b '%Y", this.value)}`;
        monthString = `${monthString.substring(0, 5)}${monthString.substring(7, 9)}`;
        return monthString;
      },
    },
  },
  yAxis: {
    title: {
      text: "",
    },
    visible: false,
    // Removes gridlines
    gridLineWidth: 0,
    // Enables the data to take all available space
    endOnTick: false,
    // Custom function to format miliseconds into readable y-axis, example output: 27h, 35m
    labels: {
      formatter() {
        const time = convertMillisecondsToReadableTime(this.value);
        return `${time}`;
      },
    },
  },
  tooltip: {
    // Custom formatter function for the tooltip
    formatter() {
      // Get the first date and second date
      const startDate = dayjs(this.x);
      let formattedDatapoint = "--";

      if (this.y || this.y === 0) {
        formattedDatapoint = convertMillisecondsToReadableTime(this.y);
      }

      switch (bucket) {
        case "week": {
          const endDate = startDate.add(6, "day");

          // Check if the months are different
          const includeMonth = startDate.month() !== endDate.month();

          // Format the second date depending on whether the months are different
          const secondDateFormatted = includeMonth
            ? Highcharts.dateFormat("%b %e", endDate)
            : Highcharts.dateFormat("%e", endDate);
          return `<b>${Highcharts.dateFormat(
            "%b %e",
            startDate
          )} - ${secondDateFormatted}</b></br>${formattedDatapoint}`;
        }
        case "month": {
          return `<b>${Highcharts.dateFormat("%B %Y", startDate)}</b></br>${formattedDatapoint}`;
        }
        default: {
          return `<b>${Highcharts.dateFormat("%b %e", startDate)}</b></br>${formattedDatapoint}`;
        }
      }
    },
    borderRadius: 10,
    backgroundColor: background.subtitle,
    borderColor: border.light,
    style: {
      color: text.title,
      opacity: 0.9,
    },
    // Remove the shadow around the tooltip
    shadow: false,
  },
  plotOptions: {
    series: {
      cursor: "pointer",
      lineWidth: 1,
      point: {
        events: {
          click() {
            onClickDataPoint(this.x, this.index);
          },
          mouseOver() {
            onMouseOverDataPoint(this.x);
          },
          mouseOut() {
            onMouseOutDataPoint();
          },
        },
      },
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 0.9 },
        stops: [
          [0, graph.area],
          [0.8, graph.breakpoint],
          [1, background.default],
        ],
      },
      // Remove the datapoint markers on the graph, except on hover
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
  // Data for the graph
  series: [
    {
      name: kpiName,
      data: kpiData,
    },
  ],
});

// eslint-disable-next-line import/prefer-default-export
export {
  chartOptionsDarkMode,
  chartOptionsDarkModeOnHover,
  chartOptionsScatterDarkMode,
  chartOptionsScatterDarkModeOnHover,
  chartOptionsBarDarkMode,
  chartOptionsBarDarkModeOnHover,
  chartOptionsLightMode,
  chartOptionsCycleTimeDarkMode,
  chartOptionsCycleTimeDarkModeOnHover,
};
