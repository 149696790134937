import PropTypes from "prop-types";
import { useState } from "react";
import { mdiGithub, mdiGitlab } from "@mdi/js"; // https://materialdesignicons.com/
import dayjs from "dayjs";
import { CardsEnum } from "components_si/EditingContext";
import services from "utils/services";
import GraphTypeEnum from "layouts/projects/utils/GraphTypeEnum";
import BasicKpiCard from "./BasicKpiCard";
import CycleTimeDataGrid from "../CycleTimeDataGrid";

// TODO: can we make this just pass on all the fields from BasicKpiCard except some that we override?
function GithubKpiCard({
  cardType,
  data,
  scatterData,
  bucket,
  graph,
  onSelectBucket,
  onSelectGraph,
  aggregateData,
  renderBenchmark,
  formatTooltip,
  formatTooltipScatter,
  graphDropdownOptions,
  renderMetricDisplay,
  description,
  teamId,
  baseFilters,
  service,
  actualStartDate,
  targetLine,
  targetScatter,
}) {
  // Ids are required by DataGridPro when passing in more than one filter
  const indexedBaseFilters = baseFilters.map((obj, idx) => ({ ...obj, id: idx }));

  // State for the filter in the Data Popup
  const [filt, setFilt] = useState(indexedBaseFilters);

  return (
    <BasicKpiCard
      cardType={cardType}
      data={data}
      scatterData={scatterData}
      bucket={bucket}
      graph={graph}
      renderMetricDisplay={renderMetricDisplay}
      onSelectBucket={onSelectBucket}
      onSelectGraph={onSelectGraph}
      aggregateData={aggregateData}
      renderBenchmark={renderBenchmark}
      targetLine={targetLine}
      targetScatter={targetScatter}
      formatTooltip={formatTooltip}
      formatTooltipScatter={formatTooltipScatter}
      graphDropdownOptions={graphDropdownOptions}
      description={description}
      teamId={teamId}
      dataSourceIcon={service === "gitlab" ? mdiGitlab : mdiGithub}
      popupContent={<CycleTimeDataGrid teamId={teamId} service={service} filtInput={filt} />}
      onClickDataPoint={(x, index) => {
        let filterStartDate = dayjs(x).format("YYYY-MM-DD");
        let filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");

        if (index === 0) {
          filterStartDate = actualStartDate.format("YYYY-MM-DD");
        }

        if (bucket === "week") {
          filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");
        } else if (bucket === "month") {
          filterEndDate = dayjs(x).add(1, "month").format("YYYY-MM-DD");
        } else if (bucket === null) {
          // TODO: handle loading state
        } else {
          console.log("unsupported bucket type");
          filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");
        }

        const tmp = [
          ...baseFilters,
          {
            id: baseFilters.length + 1,
            columnField: "pr_created_at",
            operatorValue: "onOrAfter",
            value: filterStartDate,
          },
          {
            id: baseFilters.length + 2,
            columnField: "pr_created_at",
            operatorValue: "before",
            value: filterEndDate,
          },
        ];
        setFilt(tmp.map((obj, idx) => ({ ...obj, id: idx })));
      }}
      onClickDataPointScatter={(x) => {
        const tmp = [
          ...baseFilters,
          {
            id: baseFilters.length + 1,
            columnField: "pr_title",
            operatorValue: "equals",
            value: x,
          },
        ];
        setFilt(tmp.map((obj, idx) => ({ ...obj, id: idx })));
      }}
      onClosePopup={() => {
        setFilt(indexedBaseFilters);
      }}
    />
  );
}

// Setting default values for the props
GithubKpiCard.defaultProps = {
  data: null,
  scatterData: null,
  bucket: "week",
  graph: GraphTypeEnum.Line,
  aggregateData: null,
  baseFilters: [],
  renderBenchmark: undefined,
  formatTooltip: undefined,
  formatTooltipScatter: undefined,
  renderMetricDisplay: undefined,
  onSelectBucket: undefined,
  onSelectGraph: undefined,
  graphDropdownOptions: undefined,
  targetLine: null,
  targetScatter: null,
};

// Typechecking props
GithubKpiCard.propTypes = {
  cardType: PropTypes.oneOf(Object.values(CardsEnum)).isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  // eslint-disable-next-line react/forbid-prop-types
  scatterData: PropTypes.any,
  bucket: PropTypes.string,
  graph: PropTypes.oneOf(Object.values(GraphTypeEnum)),
  onSelectBucket: PropTypes.func,
  onSelectGraph: PropTypes.func,
  aggregateData: PropTypes.number,
  renderBenchmark: PropTypes.func,
  formatTooltip: PropTypes.func,
  formatTooltipScatter: PropTypes.func,
  renderMetricDisplay: PropTypes.func,
  description: PropTypes.exact({
    title: PropTypes.string,
    shortDefinition: PropTypes.string,
    longDefinition: PropTypes.node,
    benchmarkTooltip: PropTypes.shape({
      stier: PropTypes.string.isRequired,
      average: PropTypes.string.isRequired,
      poor: PropTypes.string.isRequired,
    }),
    unit: PropTypes.string,
    unitSingular: PropTypes.string,
    highchartsTitle: PropTypes.string,
  }).isRequired,
  graphDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOf(Object.values(GraphTypeEnum)),
    })
  ),
  teamId: PropTypes.string.isRequired,
  baseFilters: PropTypes.arrayOf(
    PropTypes.exact({
      columnField: PropTypes.string,
      operatorValue: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  /*
   * The start date in the data is not the actual start date when the selected
   * date range is not at the beginning of the week.
   */
  actualStartDate: PropTypes.instanceOf(dayjs).isRequired,
  targetLine: PropTypes.number,
  targetScatter: PropTypes.number,
};

export default GithubKpiCard;
