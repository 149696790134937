const MetricsEnum = {
  CodingTime: "metric_coding",
  PickupTime: "metric_pickup",
  ReviewTime: "metric_review",
  CycleTime: "metric_cycle",
  ChangedLines: "metric_changedLines", // aka additions & deletions
  PrsOpened: "metric_prsOpened",
  IssuesCompleted: "metric_issuesCompleted",
  PointsCompleted: "metric_pointsCompleted",
  IssueCycleTime: "metric_issueCycleTime",
  IssuesCompletedPercent: "metric_issuesCompletedPercent",
  PointsCompletedPercent: "metric_pointsCompletedPercent",
  PrSize: "metric_prSize",
  MergedWithoutReview: "metric_mergedWithoutReview",
  ReviewDepth: "metric_reviewDepth",
  ReviewsSubmitted: "metric_reviewsSubmitted",
  DeploymentsCreated: "metric_deploymentsCreated",
};

const TiersEnum = {
  STier: "s-tier",
  Average: "average",
  Poor: "poor",
  None: "none",
};

function _createBreakPointEvaluatorForAverages(tierBreakPoints, biggerIsBetter) {
  if (tierBreakPoints.length !== 2) {
    throw new Error(
      "tier breakpoints must be configured with 2 items because we only have 3 tiers"
    );
  }

  if (tierBreakPoints[0] > tierBreakPoints[1]) {
    throw new Error("tier breakpoints must be sorted in ascending order");
  }

  if (biggerIsBetter) {
    // (-inf, bp[0]), [bp[0], bp[1]], (bp[1], inf)
    // poor,          average,        s-tier
    return (valueCompare, _numWeeks) => {
      if (valueCompare > tierBreakPoints[1]) {
        return TiersEnum.STier;
      }
      if (valueCompare >= tierBreakPoints[0]) {
        return TiersEnum.Average;
      }
      return TiersEnum.Poor;
    };
  }

  return (valueCompare, _numWeeks) => {
    // (-inf, bp[0]), [bp[0], bp[1]], (bp[1], inf)
    // s-tier,        average,        poor
    if (valueCompare < tierBreakPoints[0]) {
      return TiersEnum.STier;
    }
    if (valueCompare <= tierBreakPoints[1]) {
      return TiersEnum.Average;
    }
    return TiersEnum.Poor;
  };
}

function _createBreakPointEvaluatorForCounts(tierBreakPoints, biggerIsBetter) {
  if (tierBreakPoints.length !== 2) {
    throw new Error(
      "tier breakpoints must be configured with 2 items because we only have 3 tiers"
    );
  }

  if (tierBreakPoints[0] > tierBreakPoints[1]) {
    throw new Error("tier breakpoints must be sorted in ascending order");
  }

  if (biggerIsBetter) {
    // (-inf, bp[0]), [bp[0], bp[1]], (bp[1], inf)
    // poor,          average,        s-tier
    return (valueCompare, numWeeks) => {
      if (valueCompare > tierBreakPoints[1] * numWeeks) {
        return TiersEnum.STier;
      }
      if (valueCompare >= tierBreakPoints[0] * numWeeks) {
        return TiersEnum.Average;
      }
      return TiersEnum.Poor;
    };
  }

  return (valueCompare, numWeeks) => {
    // (-inf, bp[0]), [bp[0], bp[1]], (bp[1], inf)
    // s-tier,        average,        poor
    if (valueCompare < tierBreakPoints[0] * numWeeks) {
      return TiersEnum.STier;
    }
    if (valueCompare <= tierBreakPoints[1] * numWeeks) {
      return TiersEnum.Average;
    }
    return TiersEnum.Poor;
  };
}

const TIER_EVALUATOR_FUNCTIONS = {
  [MetricsEnum.CodingTime]: _createBreakPointEvaluatorForAverages(
    [
      // S-tier is less than 12 hours
      12 * 60 * 60,
      // Average is between 12 and 36 hours
      36 * 60 * 60,
    ],
    false
  ),
  [MetricsEnum.PickupTime]: _createBreakPointEvaluatorForAverages(
    [
      // S-tier is less than 6 hours
      6 * 60 * 60,
      // Average is between 6-18 hours
      18 * 60 * 60,
    ],
    false
  ),
  [MetricsEnum.ReviewTime]: _createBreakPointEvaluatorForAverages(
    [
      // S-tier is less than 6 hours
      6 * 60 * 60,
      // Average is between 6-24 hours
      24 * 60 * 60,
    ],
    false
  ),
  [MetricsEnum.CycleTime]: _createBreakPointEvaluatorForAverages(
    [
      // S-tier is less than 24 hours
      24 * 60 * 60,
      // Average is between 24-72 hours
      72 * 60 * 60,
    ],
    false
  ),
  [MetricsEnum.ChangedLines]: _createBreakPointEvaluatorForCounts([1000, 2000], true),
  [MetricsEnum.PrsOpened]: _createBreakPointEvaluatorForCounts([4, 8], true),
  [MetricsEnum.IssuesCompleted]: _createBreakPointEvaluatorForCounts([4, 8], true),
  [MetricsEnum.PointsCompleted]: _createBreakPointEvaluatorForCounts([8, 16], true),
  [MetricsEnum.IssueCycleTime]: _createBreakPointEvaluatorForAverages(
    [
      // less than 1 day is s tier
      24 * 60 * 60 * 1000, // input unit is milliseconds
      // less than 2 days is average
      2 * 24 * 60 * 60 * 1000, // input unit is milliseconds
    ],
    false
  ),
  [MetricsEnum.IssuesCompletedPercent]: _createBreakPointEvaluatorForAverages([50, 75], true),
  [MetricsEnum.PointsCompletedPercent]: _createBreakPointEvaluatorForAverages([50, 75], true),
  [MetricsEnum.PrSize]: _createBreakPointEvaluatorForAverages([600, 1200], false),
  [MetricsEnum.MergedWithoutReview]: _createBreakPointEvaluatorForCounts([0.000001, 5], false),
  [MetricsEnum.ReviewDepth]: _createBreakPointEvaluatorForAverages([3, 5], true),
  [MetricsEnum.ReviewsSubmitted]: _createBreakPointEvaluatorForCounts([4, 8], true),
  [MetricsEnum.DeploymentsCreated]: _createBreakPointEvaluatorForCounts([1, 5], true),
};

/**
 * @param {*} valueCompare - Duration to benchmark, in seconds
 * @param {*} type - segment of cycle time, can either be "coding", "pickup", "review", or "cycle"
 * @returns benchmarks: which is either "s-tier", "average", or "poor"
 */
function evaluateTier(valueCompare, type, numWeeks = 1) {
  if (!(type in TIER_EVALUATOR_FUNCTIONS)) {
    // eslint-disable-next-line no-console
    console.error("Not a valid case for the cycle time benchmarks");
    return (_valueCompare) => TiersEnum;
  }

  /*
     If no data, set benchmark to none.

     Case 1: If there is no data for the week, this value is null.
     Case 2; If there is a count but no time (e.g., for pickup time) api returns "0.000000".
     Case 3; if the value is formatted, it can be "--"
    */
  if (valueCompare === "0.000000" || valueCompare === null || valueCompare === "--") {
    return TiersEnum.None;
  }
  return TIER_EVALUATOR_FUNCTIONS[type](valueCompare, numWeeks);
}

export { MetricsEnum, TiersEnum, evaluateTier };
