// This has expanded to be more of a metrics helper so misnamed.
// But fix the other todos and this file should mostly disappear.

// TODO: Replace settings in this file with db-based variables

// SETTING: Whether to show aggregate across date range
// or to show just the latest week
const SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVERING = true;

/*
TODO: these sentinels are kind of messy:
- They assume that there are no naturally occurring instances of -1 and -2 but that's potentially not
  true in the future

What we should probably do:
- split it into two sets of sentinel values for cycle time card and basic kpi cards.
- Collocate the sentinel values to their respective files
- Use strings instead of numbers because they're more guaranteed to be outside of a number that might occur
  - this could give us issues in the future if we ever switch to typescript, but whateva
- But first make sure that we handle the cases where we want the no data points case to be treated the same as the 0 case
*/

// Data has either not been fetched yet or it's empty
const NO_DATA_POINTS_SENTINEL = -1;

// We want to show the aggregate
// Should only be set when data has been fetched
const AGGREGATE_DATA_POINT_SENTINEL = -2;

function shouldShowAggregate(dataPoint) {
  return (
    // TODO: I think I can swap this with a check on just SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVER && !== NO_DATA_POINTS_SENTINEL
    // and that would mean that we wouldn't need the aggregate sentinel value -- though it does mean that the regular path has to handle no data the same way as the aggregate
    SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVERING && dataPoint === AGGREGATE_DATA_POINT_SENTINEL
  );
}

export {
  SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVERING,
  NO_DATA_POINTS_SENTINEL,
  AGGREGATE_DATA_POINT_SENTINEL,
  shouldShowAggregate,
};
