// Base Styles
import colors from "assets/theme-dark/base/colors";

const { text } = colors;

const pickersArrowSwitcher = {
  styleOverrides: {
    root: {
      color: text.main, // This is the color of the months at the top of the calendar
    },
  },
};

export default pickersArrowSwitcher;
