/**
 * Basic Setori card
 */

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

function ListCard({ children }) {
  return (
    <Card sx={{ height: 1 }}>
      <CardContent>
        {/* <MDTypography contentEditable="true" variant="body2" fontSize="18px"> */}
        <MDTypography variant="body2" fontSize="18px">
          {children}
        </MDTypography>
      </CardContent>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
ListCard.defaultProps = {
  children: "",
};

// Typechecking props for the InsightCard
ListCard.propTypes = {
  children: PropTypes.node,
};

export default ListCard;
