// Base Styles
import colors from "assets/theme-dark/base/colors";

const { text } = colors;

const dayCalendar = {
  styleOverrides: {
    weekDayLabel: {
      color: text.subtitle, // This is the color of the months at the top of the calendar
    },
  },
};

export default dayCalendar;
