/**
 * Layout for the mission view
 */
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import TeamAvatarName from "components_si/TeamAvatarName";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import VelocityMission from "layouts/missions/VelocityMission";
import ReviewMission from "layouts/missions/ReviewMission";
import PickupMission from "layouts/missions/PickupMission";
import CodingMission from "layouts/missions/CodingMission";
import darkModeColors from "assets/theme-dark/base/colors";
import serviceEnums from "utils/services";

import { useApi, useApiFetch } from "utils/apiUtils";
import { EditingContextProvider } from "components_si/EditingContext";
import dayjs from "dayjs";

const { background, border } = darkModeColors;

function Missions() {
  const navigate = useNavigate();
  const { teamId: urlParamTeamId } = useParams();
  const { apiFetch: fetch } = useApiFetch();
  const { data: projectData, error: projectDataFetchError } = useApi({
    url: `/api/teams/${urlParamTeamId}`,
    defaultData: undefined,
  });

  const { data: missionData, refresh } = useApi({
    url: projectData === null ? undefined : `/api/teams/${projectData.team_id}/mission`,
    defaultData: null,
  });

  const { data: services } = useApi({
    url: projectData === null ? undefined : `/api/teams/${projectData.team_id}/integrations`,
    defaultData: null,
  });

  const onClickUpdateMission = async (missionName) => {
    await fetch(`/api/teams/${projectData.team_id}/mission`, {
      method: "POST",
      body: new URLSearchParams({
        mission_type: missionName,
        // defaults
        start_date: dayjs().startOf("day"),
        end_date: dayjs().add(14, "day").endOf("day"),
        target: missionName === "pickup" ? 43200 : 500, // should make an object for this
      }),
    });
    refresh();
  };

  // TODO: In the future, could remove components of missions if integrations are not available
  //   const { data: services } = useApi({
  //     url: projectData === null ? undefined : `/api/teams/${projectData.team_id}/integrations`,
  //     defaultData: null,
  //     dependencies: [projectData],
  //   });

  if (projectDataFetchError !== null) {
    // This automatically redirects to the first dashboard team
    navigate("/authentication/sign-in");
  }

  const { team_id: teamId, team_name: teamName, avatar_color: avatarColor } = projectData || {};
  // TODO: Add this check when also testing for integrations (projectData === null || services === null)
  if (projectData === null) {
    return <DashboardLayout>Loading...</DashboardLayout>;
  }

  if (Object.keys(projectData).length === 0) {
    return <DashboardLayout>We&apos;ll let you know when your data is ready!</DashboardLayout>;
  }

  if (missionData === null || services === null) {
    return <DashboardLayout>Loading...</DashboardLayout>;
  }

  let effectiveGitService;
  if (services.includes("github")) {
    effectiveGitService = serviceEnums.GitServiceEnum.Github;
  } else if (services.includes("gitlab")) {
    effectiveGitService = serviceEnums.GitServiceEnum.Gitlab;
  }

  if (missionData.mission_type === "velocity") {
    // TODO: EditingContextProvider not used yet for any editing, but will be later and
    // we reuse components that use the editing context so we need it for now.
    return (
      <EditingContextProvider>
        <VelocityMission
          teamId={teamId}
          service={effectiveGitService}
          teamName={teamName}
          avatarColor={avatarColor}
          configuration={{
            startDate: missionData.start_date,
            endDate: missionData.end_date,
            target: missionData.target,
          }}
        />
      </EditingContextProvider>
    );
  }
  if (missionData.mission_type === "review") {
    // TODO: EditingContextProvider not used yet for any editing, but will be later and
    // we reuse components that use the editing context so we need it for now.
    return (
      <EditingContextProvider>
        <ReviewMission
          teamId={teamId}
          service={effectiveGitService}
          teamName={teamName}
          avatarColor={avatarColor}
          configuration={{
            startDate: missionData.start_date,
            endDate: missionData.end_date,
            target: missionData.target,
          }}
        />
      </EditingContextProvider>
    );
  }
  if (missionData.mission_type === "pickup") {
    // TODO: EditingContextProvider not used yet for any editing, but will be later and
    // we reuse components that use the editing context so we need it for now.
    return (
      <EditingContextProvider>
        <PickupMission
          teamId={teamId}
          service={effectiveGitService}
          teamName={teamName}
          avatarColor={avatarColor}
          configuration={{
            startDate: missionData.start_date,
            endDate: missionData.end_date,
            target: missionData.target,
          }}
        />
      </EditingContextProvider>
    );
  }
  if (missionData.mission_type === "coding") {
    // TODO: EditingContextProvider not used yet for any editing, but will be later and
    // we reuse components that use the editing context so we need it for now.
    return (
      <EditingContextProvider>
        <CodingMission
          teamId={teamId}
          service={effectiveGitService}
          teamName={teamName}
          avatarColor={avatarColor}
          configuration={{
            startDate: missionData.start_date,
            endDate: missionData.end_date,
            target: missionData.target,
          }}
        />
      </EditingContextProvider>
    );
  }
  return (
    <DashboardLayout>
      <Box
        sx={{
          pt: 3.25,
          pb: 3.25,
          pl: 2,
          borderBottom: 0.5,
          borderLeft: 0.5,
          borderColor: border.light,
        }}
      >
        <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={2}>
          <TeamAvatarName teamName={teamName} avatarColor={avatarColor} />
          <MDTypography variant="h2">{teamName} &gt; Mission</MDTypography>
        </Stack>
      </Box>
      <Box
        sx={{
          py: 1,
          pl: 3,
          borderLeft: 0.5,
          borderColor: border.light,
          backgroundColor: background.subtitle,
        }}
      >
        <MDTypography color="info" variant="h3">
          Velocity
        </MDTypography>
      </Box>
      <Box
        sx={{
          p: 3,
          borderLeft: 0.5,
          borderColor: border.light,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box
              onClick={() => {
                onClickUpdateMission("velocity");
              }}
              sx={{
                py: 1,
                px: 3,
                borderColor: border.light,
                backgroundColor: background.subtitle,
                "&:hover": {
                  backgroundColor: background.hover,
                },
              }}
            >
              <MDTypography color="info">V001</MDTypography>
              <MDTypography>Ship shorter, more frequent PRs</MDTypography>
              <MDTypography>KPIs: # of PRs, PR size</MDTypography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Coming soon">
              <Box
                sx={{
                  py: 1,
                  px: 3,
                  borderColor: border.light,
                  backgroundColor: background.subtitle,
                }}
              >
                <MDTypography color="info">V002</MDTypography>
                <MDTypography>Unblock issues daily</MDTypography>
                <MDTypography>KPIs: # of issues completed, issue cycle time </MDTypography>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          py: 1,
          pl: 3,
          borderLeft: 0.5,
          borderColor: border.light,
          backgroundColor: background.subtitle,
        }}
      >
        <MDTypography color="info" variant="h3">
          Review Quality
        </MDTypography>
      </Box>
      <Box
        sx={{
          p: 3,
          borderLeft: 0.5,
          borderColor: border.light,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box
              sx={{
                py: 1,
                px: 3,
                borderColor: border.light,
                backgroundColor: background.subtitle,
                "&:hover": {
                  backgroundColor: background.hover,
                },
              }}
              onClick={() => {
                onClickUpdateMission("pickup");
              }}
            >
              <MDTypography color="info">Q001</MDTypography>
              <MDTypography>Review PRs sooner</MDTypography>
              <MDTypography>KPIs: pickup time</MDTypography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Coming soon">
              <Box
                sx={{
                  py: 1,
                  px: 3,
                  borderColor: border.light,
                  backgroundColor: background.subtitle,
                }}
              >
                <MDTypography color="info">Q002</MDTypography>
                <MDTypography>Reduce time to complete reviews</MDTypography>
                <MDTypography>KPIs: review time, PR size </MDTypography>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Coming soon">
              <Box
                sx={{
                  py: 1,
                  px: 3,
                  borderColor: border.light,
                  backgroundColor: background.subtitle,
                }}
              >
                <MDTypography color="info">Q003</MDTypography>
                <MDTypography>Complete more comprehensive reviews</MDTypography>
                <MDTypography>KPIs: review depth, PR size </MDTypography>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}
export default Missions;
