import PropTypes from "prop-types";
import benchmarkIconPaths from "assets/images/small-logos/benchmark-icon-paths";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import darkModeColors from "assets/theme-dark/base/colors";
import { TiersEnum } from "utils/tierUtils";

const { success, warning, error } = darkModeColors;

function BenchmarkIcon({ tier, tooltip }) {
  let benchmarkIcon = null;

  switch (tier) {
    case TiersEnum.STier: {
      benchmarkIcon = (
        <Tooltip title={tooltip.stier}>
          <SvgIcon fontSize="medium" viewBox="0 0 41 41">
            <path d={benchmarkIconPaths.trophy} fill={success.main} />
          </SvgIcon>
        </Tooltip>
      );
      break;
    }
    case TiersEnum.Average: {
      benchmarkIcon = (
        <Tooltip title={tooltip.average}>
          <SvgIcon fontSize="medium" viewBox="0 0 41 41">
            <path d={benchmarkIconPaths.alert} fill={warning.main} />
          </SvgIcon>
        </Tooltip>
      );
      break;
    }
    case TiersEnum.Poor: {
      benchmarkIcon = (
        <Tooltip title={tooltip.poor}>
          <SvgIcon fontSize="medium" viewBox="0 0 41 41">
            <path d={benchmarkIconPaths.fire} fill={error.main} />
          </SvgIcon>
        </Tooltip>
      );
      break;
    }
    case TiersEnum.None: {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      benchmarkIcon = <></>;
      // benchmarkIcon = (
      //   <SvgIcon fontSize="medium" viewBox="0 0 41 41">
      //     <path d={benchmarkIconPaths.alert} fill={icon.main} />
      //   </SvgIcon>
      // );
      break;
    }
    default:
      // eslint-disable-next-line no-console
      console.log("Not a valid tier for the cycle time benchmarks: ", tier);
  }
  return benchmarkIcon;
}

// Setting default values for the props
BenchmarkIcon.defaultProps = {
  tooltip: {
    stier: "Click on question mark for more details",
    average: "Click on question mark for more details",
    poor: "Click on question mark for more details",
  },
};

BenchmarkIcon.propTypes = {
  tier: PropTypes.oneOf(Object.values(TiersEnum)),
  // eslint-disable-next-line react/forbid-prop-types
  tooltip: PropTypes.any,
};

export default BenchmarkIcon;
