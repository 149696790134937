import PropTypes from "prop-types";
import dayjs from "dayjs";
import Highcharts from "highcharts";

import { useState, useEffect } from "react";
import { useApi } from "utils/apiUtils";
import { mdiJira } from "@mdi/js"; // https://materialdesignicons.com/
import {
  convertMillisecondsToReadableTimeAndUnits,
  convertMillisecondsToReadableTime,
} from "utils/timeUtils";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";
import MDTypography from "components/MDTypography";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";

import BenchmarkIcon from "components_si/BenchmarkIcon";
import {
  useEditingContext,
  EditingStates,
  getDraftCardSetting,
  editCardSetting,
  CardsEnum,
} from "components_si/EditingContext";
import BasicKpiCard from "./BasicKpiCard";
import IssueCycleTimeDataGrid from "../IssueCycleTimeDataGrid";
import SprintIssueCycleTimeDataGrid from "../SprintIssueCycleTimeDataGrid";
import SprintKpiCard from "./SprintKpiCard";

const DEFAULT_FILTERS = [
  {
    id: 1,
    columnField: "first_in_progress_time",
    operatorValue: "isNotEmpty",
  },
  {
    id: 2,
    columnField: "current_status_category",
    operatorValue: "equals",
    value: "DONE",
  },
];

const CARD_DESCRIPTION = {
  title: "Issue cycle time",
  shortDefinition: "Time from first moved to 'In Progress' to moved to 'Done'",
  longDefinition: (
    <>
      <MDTypography variant="body2" gutterBottom>
        The period from when an issue is initially started (moved to an &quot;In Progress&quot;
        status) until is is marked completed (moved to a &quot;Done&quot; status).
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        We compare you to industry benchmarks:
        <Box px={2}>
          {/* TODO: Declare benhmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>S-tier: </strong>&lt; 1 day
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Average: </strong>1-2 days
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Poor: </strong> &gt; 2 days
            </ListItem>
          </List>
        </Box>
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        Teams with a shorter cycle time can better respond to customer needs and tend to ship
        smaller, more robust changes.
      </MDTypography>
    </>
  ),
  benchmarkTooltip: {
    stier: "< 1 day",
    average: "1-2 days",
    poor: "> 2 days",
  },
  unit: "",
  unitSingular: "",
  highchartsTitle: "Average time spent in progress",
};

/**
 * @param {*} jsonBlob - A data object that must have date_point_start, cycle_time_seconds
 * @returns an object with an x- and a y-coordinate of length numPoints
 */

function processCardData(jsonBlob, bucket, numPoints) {
  if (jsonBlob === null) {
    return null;
  }

  let jsonBlobTruncated = jsonBlob;
  if (numPoints !== undefined && numPoints < jsonBlob.length) {
    jsonBlobTruncated = jsonBlob.slice(jsonBlob.length - numPoints, jsonBlob.length);
  }

  const cardDataX = jsonBlobTruncated.map((x) => dayjs(x.date_point_start).valueOf());
  const cardDataY = jsonBlobTruncated.map((y) => y.cycle_time_seconds * 1000);

  const cardData = [];
  for (let i = 0; i < cardDataX.length; i++) {
    cardData[i] = [cardDataX[i], cardDataY[i]];
  }

  return cardData;
}

function processAggregateData(aggregateData) {
  const val = aggregateData?.[0]?.cycle_time_seconds;

  if (val) {
    return parseFloat(val) * 1000;
  }

  return val;
}

function IssueCycleTimeDisplay({ data, unitDescription }) {
  let largeText = "--";
  let smallText = unitDescription.unit;
  if (data) {
    const readablePoint = convertMillisecondsToReadableTimeAndUnits(data);
    const small = [readablePoint[0].unit];
    for (let i = 1; i < readablePoint.length; i++) {
      small.push(`${readablePoint[i].time} ${readablePoint[i].unit}`);
    }

    largeText = readablePoint[0].time;
    smallText = small.join(", ");
  }

  return (
    <Stack direction="row" sx={{ alignItems: "baseline" }}>
      <MDTypography variant="h1">{largeText}</MDTypography>
      <MDTypography variant="h2" sx={{ fontWeight: "normal" }}>
        &nbsp;{smallText}
      </MDTypography>
    </Stack>
  );
}

IssueCycleTimeDisplay.defaultProps = {};

// TODO: can we make this the same as DefaultMetricDisplay?
IssueCycleTimeDisplay.propTypes = {
  data: PropTypes.number.isRequired,
  unitDescription: PropTypes.shape({
    unit: PropTypes.string,
    unitSingular: PropTypes.string,
  }).isRequired,
};

function FormatTooltip({ date, data, bucketSize, _unitDescription }) {
  const startDate = dayjs(date);
  let formattedDatapoint = "--";

  if (data || data === 0) {
    formattedDatapoint = convertMillisecondsToReadableTime(data);
  }

  switch (bucketSize) {
    case "week": {
      const endDate = startDate.add(6, "day");

      // Check if the months are different
      const includeMonth = startDate.month() !== endDate.month();

      // Format the end date depending on whether the months are different
      const endDateFormatted = includeMonth
        ? Highcharts.dateFormat("%b %e", endDate)
        : Highcharts.dateFormat("%e", endDate);

      return `<b>${Highcharts.dateFormat(
        "%b %e",
        startDate
      )} - ${endDateFormatted}</b></br>${formattedDatapoint}`;
    }
    case "month": {
      return `<b>${Highcharts.dateFormat("%B %Y", startDate)}</b></br> ${formattedDatapoint}`;
    }
    default: {
      return `<b>${Highcharts.dateFormat("%b %e", startDate)}</b></br> ${formattedDatapoint}`;
    }
  }
}

function KpiIssueCycleTimeCard({ teamId, dateRange, bucket: bucketProp, bucketDropdownOptions }) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  // TODO: should handle the null case as a loading state to prevent a flicker
  const [bucket, setBucket] = useState(null);
  useEffect(() => {
    setBucket(bucketProp);
  }, [bucketProp]);

  const getBucketWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving:
        return getDraftCardSetting(draft, CardsEnum.IssueCycleTime)?.bucket ?? bucket;
      case EditingStates.Normal:
        return bucket;
      default:
        console.log("Invalid editing states mode");
        return bucket;
    }
  };

  const setBucketWrapper = (newBucket) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        editCardSetting(dispatch, CardsEnum.IssueCycleTime, { bucket: newBucket });
        break;
      }
      case EditingStates.Normal: {
        setBucket(newBucket);
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setBucket(newBucket);
      }
    }
  };

  const startDate = encodeURIComponent(dateRange[0].toISOString());
  const endDate = encodeURIComponent(dateRange[1].toISOString());
  const { data } = useApi({
    url: `/api/teams/${teamId}/issues_cycle_avg?bucket=${getBucketWrapper()}&start=${startDate}&end=${endDate}`,
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/issues_cycle_avg?bucket=all&start=${startDate}&end=${endDate}`,
  });
  const processedData = processCardData(data, bucket);
  // State for the filter in the Data Popup
  const [filt, setFilt] = useState(DEFAULT_FILTERS);

  return getBucketWrapper() === "sprint" ? (
    <SprintKpiCard
      cardType={CardsEnum.IssueCycleTime}
      rawData={data}
      aggregateData={processAggregateData(aggregateData)}
      description={CARD_DESCRIPTION}
      onSelectBucket={(currBucket) => setBucketWrapper(currBucket)}
      setFilt={setFilt}
      onClosePopup={() => {
        setFilt(DEFAULT_FILTERS);
      }}
      popupContent={
        <SprintIssueCycleTimeDataGrid
          teamId={teamId}
          filtInput={filt}
          anchorColumn="date_week_start"
          sortField="first_in_progress_time"
        />
      }
      unit="cycle_time_seconds"
      bucketDropdownOptions={bucketDropdownOptions}
      metricsType={MetricsEnum.IssueCycleTime}
      dataSourceIcon={mdiJira}
      defaultFilters={DEFAULT_FILTERS}
      columnField="last_done_time"
      renderMetricDisplay={IssueCycleTimeDisplay}
    />
  ) : (
    <BasicKpiCard
      data={processedData}
      bucket={getBucketWrapper()}
      onSelectBucket={(currBucket) => setBucketWrapper(currBucket)}
      aggregateData={processAggregateData(aggregateData)}
      renderBenchmark={(dataPoint, numWeeks, _dataPointIdx) => {
        if (dataPoint) {
          return (
            <Box pt={1}>
              <BenchmarkIcon
                tier={evaluateTier(dataPoint, MetricsEnum.IssueCycleTime, numWeeks)}
                tooltip={CARD_DESCRIPTION.benchmarkTooltip}
              />
            </Box>
          );
        }
        // The height of the actually filled out benchmark -- otherwise this would be null
        // TODO: Probably better to just make the graph stick to the bottom of the cell instead
        return <Box height={35} />;
      }}
      formatTooltip={FormatTooltip}
      description={CARD_DESCRIPTION}
      teamId={teamId}
      dataSourceIcon={mdiJira}
      popupContent={
        <IssueCycleTimeDataGrid
          teamId={teamId}
          filtInput={filt}
          anchorColumn="date_week_start"
          sortField="first_in_progress_time"
        />
      }
      onClickDataPoint={(x) => {
        const filterStartDate = dayjs(x).format("YYYY-MM-DD");
        let filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");

        if (getBucketWrapper() === "week") {
          filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");
        } else if (getBucketWrapper() === "month") {
          filterEndDate = dayjs(x).add(1, "month").format("YYYY-MM-DD");
        } else if (getBucketWrapper() === null) {
          // TODO: handle loading state
        } else {
          console.log("unsupported bucket type");
        }

        setFilt([
          ...DEFAULT_FILTERS,
          {
            id: DEFAULT_FILTERS.length + 1,
            columnField: "first_in_progress_time",
            operatorValue: "onOrAfter",
            value: filterStartDate,
          },
          {
            id: DEFAULT_FILTERS.length + 2,
            columnField: "first_in_progress_time",
            operatorValue: "before",
            value: filterEndDate,
          },
        ]);
      }}
      onClosePopup={() => {
        setFilt(DEFAULT_FILTERS);
      }}
      renderMetricDisplay={IssueCycleTimeDisplay}
      bucketDropdownOptions={bucketDropdownOptions}
    />
  );
}

KpiIssueCycleTimeCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
  bucketDropdownOptions: [
    { label: "weekly", value: "week" },
    { label: "monthly", value: "month" },
    { label: "sprintly", value: "sprint" },
  ],
};

KpiIssueCycleTimeCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
  bucketDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default KpiIssueCycleTimeCard;
