import dayjs from "dayjs";

/* eslint-disable react/no-this-in-sfc */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// @mui material components
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";

// Other material components
import MDTypography from "components/MDTypography";
import InfoButton from "layouts/projects/components/InfoButton";
import ChipDropdown from "layouts/projects/components/ChipDropdown";
import {
  SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVERING,
  NO_DATA_POINTS_SENTINEL,
  AGGREGATE_DATA_POINT_SENTINEL,
  shouldShowAggregate,
} from "utils/settingsUtils";
import DataPopup from "components_si/DataPopup";
import { useEditingContext, EditingStates } from "components_si/EditingContext";
import {
  chartOptionsDarkMode,
  chartOptionsDarkModeOnHover,
  chartOptionsScatterDarkMode,
  chartOptionsScatterDarkModeOnHover,
} from "../../utils/optionsHighCharts";

import GraphTypeEnum from "../../utils/GraphTypeEnum";

function DefaultMetricDisplay({ data, unitDescription }) {
  let formattedDatapoint = 0;
  let formattedUnit = unitDescription.unit;
  if (data) {
    formattedDatapoint = Math.round(data).toLocaleString("en-US");
    if (data === 1) {
      formattedUnit = unitDescription.unitSingular;
    }
  }

  return (
    <Stack direction="row" sx={{ alignItems: "baseline" }}>
      <MDTypography variant="h1">{formattedDatapoint}</MDTypography>
      <MDTypography variant="h2" sx={{ fontWeight: "normal" }}>
        &nbsp;{formattedUnit}
      </MDTypography>
    </Stack>
  );
}

DefaultMetricDisplay.defaultProps = {};

// TODO: These propTypes do not actually seem binding? If I change the below, I don't get a console warning
// Typechecking props
DefaultMetricDisplay.propTypes = {
  data: PropTypes.number.isRequired,
  unitDescription: PropTypes.shape({
    unit: PropTypes.string,
    unitSingular: PropTypes.string,
  }).isRequired,
};

function DefaultFormatTooltip({ date, data, bucketSize, unitDescription }) {
  const startDate = dayjs(date);
  let formattedDatapoint = 0;
  let formattedUnit = unitDescription.unit;
  if (data) {
    formattedDatapoint = Math.round(data).toLocaleString("en-US");
    if (data === 1) {
      formattedUnit = unitDescription.unitSingular;
    }
  }
  switch (bucketSize) {
    case "week": {
      const endDate = startDate.add(6, "day");

      // Check if the months are different
      const includeMonth = startDate.month() !== endDate.month();

      // Format the end date depending on whether the months are different
      const endDateFormatted = includeMonth
        ? Highcharts.dateFormat("%b %e", endDate)
        : Highcharts.dateFormat("%e", endDate);

      return `<b>${Highcharts.dateFormat(
        "%b %e",
        startDate
      )} - ${endDateFormatted}</b></br>${formattedDatapoint} ${formattedUnit}`;
    }
    case "month": {
      return `<b>${Highcharts.dateFormat(
        "%B %Y",
        startDate
      )}</b></br> ${formattedDatapoint} ${formattedUnit}`;
    }
    default: {
      return `<b>${Highcharts.dateFormat("%b %e", startDate)}</b></br> ${formattedDatapoint}`;
    }
  }
}

function DefaultFormatTooltipScatter({ title, _date, data, unitDescription }) {
  let formattedDatapoint = 0;
  let formattedUnit = unitDescription.unit;
  if (data) {
    formattedDatapoint = Math.round(data).toLocaleString("en-US");
    if (data === 1) {
      formattedUnit = unitDescription.unitSingular;
    }
  }
  // TODO: Is there anything else we want to include in this tooltip?
  return `<b>${title}</b></br>${formattedDatapoint} ${formattedUnit}`;
}

function BasicKpiCard({
  data,
  scatterData,
  bucket,
  graph,
  onSelectBucket,
  onSelectGraph,
  aggregateData,
  description,
  dataSourceIcon,
  onClickDataPoint,
  onClickDataPointScatter,
  onClosePopup,
  renderMetricDisplay,
  renderBenchmark,
  popupContent,
  formatTooltip,
  formatTooltipScatter,
  bucketDropdownOptions,
  graphDropdownOptions,
  // TODO: these three are for issue/points but can be used for similar toggles - maybe eventually we just pass in an array of each?
  onSelectDataType,
  dataDropdownOptions,
  dataType,
  targetLine,
  targetScatter,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const [isDataPopupOpen, setIsDataPopupOpen] = useState(false);
  const [dataPointIdx, setDataPointIdx] = useState(NO_DATA_POINTS_SENTINEL);
  const [draft] = useEditingContext();
  const { mode } = draft;

  let dataPointY = null;
  if (dataPointIdx !== NO_DATA_POINTS_SENTINEL) {
    if (dataPointIdx === AGGREGATE_DATA_POINT_SENTINEL) {
      dataPointY = aggregateData;
    } else if (data.length > 0) {
      const [, y] = data[dataPointIdx];
      dataPointY = y;
    }
  }

  let numWeeksPerDataPoint = null;
  if (bucket === "week") {
    numWeeksPerDataPoint = 1;
  } else if (bucket === "month") {
    // TODO: We could calculate this more precisely. Used to scale the benchmarks
    numWeeksPerDataPoint = 4;
  } else if (bucket === null) {
    // TODO: handle loading state
  } else {
    console.log("unsupported bucket size - can only be weekly, monthly, or sprintly");
    numWeeksPerDataPoint = 1;
  }

  const resetToNotHoveringDataPointState = () => {
    if (
      aggregateData === null ||
      data === null ||
      data.length === 0 ||
      // Technically this should be !== 0 if data.length is !== 0 but just adding
      // this defensively
      aggregateData.length === 0
    ) {
      setDataPointIdx(NO_DATA_POINTS_SENTINEL);
    } else if (SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVERING) {
      setDataPointIdx(AGGREGATE_DATA_POINT_SENTINEL);
    } else {
      setDataPointIdx(data.length - 1);
    }
  };

  useEffect(resetToNotHoveringDataPointState, [data, aggregateData]);

  const handleClickCard = () => {
    setIsDataPopupOpen(true);
  };

  const handleClickDataPoint = (x, index) => {
    if (onClickDataPoint !== undefined) {
      onClickDataPoint(x, index);
    }
    setIsDataPopupOpen(true);
  };

  const handleClickDataPointScatter = (x) => {
    if (onClickDataPointScatter !== undefined) {
      onClickDataPointScatter(x);
    }
    setIsDataPopupOpen(true);
  };

  const handleMouseOverDataPoint = (idx, _y) => {
    setDataPointIdx(idx);
  };

  const handleMouseOutDataPoint = () => {
    resetToNotHoveringDataPointState();
  };

  const handleCloseDataPopup = () => {
    if (onClosePopup !== undefined) {
      onClosePopup();
    }
    setIsDataPopupOpen(false);
  };

  // Reflow the charts on hover, to make sure that it is the right size (e.g., if the window was resized since last hover, or if the sidebar size was changed)
  // TODO: If performance becomes an issue, could try to add a ref to the relevant charts and only reflow these
  useEffect(() => {
    for (let i = 0; i < Highcharts.charts.length; i++) {
      if (Highcharts.charts[i] !== undefined) {
        Highcharts.charts[i].reflow();
      }
    }
  }, [isHovering]);

  return (
    <>
      {/* The data popup. It is outside of CardAction not to trigger the onClick event */}
      <DataPopup
        title={description.title}
        definition={description.shortDefinition}
        icon={dataSourceIcon}
        isOpen={isDataPopupOpen}
        handleClose={handleCloseDataPopup}
      >
        {popupContent}
      </DataPopup>
      <Card
        onPointerLeave={() => setIsHovering(false)}
        onPointerOver={() => setIsHovering(true)}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Chips (e.g., weekly/monthly/sprintly) and question mark. On top of the card and height of 0px to make the whole card clickable for CardActionArea */}
        <Box
          sx={{
            mt: 0.5,
            mb: 2,
            ml: 2,
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              height: "0px",
              justifyContent: "right",
              alignItems: "baseline",
              display: "absolute",
              visibility: isHovering || mode === EditingStates.Editing ? "block" : "hidden",
            }}
          >
            {bucketDropdownOptions && bucketDropdownOptions.length > 0 && (
              <ChipDropdown
                items={bucketDropdownOptions}
                selectedItem={bucket}
                onChange={onSelectBucket}
                tooltipText={(item) => `Aggregates one ${item} of data per point.`}
              />
            )}
            {dataDropdownOptions && dataDropdownOptions.length > 0 && (
              <ChipDropdown
                items={dataDropdownOptions}
                selectedItem={`${dataType}`}
                onChange={onSelectDataType}
              />
            )}
            {graphDropdownOptions && graphDropdownOptions.length > 0 && (
              <ChipDropdown
                items={graphDropdownOptions}
                selectedItem={graph}
                onChange={onSelectGraph}
              />
            )}
          </Box>
          <Box
            sx={{
              height: "0px",
              justifyContent: "right",
              alignItems: "baseline",
              display: "flex",
              visibility: isHovering ? "block" : "hidden",
            }}
          >
            <InfoButton
              title={description.title}
              size="small"
              icon={dataSourceIcon}
              onClose={() => {
                setIsHovering(false);
              }}
              message={
                <MDTypography variant="body2" gutterBottom>
                  {description.longDefinition}
                </MDTypography>
              }
            />
          </Box>
        </Box>

        <CardActionArea onClick={handleClickCard}>
          <Box>
            {/* Title and metric of the card */}
            <Box pl={3} pt={1.5}>
              {/* <Stack direction="row" sx={{ alignItems: "baseline" }}> */}
              <Stack direction="row">
                <MDTypography color="info" variant="h2">
                  {description.title}&nbsp;
                </MDTypography>
              </Stack>
              {renderMetricDisplay({
                data: shouldShowAggregate(dataPointIdx) ? aggregateData : dataPointY,
                unitDescription: description,
                dataPointIdx,
              })}
              {renderBenchmark &&
                renderBenchmark(
                  shouldShowAggregate(dataPointIdx) ? aggregateData : dataPointY,
                  shouldShowAggregate(dataPointIdx)
                    ? data.length * numWeeksPerDataPoint
                    : numWeeksPerDataPoint,
                  dataPointIdx
                )}
            </Box>
            {/*
              Note: Need to add HighCharts in both light and dark modes and
              show / hide them when appropriate (as opposed to swapping the
              highchart options) because on toggling back and forth between
              dark and light, some options from the other mode are retained in
              the new mode.

              Some suspicions about why this is happening:
              - the chart ids are generated by HighCharts and are colliding
              - HighchartsReact is using a React `ref` under the hood which
                is not being updated by highcharts. We have to trigger an
                update to it somehow!
              */}

            <Box sx={{ display: graph === GraphTypeEnum.Line ? "block" : " none" }}>
              <Box sx={{ display: isHovering ? "none" : " block", pb: "20px" }}>
                <Box>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptionsDarkMode(
                      description.highchartsTitle,
                      data,
                      handleClickDataPoint,
                      handleMouseOverDataPoint,
                      handleMouseOutDataPoint,
                      targetLine
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ display: isHovering ? "block" : " none" }}>
                <Box>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptionsDarkModeOnHover(
                      description.highchartsTitle,
                      data,
                      handleClickDataPoint,
                      handleMouseOverDataPoint,
                      handleMouseOutDataPoint,
                      bucket,
                      description,
                      formatTooltip,
                      targetLine
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: graph === GraphTypeEnum.Scatter ? "block" : " none" }}>
              <Box sx={{ display: isHovering ? "none" : " block", pb: "20px" }}>
                <Box>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptionsScatterDarkMode(
                      description.highchartsTitle,
                      scatterData,
                      handleClickDataPointScatter,
                      handleMouseOverDataPoint,
                      handleMouseOutDataPoint,
                      targetScatter
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ display: isHovering ? "block" : " none" }}>
                <Box>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptionsScatterDarkModeOnHover(
                      description.highchartsTitle,
                      scatterData,
                      handleClickDataPointScatter,
                      handleMouseOverDataPoint,
                      handleMouseOutDataPoint,
                      description,
                      formatTooltipScatter,
                      targetScatter
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </>
  );
}

BasicKpiCard.defaultProps = {
  data: null,
  scatterData: null,
  bucket: "week",
  graph: GraphTypeEnum.Line,
  onSelectBucket: undefined,
  onSelectGraph: undefined,
  aggregateData: null,
  onClickDataPoint: undefined,
  onClickDataPointScatter: undefined,
  onClosePopup: undefined,
  renderMetricDisplay: DefaultMetricDisplay,
  renderBenchmark: undefined,
  formatTooltip: DefaultFormatTooltip,
  formatTooltipScatter: DefaultFormatTooltipScatter,
  bucketDropdownOptions: [
    { label: "weekly", value: "week" },
    { label: "monthly", value: "month" },
  ],
  graphDropdownOptions: [{ label: "line", value: GraphTypeEnum.Line }],
  onSelectDataType: undefined,
  dataDropdownOptions: [],
  dataType: null,
  targetLine: null,
  targetScatter: null,
};

BasicKpiCard.propTypes = {
  // an array of [x, y]
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  // eslint-disable-next-line react/forbid-prop-types
  scatterData: PropTypes.any,
  bucket: PropTypes.string,
  graph: PropTypes.oneOf(Object.values(GraphTypeEnum)),
  onSelectBucket: PropTypes.func,
  onSelectGraph: PropTypes.func,
  aggregateData: PropTypes.number,
  description: PropTypes.shape({
    title: PropTypes.string,
    shortDefinition: PropTypes.string,
    longDefinition: PropTypes.node,
    unit: PropTypes.string,
    unitSingular: PropTypes.string,
    highchartsTitle: PropTypes.string,
  }).isRequired,
  dataSourceIcon: PropTypes.string.isRequired,
  popupContent: PropTypes.node.isRequired,
  onClickDataPoint: PropTypes.func,
  onClickDataPointScatter: PropTypes.func,
  onClosePopup: PropTypes.func,
  renderMetricDisplay: PropTypes.func,
  renderBenchmark: PropTypes.func,
  formatTooltip: PropTypes.func,
  formatTooltipScatter: PropTypes.func,
  bucketDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  graphDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOf(Object.values(GraphTypeEnum)),
    })
  ),
  onSelectDataType: PropTypes.func,
  dataDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  dataType: PropTypes.any,
  targetLine: PropTypes.number,
  targetScatter: PropTypes.number,
};

export default BasicKpiCard;
