import PropTypes from "prop-types";

import { useCallback } from "react";

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

import Stack from "@mui/material/Stack";

import darkModeColors from "assets/theme-dark/base/colors";

const { background, icon } = darkModeColors;

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton sx={{ px: 0, color: icon.main, cursor: "default" }} />
      <GridToolbarExport sx={{ color: icon.main, cursor: "default" }} />
    </GridToolbarContainer>
  );
}

export default function SIDataGrid({ showExportAndFilter, noRowsText, ...rest }) {
  const components = {};

  if (showExportAndFilter) {
    components.Toolbar = CustomToolbar;
  }

  if (noRowsText) {
    components.NoRowsOverlay = useCallback(() => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {noRowsText}
      </Stack>
    ));
  }

  return (
    <DataGridPro
      disableSelectionOnClick
      components={components}
      componentsProps={{
        columnMenu: {
          sx: {
            backgroundColor: background.hover,
          },
        },
        columnsPanel: {
          sx: {
            backgroundColor: background.hover,
          },
        },
        filterPanel: {
          sx: {
            backgroundColor: background.hover,
          },
        },
      }}
      {...rest}
    />
  );
}

SIDataGrid.defaultProps = {
  noRowsText: "No rows",
  showExportAndFilter: false,
};

SIDataGrid.propTypes = {
  noRowsText: PropTypes.string,
  showExportAndFilter: PropTypes.bool,
};
