import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";

import MDTypography from "components/MDTypography";
import InfoButton from "layouts/projects/components/InfoButton";
import ChipDropdown from "layouts/projects/components/ChipDropdown";
import {
  SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVERING,
  NO_DATA_POINTS_SENTINEL,
  AGGREGATE_DATA_POINT_SENTINEL,
  shouldShowAggregate,
} from "utils/settingsUtils";
import DataPopup from "components_si/DataPopup";
import BenchmarkIcon from "components_si/BenchmarkIcon";
import { useEditingContext, EditingStates } from "components_si/EditingContext";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";
import { useMaterialUIController } from "context";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";
import {
  chartOptionsBarDarkMode,
  chartOptionsBarDarkModeOnHover,
} from "../../utils/optionsHighCharts";

function getData(jsonBlob, unit, numPoints) {
  // unit is count, points, cycle_time_seconds
  if (jsonBlob === null) {
    return [null, null];
  }
  let jsonBlobTruncated = jsonBlob;

  if (numPoints !== undefined && numPoints > jsonBlob.length) {
    jsonBlobTruncated = jsonBlob.slice(jsonBlob.length - numPoints, jsonBlob.length);
  }

  const jsonBlobProcessed = jsonBlobTruncated.map((x) => ({
    start_date: dayjs(x.start_date).valueOf(),
    end_date: dayjs(x.end_date).valueOf(),
    name: x.name,
    unit,
    data: x[unit] * (unit === "cycle_time_seconds" ? 1000 : 1),
  }));
  const processedData = jsonBlobProcessed.reduce(
    (obj, item) => ({ ...obj, [item.name]: item }),
    {}
  );
  const cardData = jsonBlobProcessed.map((x) => [x.name, x.data]);

  return [processedData, cardData];
}

const defaultRenderMetricDisplay = ({ data, unitDescription }) => {
  let formattedDatapoint = data;
  let formattedUnit = unitDescription.unit;
  formattedDatapoint = Math.round(data).toLocaleString("en-US");
  formattedUnit = formattedDatapoint === 1 ? unitDescription.unitSingular : unitDescription.unit;
  formattedUnit = unitDescription.titleUnit ?? unitDescription.unit;

  return (
    <Stack direction="row" sx={{ alignItems: "baseline" }}>
      <MDTypography variant="h1">{formattedDatapoint}</MDTypography>
      <MDTypography variant="h2" sx={{ fontWeight: "normal" }}>
        {formattedUnit.startsWith("%") ? "" : "\xa0"}
        {formattedUnit}
      </MDTypography>
    </Stack>
  );
};

function SprintKpiCard({
  rawData,
  aggregateData,
  description,
  onSelectBucket,
  setFilt,
  onClosePopup,
  popupContent,
  unit,
  bucketDropdownOptions,
  metricsType,
  dataSourceIcon,
  defaultFilters,
  // columnField,
  renderMetricDisplay,
  onSelectDataType,
  dataDropdownOptions,
  dataType,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const [isDataPopupOpen, setIsDataPopupOpen] = useState(false);
  const [dataPointIdx, setDataPointIdx] = useState(NO_DATA_POINTS_SENTINEL);
  const [draft] = useEditingContext();
  const { mode } = draft;
  const numWeeksPerDataPoint = 1;
  const [processedData, cardData] = getData(rawData, unit);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const isPercentageGraph = description.unit.startsWith("%");

  let dataPointY = null;
  if (dataPointIdx !== NO_DATA_POINTS_SENTINEL) {
    if (dataPointIdx === AGGREGATE_DATA_POINT_SENTINEL) {
      dataPointY = aggregateData;
    } else if (cardData && cardData.length > 0) {
      const [, y] = cardData[dataPointIdx];
      dataPointY = y;
    }
  }

  const resetToNotHoveringDataPointState = () => {
    if (
      aggregateData === null ||
      rawData === null ||
      rawData.length === 0 ||
      aggregateData.length === 0
    ) {
      setDataPointIdx(NO_DATA_POINTS_SENTINEL);
    } else if (SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVERING) {
      setDataPointIdx(AGGREGATE_DATA_POINT_SENTINEL);
    } else {
      setDataPointIdx(cardData.length - 1);
    }
  };

  useEffect(resetToNotHoveringDataPointState, [rawData, aggregateData]);

  const handleClickCard = () => {
    setIsDataPopupOpen(true);
  };

  const handleClickDataPoint = (x) => {
    const date = processedData[cardData[x][0]];
    // const filterStartDate = dayjs(date.start_date).format("YYYY-MM-DD");
    // const filterEndDate = dayjs(date.end_date).format("YYYY-MM-DD");
    setFilt([
      ...defaultFilters,
      // {
      //   id: defaultFilters.length + 1,
      //   columnField,
      //   operatorValue: "onOrAfter",
      //   value: filterStartDate,
      // },
      // {
      //   id: defaultFilters.length + 2,
      //   columnField,
      //   operatorValue: "before",
      //   value: filterEndDate,
      // },
      {
        id: defaultFilters.length + 1,
        columnField: "sprint_name",
        operatorValue: "equals",
        value: date.name,
      },
    ]);
    setIsDataPopupOpen(true);
  };

  const handleMouseOverDataPoint = (idx, _y) => {
    setDataPointIdx(idx);
  };

  const handleMouseOutDataPoint = () => {
    resetToNotHoveringDataPointState();
  };

  const renderBenchmark = (dataPoint, numWeeks) => (
    <Box pt={1}>
      <BenchmarkIcon
        tier={evaluateTier(dataPoint, metricsType, numWeeks)}
        tooltip={description.benchmarkTooltip}
      />
    </Box>
  );

  const handleCloseDataPopup = () => {
    if (onClosePopup !== undefined) {
      onClosePopup();
    }
    setIsDataPopupOpen(false);
  };

  function SprintFormatTooltip({ date, unitDescription }) {
    const item = processedData[date];
    let formattedUnit = item.unit === "cycle_time_seconds" ? "--" : "";
    const formattedDatapoint =
      item.unit === "cycle_time_seconds"
        ? convertMillisecondsToReadableTime(item.data)
        : Math.round(item.data).toLocaleString("en-US");
    formattedUnit = item.data === 1 ? unitDescription.unitSingular : unitDescription.unit;

    return `<b>${Highcharts.dateFormat("%b %e", item.start_date)} - ${Highcharts.dateFormat(
      "%b %e",
      item.end_date
    )}</b></br>${formattedDatapoint}${formattedUnit.startsWith("%") ? "" : " "}${formattedUnit}`;
  }

  useEffect(() => {
    for (let i = 0; i < Highcharts.charts.length; i++) {
      if (Highcharts.charts[i] !== undefined) {
        Highcharts.charts[i].reflow();
      }
    }
  }, [isHovering]);

  return (
    <>
      <DataPopup
        title={description.title}
        definition={description.shortDefinition}
        icon={dataSourceIcon}
        isOpen={isDataPopupOpen}
        handleClose={handleCloseDataPopup}
      >
        {popupContent}
      </DataPopup>
      <Card
        onPointerLeave={() => setIsHovering(false)}
        onPointerOver={() => setIsHovering(true)}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            mb: 2,
            ml: 2,
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              height: "0px",
              justifyContent: "right",
              alignItems: "baseline",
              display: "absolute",
              visibility: isHovering || mode === EditingStates.Editing ? "block" : "hidden",
            }}
          >
            {bucketDropdownOptions && bucketDropdownOptions.length > 0 && (
              <ChipDropdown
                items={bucketDropdownOptions}
                selectedItem="sprint"
                onChange={onSelectBucket}
                tooltipText={(item) => `Aggregates one ${item} of data per point.`}
              />
            )}
            {dataDropdownOptions && dataDropdownOptions.length > 0 && (
              <ChipDropdown
                items={dataDropdownOptions}
                selectedItem={`${dataType}`}
                onChange={onSelectDataType}
              />
            )}
          </Box>
          <Box
            sx={{
              height: "0px",
              justifyContent: "right",
              alignItems: "baseline",
              display: "flex",
              visibility: isHovering ? "block" : "hidden",
            }}
          >
            <InfoButton
              title={description.title}
              size="small"
              icon={dataSourceIcon}
              onClose={() => setIsHovering(false)}
              message={
                <MDTypography variant="body2" gutterBottom>
                  {description.longDefinition}
                </MDTypography>
              }
            />
          </Box>
        </Box>
        <CardActionArea onClick={handleClickCard}>
          <Box>
            <Box pl={3} pt={1.5}>
              <Stack direction="row">
                <MDTypography color="info" variant="h2">
                  {description.title}&nbsp;
                </MDTypography>
              </Stack>
              {renderMetricDisplay({
                data: dataPointY,
                unitDescription: description,
                dataPointIdx,
              })}
              {renderBenchmark &&
                renderBenchmark(
                  shouldShowAggregate(dataPointIdx) ? aggregateData : dataPointY,
                  shouldShowAggregate(dataPointIdx) ? rawData.length : numWeeksPerDataPoint
                )}
            </Box>
            <Box sx={{ display: darkMode ? "block" : "none" }}>
              <Box sx={{ display: isHovering ? "none" : "block", pb: "20px" }}>
                <Box>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptionsBarDarkMode(
                      description.highchartsTitle,
                      processedData,
                      handleClickDataPoint,
                      handleMouseOverDataPoint,
                      handleMouseOutDataPoint,
                      isPercentageGraph
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ display: isHovering ? "block" : "none" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptionsBarDarkModeOnHover(
                    description.highchartsTitle,
                    processedData,
                    handleClickDataPoint,
                    handleMouseOverDataPoint,
                    handleMouseOutDataPoint,
                    description,
                    SprintFormatTooltip,
                    isPercentageGraph
                  )} // same as above for hover
                />
              </Box>
            </Box>
          </Box>
          {/* not adding a light mode unless i need to do so */}
        </CardActionArea>
      </Card>
    </>
  );
}

SprintKpiCard.defaultProps = {
  rawData: null,
  aggregateData: null,
  onSelectBucket: undefined,
  onClosePopup: undefined,
  unit: "count",
  bucketDropdownOptions: [
    { label: "weekly", value: "week" },
    { label: "monthly", value: "month" },
    { label: "sprintly", value: "sprint" },
  ],
  renderMetricDisplay: defaultRenderMetricDisplay,
  onSelectDataType: undefined,
  dataDropdownOptions: [],
  dataType: null,
};

SprintKpiCard.propTypes = {
  /* the shape of each item (object) in the raw data is as follows:
  {
    name: string,
    start_date: datetime
    end_date: datetime
    unit (count, points, cycle_time_seconds): number
  }
  */
  // eslint-disable-next-line react/forbid-prop-types
  rawData: PropTypes.arrayOf(PropTypes.object),
  aggregateData: PropTypes.number,
  description: PropTypes.shape({
    title: PropTypes.string,
    shortDefinition: PropTypes.string,
    longDefinition: PropTypes.node,
    unit: PropTypes.string,
    unitSingular: PropTypes.string,
    highchartsTitle: PropTypes.string,
    benchmarkTooltip: PropTypes.shape({
      stier: PropTypes.string,
      average: PropTypes.string,
      poor: PropTypes.string,
    }),
  }).isRequired,
  setFilt: PropTypes.func.isRequired,
  defaultFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      columnField: PropTypes.string,
      operatorValue: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  onSelectBucket: PropTypes.func,
  onClosePopup: PropTypes.func,
  popupContent: PropTypes.node.isRequired,
  unit: PropTypes.string,
  bucketDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  metricsType: PropTypes.oneOf(Object.values(MetricsEnum)).isRequired,
  dataSourceIcon: PropTypes.string.isRequired,
  // columnField: PropTypes.string.isRequired,
  renderMetricDisplay: PropTypes.func,
  onSelectDataType: PropTypes.func,
  dataDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  dataType: PropTypes.any,
};

export default SprintKpiCard;
