import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useParams } from "react-router-dom";

import MissionSettings from "layouts/settings/components/MissionSettings";
// import BenchmarkSettings from "layouts/settings/components/BenchmarkSettings";
import RiskSettings from "layouts/settings/components/RiskSettings";

function Settings() {
  const { teamId, option: settingsOption } = useParams();

  // should team data be loaded here or in the various setting type components?

  let renderedSettings;
  if (teamId !== null) {
    switch (settingsOption) {
      // case "benchmarks":
      //   renderedSettings = <BenchmarkSettings teamId={teamId} />;
      //   break;
      case "missions":
        renderedSettings = <MissionSettings teamId={teamId} />;
        break;
      case "risks":
      default:
        // TODO: add a default page once we have enough settings to justify one
        renderedSettings = <RiskSettings teamId={teamId} />;
    }
  } else {
    renderedSettings = <div>Loading...</div>;
  }

  return <DashboardLayout>{renderedSettings}</DashboardLayout>;
}

export default Settings;
