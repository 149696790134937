import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Box } from "@mui/material";
import MDTypography from "components/MDTypography";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { useApi, useApiFetch } from "utils/apiUtils";
import dayjs from "dayjs";
import BasicSettingsLayout from "layouts/settings/components/BasicSettings";

const missionTypeMap = {
  coding: {
    label: "Coding Time",
    displayTargetUnit: "hours",
    convertDisplayFormatToServerFormat: (displayTarget) => displayTarget * 3600,
    convertServerformatToDisplayFormat: (serverTarget) => serverTarget / 3600,
  },
  pickup: {
    label: "Pickup Time",
    displayTargetUnit: "hours",
    convertDisplayFormatToServerFormat: (displayTarget) => displayTarget * 3600,
    convertServerformatToDisplayFormat: (serverTarget) => serverTarget / 3600,
  },
  velocity: {
    label: "PR Size",
    displayTargetUnit: "lines",
    convertDisplayFormatToServerFormat: (displayTarget) => displayTarget,
    convertServerformatToDisplayFormat: (serverTarget) => serverTarget,
  },
};

function MissionRow({ missionType, dateRange, handleChangeDateRange, target, handleChangeTarget }) {
  return (
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item xs={4}>
        <h4>{missionTypeMap[missionType].label}</h4>
      </Grid>
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            localeText={{ start: "start", end: "end" }}
            format="MMM D, YYYY"
            sx={{ width: "290px" }}
            value={dateRange}
            onChange={(newValue) => {
              handleChangeDateRange(newValue);
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="target"
          label={missionTypeMap[missionType].displayTargetUnit}
          placeholder={`${target}`}
          value={target}
          type="number"
          onChange={(e) => handleChangeTarget(Number(e.target.value))}
        />
      </Grid>
    </Grid>
  );
}

MissionRow.propTypes = {
  missionType: PropTypes.string.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)).isRequired,
  handleChangeDateRange: PropTypes.func.isRequired,
  target: PropTypes.number.isRequired,
  handleChangeTarget: PropTypes.func.isRequired,
};

function MissionSettings({ teamId }) {
  const { apiFetch: fetch } = useApiFetch();
  const [missionType, setMissionType] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [target, setTarget] = useState(null);
  const [saved, setSaved] = useState(false);

  const { data, refresh } = useApi({
    url: `/api/teams/${teamId}/mission`,
  });

  useEffect(() => {
    if (data) {
      setMissionType(data.mission_type);
      setDateRange([
        data.start_date ? dayjs(data.start_date) : dayjs().startOf("day"),
        data.end_date ? dayjs(data.end_date) : dayjs().add(14, "day").endOf("day"),
      ]);
      setTarget(
        // this default value doesn't really matter too much because it wouldn't be displayed
        data.mission_type
          ? missionTypeMap[data.mission_type].convertServerformatToDisplayFormat(data.target)
          : 0
      );
    }
  }, [data]);

  const handleSave = async () => {
    await fetch(`/api/teams/${teamId}/mission`, {
      method: "POST",
      body: new URLSearchParams({
        mission_type: missionType,
        start_date: dateRange[0],
        end_date: dateRange[1],
        target: missionTypeMap[missionType].convertDisplayFormatToServerFormat(target),
      }),
    });
    refresh();
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 2000); // display "Saved!" for a few seconds after hitting the save button
  };

  if (data == null) {
    return <div>Loading...</div>;
  }

  return (
    <Box mx={15}>
      <BasicSettingsLayout
        teamId={teamId}
        title="Mission"
        description="Set the timeframe and target of your mission"
        headers={[
          <Grid item xs={4} key={0}>
            <MDTypography variant="h3">Mission</MDTypography>
          </Grid>,
          <Grid item xs={6} key={1}>
            <MDTypography variant="h3">Duration</MDTypography>
          </Grid>,
          <Grid item xs key={2}>
            <MDTypography variant="h3">Target</MDTypography>
          </Grid>,
        ]}
        configurables={
          missionType !== null && missionType !== ""
            ? [
                <MissionRow
                  missionType={missionType}
                  dateRange={dateRange}
                  handleChangeDateRange={setDateRange}
                  target={target}
                  handleChangeTarget={setTarget}
                  key={0}
                />,
              ]
            : []
        }
        handleSave={handleSave}
        saved={saved}
        defaultRender={
          <Grid item xs>
            <MDTypography variant="h3">
              Your team currently does not have an active mission.
            </MDTypography>
            Contact info@setori.ai to get set up!
          </Grid>
        }
      />
    </Box>
  );
}

MissionSettings.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default MissionSettings;
