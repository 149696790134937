import PropTypes from "prop-types";
// import dayjs from "dayjs";
import { useState } from "react";

// Utilities
import { useApi } from "utils/apiUtils";

// Setori Components
import DataPopup from "components_si/DataPopup";
import CycleTimeDataGrid from "layouts/projects/components/CycleTimeDataGrid";
import { mdiGithub, mdiGitlab } from "@mdi/js";
import AuthorAvatarName from "components_si/AuthorAvatarName";
import SIDataGridPro from "components_si/SIDataGridPro";
import services from "utils/services";

const description = {
  title: "PRs opened",
  shortDefinition: "Number of pull requests (PRs) opened",
};

export default function MissionDataGrid({ teamId, service }) {
  // TODO: This mission is only used by ArtistConnect it is starting on.
  // Search for ARTIST_CONNECT_CHANGE to find where else it is hardcoded.
  const currentDate = "2023-05-15";
  const endDate = "2023-06-09";

  const { data: missionData, loading } = useApi({
    url: `/api/teams/${teamId}/velocity_mission_one?startDate=${currentDate}&endDate=${endDate}&service=${service}`,
    defaultData: [],
  });

  const columns = [
    {
      field: "pr_author_name",
      headerName: "Author",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <AuthorAvatarName
          author={params.row.pr_author_name}
          authorUrl={params.row.pr_author_url}
          authorAvatarUrl={params.row.pr_author_avatar_url}
        />
      ),
    },
    {
      field: "number_of_prs",
      headerName: "# of PRs opened",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "lines_changed_weekly_avg_per_pr",
      headerName: "Average PR size (lines of code)",
      flex: 1,
      minWidth: 200,
      type: "number",
      renderCell: (params) => params.lines_changed_weekly_avg_per_pr, // Formatting for the cell in the DataGrid
    },
  ];
  const [isDataPopupOpen, setIsDataPopupOpen] = useState(false);
  // State for the filter in the Data Popup
  const [filt, setFilt] = useState(null);

  // TODO: When sprint dates are more flexible, will also need to make this date filter more flexible as well
  const handleClickCard = (authorName, dateOnOrAfter) => {
    setFilt([
      {
        id: 1,
        columnField: "pr_author_name",
        operatorValue: "equals",
        value: authorName,
      },
      {
        id: 2,
        columnField: "pr_created_at",
        operatorValue: "onOrAfter",
        value: dateOnOrAfter,
      },
    ]);
    setIsDataPopupOpen(true);
  };

  const handleClose = () => {
    setIsDataPopupOpen(false);
    // Note: Can't set this to [], since need to 'flush' the value of the array with null for the data point filters to work
    // TODO: Figure out why this is the case, not 100% sure why this works this way
    setFilt(null);
  };
  return (
    <>
      <DataPopup
        title={description.title}
        definition={description.shortDefinition}
        icon={service === "gitlab" ? mdiGitlab : mdiGithub}
        isOpen={isDataPopupOpen}
        handleClose={handleClose}
      >
        <CycleTimeDataGrid teamId={teamId} service={service} filtInput={filt} />
      </DataPopup>
      <SIDataGridPro
        autoHeight
        sx={{
          py: 1,
          px: 2,
        }}
        columns={columns}
        rows={missionData}
        disableSelectionOnClick
        hideFooter
        onRowClick={(params) => handleClickCard(params.row.pr_author_name, currentDate)}
        loading={loading}
        getRowId={(row) => row.pr_author_name}
      />
    </>
  );
}

MissionDataGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
};
