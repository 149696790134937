import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useState, useEffect } from "react";
import { useApi } from "utils/apiUtils";
import { mdiJira } from "@mdi/js"; // https://materialdesignicons.com/
import MDTypography from "components/MDTypography";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";

import BenchmarkIcon from "components_si/BenchmarkIcon";
import {
  useEditingContext,
  EditingStates,
  getDraftCardSetting,
  editCardSetting,
  CardsEnum,
} from "components_si/EditingContext";
import IssueCycleTimeDataGrid from "../IssueCycleTimeDataGrid";
import SprintIssueCycleTimeDataGrid from "../SprintIssueCycleTimeDataGrid";
import BasicKpiCard from "./BasicKpiCard";
import SprintKpiCard from "./SprintKpiCard";

function CARD_DESCRIPTION(points) {
  return {
    title: `Total ${points ? "points" : "issues"} completed`,
    shortDefinition: `Number of Jira ${points ? "points" : "issues"} completed`,
    longDefinition: (
      <>
        <MDTypography variant="body2" gutterBottom>
          A Jira issue is considered completed when its status is changed to Done.
        </MDTypography>
        <MDTypography variant="body2" gutterBottom>
          We compare you to industry benchmarks, for teams of your size:
          <Box px={2}>
            {/* TODO: Declare benchmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
            <List sx={{ listStyleType: "disc" }}>
              <ListItem sx={{ display: "list-item" }}>
                <strong>S-tier: </strong>&gt; {points ? "16 points" : "8 issues"} completed per week
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <strong>Average: </strong>
                {points ? "8-16 points" : "4-8 issues"} completed per week
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <strong>Poor: </strong>&lt; {points ? "8 points" : "4 issues"} completed per week
              </ListItem>
            </List>
          </Box>
        </MDTypography>
        <MDTypography variant="body2" gutterBottom>
          {points
            ? `Points completed is one measure of velocity, but not all teams assign points to tasks in the
              same way, so this is just one metric that you should take into consideration. If you are
              strictly looking for issue completion regardless of points estimation, you should use Issues
              Completed instead.`
            : `Issues completed is one measure of velocity, but not all issues are the same in size and
              scope so this is just one metric you should take into consideration. If you are estimating
              or pointing your issues, you should use Points Completed instead.`}
        </MDTypography>
        <MDTypography variant="body2" gutterBottom>
          Note: If a Jira issue is moved to a Done status and then moved out of a Done status (e.g.,
          it&apos;s moved back to Todo), it will no longer be counted as completed and old data
          points may be altered.
        </MDTypography>
      </>
    ),
    benchmarkTooltip: {
      stier: `> ${points ? "16 points" : "8 issues"} completed per week`,
      average: `${points ? "8-16 points" : "4-8 issues"} completed per week`,
      poor: `< ${points ? "8 points" : "4 issues"} completed per week`,
    },
    unit: points ? "points" : "issues",
    unitSingular: points ? "point" : "issue",
    highchartsTitle: `Total ${points ? "points" : "issues"} completed`,
  };
}

/**
 * @param {*} jsonBlob - A data object that must have date_week_start, count
 * @param {number} numPoints - Optional parameter, the number of data points to return. If not provided, returns all data points available in jsonBlob
 * @returns an object with an x- and a y-coordinate of length numPoints
 */
function getCardData(jsonBlob, ticketPoints, numPoints) {
  if (jsonBlob === null) {
    return null;
  }
  let jsonBlobTruncated = jsonBlob;

  if (numPoints !== undefined && numPoints < jsonBlob.length) {
    jsonBlobTruncated = jsonBlob.slice(jsonBlob.length - numPoints, jsonBlob.length);
  }

  const cardDataX = jsonBlobTruncated.map((x) => dayjs(x.date_point_start).valueOf());
  const cardDataY = jsonBlobTruncated.map((y) => y[ticketPoints ? "points" : "count"] * 1); // Multiplying by 1 to cast to int

  const cardData = [];
  for (let i = 0; i < cardDataX.length; i++) {
    cardData[i] = [cardDataX[i], cardDataY[i]];
  }
  return cardData;
}

// State for the filter in the Data Popup
const DEFAULT_ISSUES_COMPLETED_FILTER = {
  // Ids are required by DataGridPro when passing in more than one filter
  id: 1,
  columnField: "current_status_category",
  operatorValue: "equals",
  value: "DONE",
};

function KpiIssuesCompletedCard({
  teamId,
  dateRange,
  bucket: bucketProp,
  points: pointsProp,
  bucketDropdownOptions,
}) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  // TODO: should handle the null case as a loading state to prevent a flicker
  const [cardState, setCardState] = useState({ bucket: null, points: false });
  useEffect(() => {
    setCardState({ bucket: bucketProp, points: pointsProp });
  }, [bucketProp, pointsProp]);

  const getStateWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.IssuesCompleted);
        return {
          ...cardState,
          ...settings,
        };
      }
      case EditingStates.Normal:
        return cardState;
      default:
        console.log("Invalid editing states mode");
        return cardState;
    }
  };

  const setStateWrapper = (newCardState) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.IssuesCompleted);
        const newSettings = {
          ...cardState,
          ...settings,
          ...newCardState,
        };
        editCardSetting(dispatch, CardsEnum.IssuesCompleted, newSettings);
        setCardState(newSettings);
        break;
      }
      case EditingStates.Normal: {
        setCardState({
          ...cardState,
          ...newCardState,
        });
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setCardState({
          ...cardState,
          ...newCardState,
        });
      }
    }
  };

  const startDate = encodeURIComponent(dateRange[0].toISOString());
  const endDate = encodeURIComponent(dateRange[1].toISOString());
  const { data } = useApi({
    url: `/api/teams/${teamId}/issues_completed_count?bucket=${
      getStateWrapper().bucket
    }&start=${startDate}&end=${endDate}&points=${getStateWrapper().points}`,
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/issues_completed_count?bucket=all&start=${startDate}&end=${endDate}&points=${
      getStateWrapper().points
    }`,
  });
  const [filt, setFilt] = useState([DEFAULT_ISSUES_COMPLETED_FILTER]);
  const processedData = getCardData(data, getStateWrapper().points);

  return getStateWrapper().bucket === "sprint" ? (
    <SprintKpiCard
      cardType={CardsEnum.IssuesCompleted}
      rawData={data}
      aggregateData={aggregateData?.[0][getStateWrapper().points ? "points" : "count"]}
      description={CARD_DESCRIPTION(getStateWrapper().points)}
      onSelectBucket={(newBucket) => setStateWrapper({ bucket: newBucket })}
      setFilt={setFilt}
      onClosePopup={() => {
        setFilt([DEFAULT_ISSUES_COMPLETED_FILTER]);
      }}
      popupContent={
        <SprintIssueCycleTimeDataGrid
          teamId={teamId}
          filtInput={filt}
          anchorColumn="date_week_done"
          sortField="last_done_time"
        />
      }
      unit={getStateWrapper().points ? "points" : "count"}
      bucketDropdownOptions={bucketDropdownOptions}
      metricsType={
        getStateWrapper().points ? MetricsEnum.PointsCompleted : MetricsEnum.IssuesCompleted
      }
      dataSourceIcon={mdiJira}
      defaultFilters={[DEFAULT_ISSUES_COMPLETED_FILTER]}
      columnField="last_done_time"
      onSelectDataType={(currType) => setStateWrapper({ points: currType === "points" })}
      dataDropdownOptions={[
        { label: "issues", value: "issues" },
        { label: "points", value: "points" },
      ]}
      dataType={getStateWrapper().points ? "points" : "issues"}
    />
  ) : (
    <BasicKpiCard
      cardType={CardsEnum.IssuesCompleted}
      data={processedData}
      bucket={getStateWrapper().bucket}
      onSelectBucket={(newBucket) => setStateWrapper({ bucket: newBucket })}
      aggregateData={aggregateData?.[0][getStateWrapper().points ? "points" : "count"]}
      renderBenchmark={(dataPoint, numWeeks) => (
        <Box pt={1}>
          <BenchmarkIcon
            tier={evaluateTier(
              dataPoint,
              getStateWrapper().points ? MetricsEnum.PointsCompleted : MetricsEnum.IssuesCompleted,
              numWeeks
            )}
            tooltip={CARD_DESCRIPTION(getStateWrapper().points).benchmarkTooltip}
          />
        </Box>
      )}
      description={CARD_DESCRIPTION(getStateWrapper().points)}
      teamId={teamId}
      dataSourceIcon={mdiJira}
      popupContent={
        <IssueCycleTimeDataGrid
          teamId={teamId}
          filtInput={filt}
          anchorColumn="date_week_done"
          sortField="last_done_time"
        />
      }
      onClickDataPoint={(x) => {
        const filterStartDate = dayjs(x).format("YYYY-MM-DD");
        let filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");
        if (getStateWrapper().bucket === "week") {
          filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");
        } else if (getStateWrapper().bucket === "month") {
          filterEndDate = dayjs(x).add(1, "month").format("YYYY-MM-DD");
        } else if (getStateWrapper().bucket === null) {
          // TODO: handle loading state
        } else {
          console.log("unsupported bucket type");
        }

        setFilt([
          DEFAULT_ISSUES_COMPLETED_FILTER,
          {
            id: 2,
            columnField: "last_done_time",
            operatorValue: "onOrAfter",
            value: filterStartDate,
          },
          {
            id: 3,
            columnField: "last_done_time",
            operatorValue: "before",
            value: filterEndDate,
          },
        ]);
      }}
      onClosePopup={() => {
        setFilt([DEFAULT_ISSUES_COMPLETED_FILTER]);
      }}
      bucketDropdownOptions={bucketDropdownOptions}
      onSelectDataType={(currType) => setStateWrapper({ points: currType === "points" })}
      dataDropdownOptions={[
        { label: "issues", value: "issues" },
        { label: "points", value: "points" },
      ]}
      dataType={getStateWrapper().points ? "points" : "issues"}
    />
  );
}

KpiIssuesCompletedCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
  points: false,
  bucketDropdownOptions: [
    { label: "weekly", value: "week" },
    { label: "monthly", value: "month" },
    { label: "sprintly", value: "sprint" },
  ],
};

KpiIssuesCompletedCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
  points: PropTypes.bool,
  bucketDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default KpiIssuesCompletedCard;
