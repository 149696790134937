// Base Styles
import colors from "assets/theme-dark/base/colors";

const { background } = colors;

const paper = {
  styleOverrides: {
    root: {
      backgroundColor: background.default,
      boxShadow: "none",
      overflow: "visible",
    },
  },
};

export default paper;
