/**
 * Layout for the integrations page
 */
// Utilities
import { useApiFetch } from "utils/apiUtils";
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import darkModeColors from "assets/theme-dark/base/colors";
import { useSearchParams, useParams } from "react-router-dom";

const { border } = darkModeColors;
function IntegrationRedirect() {
  const { integration } = useParams();
  const { apiFetch } = useApiFetch();

  const [shouldShowMessage, setShouldShowMessage] = useState(false);

  const [isIntegrationLoading, setIsIntegrationLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);

  const redirectWithDelay = (seconds = 3) => {
    setTimeout(() => {
      window.location.href = "/integrations";
    }, seconds * 1000);
  };

  useEffect(async () => {
    try {
      let response;
      switch (integration) {
        case "google":
          response = await apiFetch(`/oauth/google-calendar?${searchParams.toString()}`, {
            method: "POST",
          });
          break;
        case "github_app":
          // The Github App has a "setup url" that redirects to this url.
          // The query params that it passes are:
          // - installation_id: <number> (only if setup_action is 'install')
          // - setup_action: 'install', 'request'

          response = await apiFetch(`/github-installation?${searchParams.toString()}`, {
            method: "POST",
          });
          break;
        default:
          setError("Integration not supported");
      }
      setIsIntegrationLoading(false);
      if (response.error) {
        setError(response.error);
      }
    } catch (e) {
      setIsIntegrationLoading(false);
      setError(e.message ?? "Something went wrong... Please try again after redirect!");
    }
    redirectWithDelay();
  }, []);

  return (
    <DashboardLayout>
      <Box
        sx={{
          pt: 3.25,
          pb: 3.25,
          pl: 2,
          borderBottom: 0.5,
          borderLeft: 0.5,
          borderColor: border.light,
        }}
      >
        <MDTypography variant="h2">Integrations</MDTypography>
      </Box>
      <MDTypography variant="h2" color="white">
        {isIntegrationLoading && "Processing..."}
        {!isIntegrationLoading && !!error && `ERROR: ${error}. Redirecting...`}
        {!isIntegrationLoading && !error && "Connected! Redirecting..."}
      </MDTypography>
      <MDSnackbar
        icon="notifications"
        title="Contact us"
        content={
          <span>
            To offboard integrations, please contact us at{" "}
            <a href="mailto:info@setori.ai" style={{ color: "white" }}>
              <b>info@setori.ai</b>
            </a>
          </span>
        }
        open={shouldShowMessage}
        onClose={() => {
          setShouldShowMessage(false);
        }}
        close={() => {
          setShouldShowMessage(false);
        }}
      />
    </DashboardLayout>
  );
}

export default IntegrationRedirect;
