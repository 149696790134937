import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useState, useEffect } from "react";
import { useApi } from "utils/apiUtils";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";

import MDTypography from "components/MDTypography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import services from "utils/services";
import BenchmarkIcon from "components_si/BenchmarkIcon";
import {
  useEditingContext,
  EditingStates,
  getDraftCardSetting,
  editCardSetting,
  CardsEnum,
} from "components_si/EditingContext";
import GraphTypeEnum from "layouts/projects/utils/GraphTypeEnum";
import GithubKpiCard from "./GithubKpiCard";

// TODO: For consideration - extract the descriptions for all the KPI cards in a seperate file in a dictionary.
// Also, change APIs to have a common name (e.g., kpi_data instead of lines_changed_weekly_avg_per_pr)
// This would avoid needing this additional level (i.e., could go directly to basic KPI card)
const description = {
  title: "PR size",
  shortDefinition: "Lines of code changed in a pull request",
  longDefinition: (
    <>
      <MDTypography variant="body2" gutterBottom>
        The average size of pull requests (PRs) opened within a project each week. The size of a PR
        is the sum of all lines of code added or deleted.
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        We compare you to industry benchmarks:
        <Box px={2}>
          {/* TODO: Declare benhmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>S-tier: </strong>&lt; 600 lines of code per PR
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Average: </strong>600-1,200 lines of code per PR
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Poor: </strong> &gt; 1,200 lines of code per PR
            </ListItem>
          </List>
        </Box>
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        Smaller PRs tend to be easier to review and merge. This contributes to higher velocity and
        lower defects.
      </MDTypography>
    </>
  ),
  benchmarkTooltip: {
    stier: "< 600 lines of code per PR",
    average: "600-1,200 lines of code per PR",
    poor: "> 1,200 lines of code per PR",
  },
  unit: "lines of code",
  unitSingular: "line of code",
  highchartsTitle: "Average PR size",
};

/**
 * @param {*} jsonBlob - A data object that must have date_week_start, lines_changed_weekly_avg_per_pr
 * @param {number} numPoints - Optional parameter, the number of data points to return. If not provided, returns all data points available in jsonBlob
 * @returns an object with an x- and a y-coordinate of length numPoints
 */
function processCardData(jsonBlob, numPoints) {
  if (jsonBlob === null) {
    return null;
  }

  const cardData = [];
  let jsonBlobTruncated = jsonBlob;

  if (numPoints !== undefined && numPoints < jsonBlob.length) {
    jsonBlobTruncated = jsonBlob.slice(jsonBlob.length - numPoints, jsonBlob.length);
  }

  const cardDataX = jsonBlobTruncated.map((x) => dayjs(x.date_point_start).valueOf());
  const cardDataY = jsonBlobTruncated.map((y) => parseFloat(y.lines_changed_weekly_avg_per_pr));

  for (let i = 0; i < cardDataX.length; i++) {
    cardData[i] = [cardDataX[i], cardDataY[i]];
  }
  return cardData;
}

function processAggregateData(aggregateData) {
  const val = aggregateData?.[0]?.lines_changed_weekly_avg_per_pr;

  if (val) {
    return parseFloat(val);
  }

  return val;
}

function processScatterData(data, prData, bucket) {
  const dates = processCardData(data).map((item) => item[0]);

  return dates.map((date, index) => {
    const prDataTransformed = prData
      .filter((item) => {
        const createdAt = dayjs(item.pr_created_at).valueOf(); // Convert to milliseconds
        const periodStart = dayjs(date).valueOf(); // Convert to milliseconds
        let periodEnd;
        if (bucket === "month") {
          periodEnd = dayjs(date).add(1, "month").valueOf(); // Add 1 month
        } else if (bucket === "week") {
          periodEnd = dayjs(date).add(7, "day").valueOf(); // Add 7 days
        } else {
          console.log("unsupported bucket size in PR size card - can only be weekly or monthly");
          // fall back to a week
          periodEnd = dayjs(date).add(7, "day").valueOf();
        }

        return createdAt >= periodStart && createdAt < periodEnd;
      })
      .map((item) => [index, item.pr_lines_changed, item.pr_title]);

    return {
      name: date.toString(),
      data: prDataTransformed,
    };
  });
}

function KpiPrSizeCard({ teamId, dateRange, bucket: bucketProp, graph: graphProp, service }) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  // TODO: should handle the null case as a loading state to prevent a flicker
  const [cardState, setCardState] = useState({ bucket: null, graph: null });
  useEffect(() => {
    setCardState({ bucket: bucketProp, graph: graphProp });
  }, [bucketProp, graphProp]);

  const getStateWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.PrSize);
        return {
          ...cardState,
          ...settings,
        };
      }
      case EditingStates.Normal:
        return cardState;
      default:
        console.log("Invalid editing states mode");
        return cardState;
    }
  };

  const setStateWrapper = (newCardState) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.PrSize);
        const newSettings = {
          ...cardState,
          ...settings,
          ...newCardState,
        };
        editCardSetting(dispatch, CardsEnum.PrSize, newSettings);
        setCardState(newSettings);
        break;
      }
      case EditingStates.Normal: {
        setCardState({
          ...cardState,
          ...newCardState,
        });
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setCardState({
          ...cardState,
          ...newCardState,
        });
      }
    }
  };

  const [startDate, endDate] = dateRange;
  const encodedStartDate = encodeURIComponent(startDate.toISOString());
  const encodedEndDate = encodeURIComponent(endDate.toISOString());
  const { data } = useApi({
    url: `/api/teams/${teamId}/lines_changed_sum?service=${service}&bucket=${
      getStateWrapper().bucket
    }&start=${encodedStartDate}&end=${encodedEndDate}`,
    defaultData: [],
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/lines_changed_sum?service=${service}&bucket=all&start=${encodedStartDate}&end=${encodedEndDate}`,
    defaultData: [],
  });
  const { data: prData } = useApi({
    url: `/api/teams/${teamId}/prs_computed_table?service=${service}`,
    defaultData: [],
  });

  return (
    <GithubKpiCard
      cardType={CardsEnum.PrSize}
      data={processCardData(data)}
      scatterData={processScatterData(data, prData, getStateWrapper().bucket)}
      bucket={getStateWrapper().bucket}
      graph={getStateWrapper().graph}
      onSelectBucket={(newBucket) => setStateWrapper({ bucket: newBucket })}
      onSelectGraph={(newGraph) => setStateWrapper({ graph: newGraph })}
      aggregateData={processAggregateData(aggregateData)}
      renderBenchmark={(dataPoint, numWeeks) => (
        <Box pt={1}>
          <BenchmarkIcon
            tier={evaluateTier(dataPoint, MetricsEnum.PrSize, numWeeks)}
            tooltip={description.benchmarkTooltip}
          />
        </Box>
      )}
      graphDropdownOptions={[
        { label: "line", value: GraphTypeEnum.Line },
        { label: "scatter", value: GraphTypeEnum.Scatter },
      ]}
      description={description}
      teamId={teamId}
      service={service}
      actualStartDate={startDate}
    />
  );
}

// Setting default values for the props
KpiPrSizeCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
  graph: GraphTypeEnum.Line,
};

// Typechecking props
KpiPrSizeCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
  graph: PropTypes.oneOf(Object.values(GraphTypeEnum)),
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
};

export default KpiPrSizeCard;
