// Base Styles
import colors from "assets/theme-dark/base/colors";
import typography from "assets/theme-dark/base/typography";

const { text, info, background, border } = colors;

const dataGrid = {
  styleOverrides: {
    root: {
      color: text.main,
      border: `0.5px solid ${border.light}`,
      borderRight: 0,
      borderTop: 0,
      borderRadius: 0,
      boxShadow: "none",
      overflow: "visible",
      "& .MuiDataGrid-columnHeaders": {
        borderColor: background.hover,
        borderWidth: "2px",
      },
      "& .MuiDataGrid-columnHeader: focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: info.main,
        fontFamily: typography.fontFamily,
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      "& .MuiDataGrid-row: hover": {
        backgroundColor: background.hover,
      },
      "& .MuiDataGrid-cell": {
        borderColor: background.hover,
        borderWidth: "2px",
      },
      "& .MuiDataGrid-cell: focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-footerContainer": {
        border: 0,
      },
    },
  },
};

export default dataGrid;
