import { useState } from "react";

import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function InfoButton({ title, icon, message, onClose }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (onClose !== undefined) {
      onClose();
    }
  };

  return (
    <>
      <IconButton aria-label="Icon button" onClick={handleClickOpen} sx={{ p: 0.5 }}>
        <HelpOutlineIcon />
      </IconButton>
      {/* Popup when info button is clicked */}
      <Dialog onClose={handleClose} maxWidth="lg" fullWidth open={open}>
        <DialogTitle sx={{ m: 0, pt: 2, pb: 1 }}>
          <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
            {icon && (
              <SvgIcon color="light" fontSize="medium">
                <path d={icon} />
              </SvgIcon>
            )}
            <Box>{title}</Box>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{message}</DialogContent>
      </Dialog>
    </>
  );
}

InfoButton.defaultProps = {
  message: "",
  onClose: undefined,
  icon: undefined,
};

InfoButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
};
