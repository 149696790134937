import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useState, useEffect } from "react";
import { useApi } from "utils/apiUtils";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";

import MDTypography from "components/MDTypography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";

import {
  useEditingContext,
  EditingStates,
  getDraftCardSetting,
  editCardSetting,
  CardsEnum,
} from "components_si/EditingContext";
import BenchmarkIcon from "components_si/BenchmarkIcon";
import services from "utils/services";
import GithubReviewKpiCard from "./GithubReviewKpiCard";

const CARD_DESCRIPTION = {
  title: "Reviews submitted",
  shortDefinition: "Number of reviews submitted",
  longDefinition: (
    <>
      <MDTypography variant="body2" gutterBottom>
        The number of reviews submitted on a team.
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        We compare you to industry benchmarks, for teams of your size:
        <Box px={2}>
          {/* TODO: Declare benhmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>S-tier: </strong>&gt; 8 reviews per week
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Average: </strong>4-8 reviews per week
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Poor: </strong> &lt; 4 reviews per week
            </ListItem>
          </List>
        </Box>
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        All else equal, teams with more reviews are learning more, and improving the quality of
        shipped code.
      </MDTypography>
    </>
  ),
  benchmarkTooltip: {
    stier: "> 8 reviews per week",
    average: "4-8 reviews per week",
    poor: "< 4 PRs reviews per week",
  },
  unit: "reviews",
  unitSingular: "review",
  highchartsTitle: "Total reviews submitted",
};

/**
 * @param {*} jsonBlob - A data object that must have date_point_start, lines_changed_weekly_avg_per_pr
 * @param {number} numPoints - Optional parameter, the number of data points to return. If not provided, returns all data points available in jsonBlob
 * @returns an object with an x- and a y-coordinate of length numPoints
 */
const getCardData = (jsonBlob, numPoints) => {
  if (jsonBlob === null) {
    return null;
  }

  const cardData = [];
  let jsonBlobTruncated = jsonBlob;

  if (numPoints !== undefined && numPoints < jsonBlob.length) {
    jsonBlobTruncated = jsonBlob.slice(jsonBlob.length - numPoints, jsonBlob.length);
  }

  const cardDataX = jsonBlobTruncated.map((x) => dayjs(x.date_point_start).valueOf());
  const cardDataY = jsonBlobTruncated.map((y) => y.reviews_count * 1); // Multiplying by 1 to cast to int

  for (let i = 0; i < cardDataX.length; i++) {
    cardData[i] = [cardDataX[i], cardDataY[i]];
  }
  return cardData;
};

function KpiReviewCard({ teamId, dateRange, bucket: bucketProp, service }) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  // TODO: should handle the null case as a loading state to prevent a flicker
  const [bucket, setBucket] = useState(null);
  useEffect(() => {
    setBucket(bucketProp);
  }, [bucketProp]);

  const getBucketWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving:
        return getDraftCardSetting(draft, CardsEnum.Reviews)?.bucket ?? bucket;
      case EditingStates.Normal:
        return bucket;
      default:
        console.log("Invalid editing states mode");
        return bucket;
    }
  };

  const setBucketWrapper = (newBucket) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        editCardSetting(dispatch, CardsEnum.Reviews, { bucket: newBucket });
        break;
      }
      case EditingStates.Normal: {
        setBucket(newBucket);
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setBucket(newBucket);
      }
    }
  };

  const [startDate, endDate] = dateRange;
  const encodedStartDate = encodeURIComponent(startDate.toISOString());
  const encodedEndDate = encodeURIComponent(endDate.toISOString());
  const { data } = useApi({
    url: `/api/teams/${teamId}/reviews_count?service=${service}&bucket=${getBucketWrapper()}&start=${encodedStartDate}&end=${encodedEndDate}`,
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/reviews_count?service=${service}&bucket=all&start=${encodedStartDate}&end=${encodedEndDate}`,
  });

  return (
    <GithubReviewKpiCard
      data={getCardData(data)}
      bucket={getBucketWrapper()}
      onSelectBucket={(currBucket) => {
        setBucketWrapper(currBucket);
      }}
      aggregateData={aggregateData?.[0]?.reviews_count}
      renderBenchmark={(dataPoint, numWeeks) => (
        <Box pt={1}>
          <BenchmarkIcon
            tier={evaluateTier(dataPoint ?? 0, MetricsEnum.ReviewsSubmitted, numWeeks)}
            tooltip={CARD_DESCRIPTION.benchmarkTooltip}
          />
        </Box>
      )}
      description={CARD_DESCRIPTION}
      teamId={teamId}
      service={service}
      actualStartDate={startDate}
    />
  );
}

// Setting default values for the props of Cycle Time Card
KpiReviewCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
};

// Typechecking props for the Cycle Time Card
KpiReviewCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
};

export default KpiReviewCard;
