// Base Styles
import colors from "assets/theme-dark/base/colors";

const { text, background } = colors;

const snackBarContent = {
  styleOverrides: {
    root: {
      color: text.main,
      backgroundColor: background.snackbar,
    },
  },
};

export default snackBarContent;
