/**
 * Setori integration card
 */

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import SvgIcon from "@mui/material/SvgIcon";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

function IntegrationCard({ title, subtitle, icon, description, onClick }) {
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: "0.5px solid #2C2E3F",
      }}
    >
      <Box p={1.5}>
        <CardActionArea
          onClick={onClick}
          disabled={onClick === undefined}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h2" sx={{ height: "24px" }}>
            {title}
          </MDTypography>
          <MDTypography variant="subtitle1">{subtitle}</MDTypography>
          <Stack direction="column" sx={{ display: "flex", alignItems: "center" }}>
            <SvgIcon color="dark" fontSize="large">
              <path d={icon} />
            </SvgIcon>
            <MDTypography sx={{ textAlign: "center" }} variant="body2">
              {description}
            </MDTypography>
          </Stack>
        </CardActionArea>
      </Box>
    </Card>
  );
}

IntegrationCard.defaultProps = {
  description: "",
  onClick: undefined,
};

IntegrationCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func,
};

export default IntegrationCard;
