// Base Styles
import colors from "assets/theme-dark/base/colors";

const { border } = colors;

const chip = {
  styleOverrides: {
    root: {
      borderColor: border.light,
    },
  },
};

export default chip;
