import PropTypes from "prop-types";

/* eslint-disable dot-notation */
import { useState, useCallback } from "react";

import InfiniteScroll from "react-infinite-scroller";

// @mui material components
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// @mui material lab component
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// Setori React example
import ListCard from "examples/Cards/ListCard";
import { useApiFetch } from "utils/apiUtils";

// E.g., Wed Aug 17
function getDayString(dateString) {
  // TODO: There's probably a more robust way to cut off the year, like date formatting
  return new Date(dateString).toDateString().slice(0, -5);
}

// TODO: Use a library to make this human time
function getReadableTimeStamp(dateString) {
  const date = new Date(dateString);
  // TODO: could probably put the getDayString in here
  const amPmTime = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return `${getDayString(dateString)}, ${amPmTime}`;
}

/**
 * @param {*} obj - Object with at least two keys: old_workflow_state_name and
 * new_workflow_state_name. old_workflow_state_name may be undefined.
 */
function getStateString(obj) {
  if (obj["old_workflow_state_name"] === undefined || obj["old_workflow_state_name"] === null) {
    if (obj["member_name"] === null) {
      // We assume that it's imported if there is no member_name
      return `was imported (${obj["new_workflow_state_name"]})`;
    }
    // TODO: should probably make member_name clickable to the profile
    return `was created by ${obj["member_name"]} (${obj["new_workflow_state_name"]})`;
  }
  // TODO: Is the member name the person who owns the task or the person who
  // moved it from state to state? It's the person who moved the task.
  // TODO: just better styling in general
  return `was moved from ${obj["old_workflow_state_name"]} to ${obj["new_workflow_state_name"]}`;
}

function ProjectActivityLog({ teamId }) {
  const { apiFetch: fetch } = useApiFetch();
  const [activityLogItems, setActivityLogItems] = useState([]);

  const formattedActivityLogData = activityLogItems.map((obj) => ({
    time: obj["changed_at"],
    member_name: obj["member_name"],
    story_name: obj["story_name"],
    // TODO: pass in organization name somehow -- maybe from the obj, maybe
    // from some other state we have saved by now
    url: `https://app.shortcut.com/setori/story/${obj["story_id"]}`,
    state_string: getStateString(obj),
  }));

  const timelineItems = [];
  for (let i = 0; i < formattedActivityLogData.length; i++) {
    timelineItems.push(
      <TimelineItem key={i}>
        <TimelineOppositeContent>
          <MDTypography variant="body2" fontSize="20px">
            {getDayString(formattedActivityLogData[i]["time"])}
          </MDTypography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <ListCard>
            <Stack direction="row" pt={1}>
              <Box pt={0.5}>
                <Avatar>
                  {/* Take the first letter of the first and last name */}
                  {/* TODO: make this a util */}
                  {/* Sometimes it's a null when you have imported your tickets */}
                  {formattedActivityLogData[i]["member_name"] !== null
                    ? formattedActivityLogData[i]["member_name"]
                        .split(" ")
                        .reduce((prev, curr) => prev + curr[0], "")
                    : "SH"}
                  {/* TODO: replace SH with a icon depending on type (e.g., shortcut) */}
                </Avatar>
              </Box>
              <Stack px={2} spacing={1}>
                <MDTypography
                  variant="body2"
                  component="a"
                  href={formattedActivityLogData[i]["url"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>{formattedActivityLogData[i]["story_name"]}</strong>{" "}
                  {formattedActivityLogData[i]["state_string"]}
                </MDTypography>
                <MDTypography variant="caption">
                  {getReadableTimeStamp(formattedActivityLogData[i]["time"])}
                </MDTypography>
              </Stack>
            </Stack>
          </ListCard>
        </TimelineContent>
      </TimelineItem>
    );
  }

  const [nextPageUrl, setNextPageUrl] = useState(`/api/teams/${teamId}/ticket_activity?per_page=4`);
  const hasMoreItems = nextPageUrl !== null;
  const [fetching, setFetching] = useState(false);
  const fetchMoreItems = useCallback(async () => {
    if (fetching || nextPageUrl === null) {
      return;
    }
    setFetching(true);
    const responseParsed = await fetch(nextPageUrl);
    setActivityLogItems([...activityLogItems, ...responseParsed.data]);
    setNextPageUrl(responseParsed.next === null ? null : responseParsed.next);
    setFetching(false);
  }, [activityLogItems, fetching, nextPageUrl]);

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      <InfiniteScroll
        pageStart={0}
        loadMore={fetchMoreItems}
        hasMore={hasMoreItems}
        loader={<div>Loading...</div>}
      >
        {timelineItems}
      </InfiniteScroll>
    </Timeline>
  );
}

ProjectActivityLog.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default ProjectActivityLog;
