import PropTypes from "prop-types";

import { createContext, useReducer, useMemo, useContext } from "react";

const EditingContext = createContext();

// Setting custom name for the context which is visible on react dev tools
EditingContext.displayName = "EditingContext";

// Note these are similar to MetricsEnum but are different since not every metric = 1 card
const CardsEnum = {
  CycleTime: "card_cycle",
  CodingTime: "card_coding",
  PickupTime: "card_pickup",
  ChangedLines: "card_changedLines", // aka additions & deletions
  PrsOpened: "card_prsOpened",
  IssuesCompleted: "card_issuesCompleted",
  IssueCycleTime: "card_issueCycleTime",
  IssuesCompletedPercent: "card_issuesCompletedPercent",
  PrSize: "card_prSize",
  MergedWithoutReview: "card_mergedWithoutReview",
  Reviews: "card_reviewsSubmitted",
  ReviewDepth: "card_reviewDepth",
  ReviewsSubmitted: "card_reviewsSubmitted",
  DeploymentsCreated: "card_deploymentsCreated",
};

const SettingTypesEnum = {
  Cards: "cards",
};

const EditingStates = {
  Normal: "normal",
  Editing: "editing",
  Saving: "saving",
};

const INITIAL_STATE = {
  mode: EditingStates.Normal,
  settings: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "EDITING_CONTEXT_EDITING_MODE": {
      return {
        ...state,
        mode: EditingStates.Editing,
      };
    }
    case "EDITING_CONTEXT_EDIT_SETTINGS": {
      if (!state.mode === EditingStates.Editing) {
        throw new Error("Trying to edit settings while not in edit mode");
      }

      const { settings: currentSettings } = state;

      const newSettings = {
        ...currentSettings,
        [action.settingsType]: {
          ...currentSettings?.[action.settingsType],
          ...action.settings,
        },
      };

      const newState = {
        ...state,
        settings: {
          ...newSettings,
        },
      };
      return newState;
    }
    case "EDITING_CONTEXT_SAVE": {
      if (!state.mode === EditingStates.Editing) {
        throw new Error("Trying to edit settings while not in edit mode");
      }

      if (state.settings === null) {
        return INITIAL_STATE;
      }

      return {
        ...state,
        mode: EditingStates.Saving,
      };
    }
    case "EDITING_DONE_SAVING":
    case "EDITING_CONTEXT_DISCARD": {
      if (!state.mode === EditingStates.Editing) {
        throw new Error("Trying to edit settings while not in edit mode");
      }
      return INITIAL_STATE;
    }
    case "EDITING_CONTEXT_RESET_TO_DEFAULT": {
      if (!state.mode === EditingStates.Editing) {
        throw new Error("Trying to edit settings while not in edit mode");
      }

      return {
        mode: EditingStates.Saving,
        settings: {},
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function EditingContextProvider({ children }) {
  const [draft, dispatch] = useReducer(reducer, INITIAL_STATE);

  const value = useMemo(() => [draft, dispatch], [draft, dispatch]);

  return <EditingContext.Provider value={value}>{children}</EditingContext.Provider>;
}

function useEditingContext() {
  const context = useContext(EditingContext);

  if (context === undefined) {
    throw new Error("useEditingContext must be used within a EditingContextProvider");
  }

  return context;
}

EditingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Setters
const setIsEditing = (dispatch) => {
  dispatch({ type: "EDITING_CONTEXT_EDITING_MODE" });
};
const resetToDefault = (dispatch) => {
  dispatch({ type: "EDITING_CONTEXT_RESET_TO_DEFAULT" });
};
const setDoneSaving = (dispatch) => {
  dispatch({ type: "EDITING_DONE_SAVING" });
};
const discardDraft = (dispatch) => {
  dispatch({ type: "EDITING_CONTEXT_DISCARD" });
};
const startSaving = (dispatch) => {
  dispatch({ type: "EDITING_CONTEXT_SAVE" });
};
const editCardSetting = (dispatch, card, cardSettings) => {
  dispatch({
    type: "EDITING_CONTEXT_EDIT_SETTINGS",
    settingsType: SettingTypesEnum.Cards,
    settings: {
      [card]: cardSettings,
    },
  });
};

// Getters
// could make sense to somehow put this in the useContext return instead
const getDraftCardSetting = (draft, card) => draft?.settings?.[SettingTypesEnum.Cards]?.[card];

export {
  EditingContextProvider,
  useEditingContext,
  CardsEnum,
  EditingStates,
  setIsEditing,
  resetToDefault,
  discardDraft,
  startSaving,
  setDoneSaving,
  editCardSetting,
  getDraftCardSetting,
};
