import PropTypes from "prop-types";
import dayjs from "dayjs";

// Utilities
import { useApi } from "utils/apiUtils";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";

// Setori Components
import AuthorAvatarName from "components_si/AuthorAvatarName";
import SIDataGrid from "components_si/SIDataGridPro";
import services from "utils/services";

export default function MissionDataGrid({ teamId, service }) {
  const { data: missionData } = useApi({
    url: `/api/teams/${teamId}/coding_mission?service=${service}`,
    defaultData: [],
  });

  const columns = [
    {
      field: "pr_author_name",
      headerName: "Author",
      minWidth: 200,
      maxWidth: 500,
      renderCell: (params) => (
        <AuthorAvatarName
          author={params.row.pr_author_name}
          authorAvatarUrl={params.row.pr_author_avatar_url}
        />
      ),
    },
    {
      field: "pr_created_at",
      headerName: "Date",
      type: "date",
      minWidth: 200,
      maxWidth: 500,
      renderCell: (params) =>
        params.row.pr_created_at !== null
          ? dayjs(params.row.pr_created_at).format("YYYY-MM-DD [at] HH:mm")
          : "",
    },
    {
      field: "pr_title",
      headerName: "Title",
      flex: 1,
      minWidth: 500,
    },
    {
      field: "coding_time",
      headerName: "Coding time",
      minWidth: 200,
      maxWidth: 500,
      type: "number", // Need to specify that valueGetter returns a number for sorting
      headerAlign: "left", // Need to explicitly align left, since type: "number" aligns right
      align: "left", // Need to explicitly align left, since type: "number" aligns right
      valueGetter: (params) => params.row.coding_time_epoch, // Value used for sorting and export
      valueFormatter: (params) => convertMillisecondsToReadableTime(params.value * 1000), // Formatting the value for csv export
    },
  ];

  return (
    <SIDataGrid
      autoHeight
      sx={{
        py: 1,
        px: 2,
      }}
      columns={columns}
      rows={missionData}
      hideFooter
      onRowClick={(params) => window.open(params.row.pr_url)}
      getRowId={(row) => row.pr_id}
    />
  );
}

MissionDataGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
};
