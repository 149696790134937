/**
 * Layout for the sign in page
 */

import { useAuth0 } from "@auth0/auth0-react";

import { useApiFetch } from "utils/apiUtils";

import { useNavigate } from "react-router-dom";

// @mui material components
// import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

import MDButton from "components/MDButton";

import { useEffect } from "react";

function SignIn() {
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const { apiFetch: fetch } = useApiFetch();

  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      // if logged in
      if (!isLoading && isAuthenticated) {
        const { isNewTenant, landingTeamId } = await fetch("/api/sign-in", {
          method: "POST",
        });

        if (isNewTenant || landingTeamId == null) {
          navigate("/integrations");
        } else {
          navigate(`/projects/${landingTeamId}`);
        }
      }
    })();
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  let authenticationPanel;
  if (isLoading) {
    authenticationPanel = "Loading...";
  } else if (isAuthenticated) {
    authenticationPanel = "Redirecting...";
  } else {
    authenticationPanel = (
      <MDBox mt={4}>
        <MDButton variant="gradient" color="info" onClick={() => loginWithRedirect()}>
          Sign In
        </MDButton>
      </MDBox>
    );
  }

  return (
    <PageLayout>
      <Box sx={{ m: 20 }}>
        <MDTypography variant="h2">Welcome to Setori!</MDTypography>
        {authenticationPanel}
      </Box>
    </PageLayout>
  );
}

export default SignIn;
