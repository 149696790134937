import { PropTypes } from "prop-types";
// @mui material components
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

import darkModeColors from "assets/theme-dark/base/colors";

const { border } = darkModeColors;

function MissionOverview({ target }) {
  return (
    <Box
      sx={{
        py: 4,
        pl: 3,
        borderBottom: 0.5,
        borderLeft: 0.5,
        borderColor: border.light,
        height: "100%",
      }}
    >
      <MDTypography variant="body1" color="info" gutterBottom>
        <strong>Mission:</strong> Ship shorter, more frequent PRs
      </MDTypography>
      <Box sx={{ p: 1 }} />
      <MDTypography variant="body1" color="info" gutterBottom>
        <strong>Target:</strong> Average PR size less than {target} lines of code
      </MDTypography>
    </Box>
  );
}

MissionOverview.propTypes = {
  target: PropTypes.number.isRequired,
};

export default MissionOverview;
