import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useState } from "react";

// Utilities
import { useApi } from "utils/apiUtils";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";
import services from "utils/services";

// Setori Components
import DataPopup from "components_si/DataPopup";
import { mdiGithub } from "@mdi/js";
import AuthorAvatarName from "components_si/AuthorAvatarName";
import SIDataGrid from "components_si/SIDataGridPro";

import ReviewsDataGrid from "./ReviewsDataGrid";

const description = {
  title: "Reviews",
  shortDefinition: "For each reviewer, on each PR",
};

export default function MissionDataGrid({ teamId, service }) {
  // TODO: Someday, we'll probably need to make this depend on the start work date for a country.
  // This is the Monday of the current week
  const currentDate = dayjs().day(1).format("YYYY-MM-DD");

  const { data: missionData } = useApi({
    url: `/api/teams/${teamId}/review_mission/weeklyavg/${currentDate}?service=${service}`,
    defaultData: [],
  });

  // Added more fields than strictly necessary so that the mission can be fine-tuned based on client feedback
  const columns = [
    {
      field: "reviewer_name",
      headerName: "Reviewer",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <AuthorAvatarName
          author={params.row.reviewer_name}
          authorAvatarUrl={params.row.reviewer_avatar_url}
        />
      ),
    },
    // {
    //   field: "number_of_changes_requested_reviews",
    //   headerName: "# of changes requested reviews",
    //   flex: 1,
    //   minWidth: 200,
    // },
    // {
    //   field: "number_of_approved_reviews",
    //   headerName: "# of approved reviews",
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "number_of_reviews",
      headerName: "# of reviews",
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "number_of_comments",
    //   headerName: "Comments/review",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: (params) =>
    //     Math.round((params.row.number_of_comments / params.row.number_of_reviews) * 10) / 10,
    // },
    // {
    //   field: "number_of_words",
    //   headerName: "Words/review",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: (params) => Math.round(params.row.number_of_words / params.row.number_of_reviews),
    // },
    {
      field: "average_pickup_time",
      headerName: "Reviewer pickup time",
      flex: 1,
      minWidth: 250,
      renderCell: (params) =>
        convertMillisecondsToReadableTime(params.row.average_pickup_time * 1000),
    },
  ];
  const [isDataPopupOpen, setIsDataPopupOpen] = useState(false);
  // State for the filter in the Data Popup
  const [filt, setFilt] = useState(null);

  // TODO: When sprint dates are more flexible, will also need to make this date filter more flexible as well
  const handleClickCard = (reviewerName, dateOnOrAfter) => {
    setFilt([
      {
        id: 1,
        columnField: "reviewer_name",
        operatorValue: "equals",
        value: reviewerName,
      },
      {
        id: 2,
        columnField: "first_review_submit_date",
        operatorValue: "onOrAfter",
        value: dateOnOrAfter,
      },
    ]);
    setIsDataPopupOpen(true);
  };

  const handleClose = () => {
    setIsDataPopupOpen(false);
    // Note: Can't set this to [], since need to 'flush' the value of the array with null for the data point filters to work
    // TODO: Figure out why this is the case, not 100% sure why this works this way
    setFilt(null);
  };

  return (
    <>
      <DataPopup
        title={description.title}
        definition={description.shortDefinition}
        icon={mdiGithub}
        isOpen={isDataPopupOpen}
        handleClose={handleClose}
      >
        <ReviewsDataGrid teamId={teamId} service={service} filtInput={filt} />
      </DataPopup>
      <SIDataGrid
        autoHeight
        sx={{
          py: 1,
          px: 2,
        }}
        columns={columns}
        rows={missionData}
        hideFooter
        onRowClick={(params) => handleClickCard(params.row.reviewer_name, currentDate)}
      />
    </>
  );
}

MissionDataGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
};
