import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useState, useEffect } from "react";
import { useApi } from "utils/apiUtils";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";

import MDTypography from "components/MDTypography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";

import {
  useEditingContext,
  EditingStates,
  getDraftCardSetting,
  editCardSetting,
  CardsEnum,
} from "components_si/EditingContext";
import BenchmarkIcon from "components_si/BenchmarkIcon";
import services from "utils/services";
import GraphTypeEnum from "layouts/projects/utils/GraphTypeEnum";
import GithubKpiCard from "./GithubKpiCard";

const CARD_DESCRIPTION = {
  title: "Review depth",
  shortDefinition: "# of comments on a pull request (PR) that has been reviewed and merged",
  longDefinition: (
    <>
      <MDTypography variant="body2" gutterBottom>
        The average number of comments on reviewed merged pull requests (PRs) that were opened
        within a project each week. Includes comments from both the PR author and reviewers.
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        We compare you to industry benchmarks:
        <Box px={2}>
          {/* TODO: Declare benhmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>S-tier: </strong>&gt; 5 comments per PR reviewed
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Average: </strong>3-5 comments per PR reviewed
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Poor: </strong> &lt; 3 comments per PR reviewed
            </ListItem>
          </List>
        </Box>
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        In depth reviews contribute to more learning across the team and lower defects.
      </MDTypography>
    </>
  ),
  benchmarkTooltip: {
    stier: "> 5 comments per PR reviewed",
    average: "3-5 comments per PR reviewed",
    poor: "< 3 comments per PR reviewed",
  },
  unit: "comments per review",
  unitSingular: "comment per review",
  highchartsTitle: "Average review depth",
};

/**
 * @param {*} jsonBlob - A data object that must have date_point_start, review_depth
 * @param {number} numPoints - Optional parameter, the number of data points to return. If not provided, returns all data points available in jsonBlob
 * @returns an object with an x- and a y-coordinate of length numPoints
 */
function processCardData(jsonBlob, numPoints) {
  if (jsonBlob === null) {
    return null;
  }

  const cardData = [];
  let jsonBlobTruncated = jsonBlob;

  if (numPoints !== undefined && numPoints < jsonBlob.length) {
    jsonBlobTruncated = jsonBlob.slice(jsonBlob.length - numPoints, jsonBlob.length);
  }

  const cardDataX = jsonBlobTruncated.map((x) => dayjs(x.date_point_start).valueOf());
  const cardDataY = jsonBlobTruncated.map((y) => parseFloat(y.review_depth));

  for (let i = 0; i < cardDataX.length; i++) {
    cardData[i] = [cardDataX[i], cardDataY[i]];
  }
  return cardData;
}

function processAggregateData(aggregateData) {
  const val = aggregateData?.[0]?.review_depth;

  if (val) {
    return parseFloat(val);
  }

  return val;
}

function processScatterData(data, prData, bucket) {
  const dates = processCardData(data).map((item) => item[0]);

  return dates.map((date, index) => {
    const prDataTransformed = prData
      .filter((item) => {
        const createdAt = dayjs(item.pr_created_at).valueOf(); // Convert to milliseconds
        const periodStart = dayjs(date).valueOf(); // Convert to milliseconds
        let periodEnd;
        if (bucket === "month") {
          periodEnd = dayjs(date).add(1, "month").valueOf(); // Add 1 month
        } else if (bucket === "week") {
          periodEnd = dayjs(date).add(7, "day").valueOf(); // Add 7 days
        } else {
          console.log("unsupported bucket size in PR size card - can only be weekly or monthly");
          // fall back to a week
          periodEnd = dayjs(date).add(7, "day").valueOf();
        }

        return (
          createdAt >= periodStart &&
          createdAt < periodEnd &&
          item.state === "merged" && // Additional filter for review depth card
          item.review_time_epoch !== "0.000000" // Additional filter for review depth card
        );
      })
      .map((item) => [index, item.pr_comments, item.pr_title]);

    return {
      name: date.toString(),
      data: prDataTransformed,
    };
  });
}

function KpiReviewDepthCard({ teamId, dateRange, bucket: bucketProp, graph: graphProp, service }) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  // TODO: should handle the null case as a loading state to prevent a flicker
  const [cardState, setCardState] = useState({ bucket: null, graph: null });
  useEffect(() => {
    setCardState({ bucket: bucketProp, graph: graphProp });
  }, [bucketProp, graphProp]);

  const getStateWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.ReviewDepth);
        return {
          ...cardState,
          ...settings,
        };
      }
      case EditingStates.Normal:
        return cardState;
      default:
        console.log("Invalid editing states mode");
        return cardState;
    }
  };

  const setStateWrapper = (newCardState) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        const settings = getDraftCardSetting(draft, CardsEnum.ReviewDepth);
        const newSettings = {
          ...cardState,
          ...settings,
          ...newCardState,
        };
        editCardSetting(dispatch, CardsEnum.ReviewDepth, newSettings);
        break;
      }
      case EditingStates.Normal: {
        setCardState({
          ...cardState,
          ...newCardState,
        });
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setCardState({
          ...cardState,
          ...newCardState,
        });
      }
    }
  };

  const [startDate, endDate] = dateRange;
  const encodedStartDate = encodeURIComponent(startDate.toISOString());
  const encodedEndDate = encodeURIComponent(endDate.toISOString());
  const { data } = useApi({
    url: `/api/teams/${teamId}/review_depth_avg?service=${service}&bucket=${
      getStateWrapper().bucket
    }&start=${encodedStartDate}&end=${encodedEndDate}`,
    defaultData: [],
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/review_depth_avg?service=${service}&bucket=all&start=${encodedStartDate}&end=${encodedEndDate}`,
    defaultData: [],
  });
  const { data: prData } = useApi({
    url: `/api/teams/${teamId}/prs_computed_table?service=${service}`,
    defaultData: [],
  });

  // TODO: Implement rounding precision as an optional parameter of the BasicKpiCard component. In this case, we'd want Math.round(foo * 100) / 100
  return (
    <GithubKpiCard
      cardType={CardsEnum.ReviewDepth}
      data={processCardData(data)}
      scatterData={processScatterData(data, prData, getStateWrapper().bucket)}
      bucket={getStateWrapper().bucket}
      graph={getStateWrapper().graph}
      onSelectBucket={(newBucket) => setStateWrapper({ bucket: newBucket })}
      onSelectGraph={(newGraph) => setStateWrapper({ graph: newGraph })}
      aggregateData={processAggregateData(aggregateData)}
      renderBenchmark={(dataPoint, numWeeks, dataPointIdx) => {
        if (dataPointIdx >= 0 && data[dataPointIdx].opened_prs_count === 0) {
          // The height of the actually filled out benchmark -- otherwise this would be null
          // TODO: Probably better to just make the graph stick to the bottom of the cell instead
          return <Box height={35.5} />;
        }
        return (
          <Box pt={1}>
            <BenchmarkIcon
              tier={evaluateTier(dataPoint, MetricsEnum.ReviewDepth, numWeeks)}
              tooltip={CARD_DESCRIPTION.benchmarkTooltip}
            />
          </Box>
        );
      }}
      graphDropdownOptions={[
        { label: "line", value: GraphTypeEnum.Line },
        { label: "scatter", value: GraphTypeEnum.Scatter },
      ]}
      description={CARD_DESCRIPTION}
      teamId={teamId}
      baseFilters={[
        {
          columnField: "state",
          operatorValue: "equals",
          value: "Merged",
        },
        {
          columnField: "review_time",
          operatorValue: "!=",
          value: "0",
        },
      ]}
      service={service}
      actualStartDate={startDate}
    />
  );
}

// Setting default values for the props
KpiReviewDepthCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
  graph: GraphTypeEnum.Line,
};

// Typechecking props
KpiReviewDepthCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
  graph: PropTypes.oneOf(Object.values(GraphTypeEnum)),
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
};

export default KpiReviewDepthCard;
