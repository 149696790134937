// Material Dashboard 2 React base styles
import colors from "assets/theme-dark/base/colors";

// Material Dashboard 2 React helper functions
// import rgba from "assets/theme-dark/functions/rgba";
import pxToRem from "assets/theme-dark/functions/pxToRem";

// const { dark, transparent, white } = colors;
const { background, border, transparent } = colors;

const divider = {
  styleOverrides: {
    root: {
      borderColor: border.light,
      // backgroundImage: `linear-gradient(to right, ${rgba(dark.main, 0)}, ${white.main}, ${rgba(
      //   dark.main,
      //   0
      // )}) !important`,
      height: pxToRem(1),
      margin: `${pxToRem(8)}`,
      // borderBottom: "none",
      // opacity: 0.5,
    },

    vertical: {
      backgroundColor: transparent.main,
      // backgroundImage: `linear-gradient(to bottom, ${rgba(dark.main, 0)}, ${white.main}, ${rgba(
      //   dark.main,
      //   0
      // )}) !important`,
      width: pxToRem(1),
      height: "100%",
      margin: `0 ${pxToRem(16)}`,
      borderRight: "none",
    },

    light: {
      backgroundColor: background.lines,
      // backgroundImage: `linear-gradient(to right, ${rgba(white.main, 0)}, ${rgba(
      //   dark.main,
      //   0.4
      // )}, ${rgba(white.main, 0)}) !important`,

      // "&.MuiDivider-vertical": {
      //   backgroundImage: `linear-gradient(to bottom, ${rgba(white.main, 0)}, ${rgba(
      //     dark.main,
      //     0.4
      //   )}, ${rgba(white.main, 0)}) !important`,
      // },
    },
  },
};

export default divider;
