/*eslint-disable*/

// @mui material components
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

export default styled(LinearProgress)(({ theme, valueBuffer, ownerState }) => {
  const { palette, functions } = theme;
  const { color, value, variant } = ownerState;

  const { text, gradients } = palette;
  const { linearGradient } = functions;

  // background value
  let backgroundValue;

  if (variant === "gradient") {
    backgroundValue = gradients[color]
      ? linearGradient(gradients[color].main, gradients[color].state)
      : linearGradient(gradients.info.main, gradients.info.state);
  } else {
    backgroundValue = palette[color] ? palette[color].main : palette.info.main;
  }

  return {
    height: 30,
    "& .MuiLinearProgress-bar": {
      height: 30,
      borderRadius: 5,
    },
    "& .MuiLinearProgress-bar1Buffer": {
      background: "#64a44c",
      width: `${value}%`,
      color: text.main,
    },
    "& .MuiLinearProgress-bar2Buffer": {
      background: "#aad19b",
      width: `${valueBuffer}%`,
      color: text.main,
    },
    "& .MuiLinearProgress-dashed": {
      backgroundColor: "lightgrey",
      backgroundImage: "none",
      animation: "none",
      borderRadius: 5,
    },
  };
});
