import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useApi } from "utils/apiUtils";

import SIDataGrid from "components_si/SIDataGridPro";
import services from "utils/services";

import { convertMillisecondsToReadableTime } from "utils/timeUtils";
import AuthorAvatarName from "components_si/AuthorAvatarName";

// possible anchor columns
const ANCHOR_COLUMNS = ["created_at"];

export default function DeploymentsDataGrid({
  teamId,
  filtInput,
  anchorColumn,
  sortField,
  service,
}) {
  const { data } = useApi({
    url: `/api/teams/${teamId}/deployments?service=${service}`,
    defaultData: [],
  });

  const columns = [
    {
      field: "username",
      headerName: "Triggerer",
      flex: 1,
      renderCell: (params) => (
        <AuthorAvatarName author={params.row.username} authorAvatarUrl={params.row.avatar_url} />
      ),
    },
    {
      field: "created_at",
      headerName: "Date created",
      flex: 1,
      type: "date",
      minWidth: 180,
      maxWidth: 200,
      renderCell: (params) =>
        params.row.created_at !== null
          ? dayjs(params.row.created_at).format("YYYY-MM-DD [at] HH:mm")
          : "",
    },
    {
      field: "group_name",
      headerName: "Group",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "project_name",
      headerName: "Project",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "environment_name",
      headerName: "Environment",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "environment_tier",
      headerName: "Environment Tier",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "commit_title",
      headerName: "Commit title",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "queued_duration",
      headerName: "Queued duration",
      flex: 1,
      minWidth: 180,
      maxWidth: 200,
      renderCell: (params) => convertMillisecondsToReadableTime(params.row.queued_duration * 1000),
    },
    {
      field: "deployment_started_at",
      headerName: "Date started",
      flex: 1,
      type: "date",
      minWidth: 180,
      maxWidth: 200,
      renderCell: (params) =>
        params.row.deployment_started_at !== null
          ? dayjs(params.row.deployment_started_at).format("YYYY-MM-DD [at] HH:mm")
          : "",
    },
    {
      field: "deployment_finished_at",
      headerName: "Date finished",
      flex: 1,
      type: "date",
      minWidth: 180,
      maxWidth: 200,
      renderCell: (params) =>
        params.row.deployment_finished_at !== null
          ? dayjs(params.row.deployment_finished_at).format("YYYY-MM-DD [at] HH:mm")
          : "",
    },
    {
      field: "deployment_duration",
      headerName: "Deployment duration",
      flex: 1,
      minWidth: 180,
      maxWidth: 200,
      renderCell: (params) =>
        convertMillisecondsToReadableTime(params.row.deployment_duration * 1000),
    },
  ];

  return (
    <SIDataGrid
      showExportAndFilter
      columns={columns}
      rows={data}
      onRowClick={(params) => window.open(params.row.web_url)}
      initialState={{
        columns: {
          // Hide the unselected anchor columns
          columnVisibilityModel: ANCHOR_COLUMNS.filter((val) => val !== anchorColumn).reduce(
            (acc, val) => {
              acc[val] = false;
              return acc;
            },
            {
              project_name: false,
              group_name: false,
              deployment_started_at: false,
              deployment_finished_at: false,
              environment_name: false,
            }
          ),
        },
        sorting: {
          sortModel: sortField ? [{ field: sortField, sort: "asc" }] : [],
        },
        filter: {
          filterModel: {
            items: filtInput,
            // logicOperator: GridLogicOperator.Or,
          },
        },
      }}
      sx={{ border: 0 }}
      getRowId={(row) => row.id}
    />
  );
}

DeploymentsDataGrid.defaultProps = {
  filtInput: [],
  anchorColumn: undefined,
  sortField: undefined,
};

DeploymentsDataGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
  filtInput: PropTypes.arrayOf(PropTypes.object),
  anchorColumn: PropTypes.oneOf(ANCHOR_COLUMNS),
  sortField: PropTypes.string,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
};
