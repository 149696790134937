import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";

import MDTypography from "components/MDTypography";

export default function DataPopup({ title, definition, icon, isOpen, handleClose, children }) {
  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={handleClose}>
        <DialogTitle sx={{ m: 0, pt: 2, pb: 0 }}>
          <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
            <SvgIcon color="light" fontSize="medium">
              <path d={icon} />
            </SvgIcon>
            <Box>{title}</Box>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box sx={{ pl: 2 }}>
          <MDTypography variant="subtitle1">{definition}</MDTypography>
        </Box>
        <DialogContent sx={{ pt: 1 }}>{children}</DialogContent>
      </Dialog>
    </div>
  );
}

DataPopup.defaultProps = {
  definition: "",
  isOpen: "false",
};

DataPopup.propTypes = {
  title: PropTypes.string.isRequired,
  definition: PropTypes.string,
  icon: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
