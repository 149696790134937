// Base Styles
import colors from "assets/theme-dark/base/colors";

const { text } = colors;

const pickersDay = {
  styleOverrides: {
    root: {
      color: text.main, // This is the color of the numbered dates on the datePicker components
    },
  },
};

export default pickersDay;
