/**
 * Layout for the team view
 */

// import { track } from "@amplitude/analytics-browser";
import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import durationPlugin from "dayjs/plugin/duration";

import darkModeColors from "assets/theme-dark/base/colors";

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { IconButton, Button } from "@mui/material";
import Icon from "@mui/material/Icon";
// import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Overview from "layouts/projects/components/Overview";
import AtRiskTicketsTable from "layouts/projects/components/AtRiskTicketsTable";
import AtRiskPRsTable from "layouts/projects/components/AtRiskPRsTable";
import ProjectActivityLog from "layouts/projects/components/ProjectActivityLog";
// import RowKeyRisks from "layouts/projects/components/RowKeyRisks";

import KpiIssuesCompletedCard from "layouts/projects/components/cards/KpiIssuesCompletedCard";
import KpiIssueCycleTimeCard from "layouts/projects/components/cards/KpiIssueCycleTimeCard";
import KpiPercentIssuesCompletedCard from "layouts/projects/components/cards/KpiPercentIssuesCompletedCard";
import KpiReviewCard from "layouts/projects/components/cards/KpiReviewCard";
import KpiPrCard from "layouts/projects/components/cards/KpiPrCard";
import KpiPrSizeCard from "layouts/projects/components/cards/KpiPrSizeCard";
import KpiNoReviewsCard from "layouts/projects/components/cards/KpiNoReviewsCard";
import KpiReviewDepthCard from "layouts/projects/components/cards/KpiReviewDepthCard";
import CycleTimeCard from "layouts/projects/components/cards/CycleTimeCard";
import KpiDeploymentsCard from "layouts/projects/components/cards/KpiDeploymentsCard";

import { useApi, useApiFetch } from "utils/apiUtils";
import TeamAvatarName from "components_si/TeamAvatarName";
import Subtitle from "components_si/Subtitle";
import {
  EditingContextProvider,
  useEditingContext,
  CardsEnum,
  EditingStates,
  setIsEditing,
  discardDraft,
  startSaving,
  setDoneSaving,
} from "components_si/EditingContext";
import { useMaterialUIController } from "context";
import pxToRem from "assets/theme/functions/pxToRem";

import serviceEnums from "utils/services";

const { text, border, glass } = darkModeColors;

dayjs.extend(isBetweenPlugin);
dayjs.extend(durationPlugin);
const now = dayjs();
// These functions are used to calculate if a last 30, 60, or 90 days button should be 'selected' (i.e., purple background). This works if both: i) The button is clicked, or ii) The last 30, 60, or 90 days is selected via the calendar interface
function areDatesSameDay(date1, date2) {
  const date1WithoutTime = date1.startOf("day");
  const date2WithoutTime = date2.startOf("day");
  return date1WithoutTime.isSame(date2WithoutTime);
}

function isDesiredDifferenceDaysAndToday(start, end, desiredDifference) {
  const startWithoutTime = start.startOf("day");
  const endWithoutTime = end.startOf("day");

  const differenceInDays = Math.round(
    dayjs.duration(endWithoutTime.diff(startWithoutTime)).asDays()
  ); // Rounding since sometimes off by one hour due to daylight savings time

  return differenceInDays === desiredDifference && areDatesSameDay(end, now);
}

const ViewStateEnum = { Risks: 0, Velocity: 1, Quality: 2 };

// if a date range is invalid, set to default
function getValidDateRange(rangeToValidate) {
  let [startDate, endDate] = rangeToValidate;

  // Set start date to 90 days before today
  if (startDate === null || startDate === undefined || !startDate.isValid()) {
    startDate = now.subtract(90, "day").startOf("day");
  }

  if (endDate === null || endDate === undefined || !endDate.isValid()) {
    endDate = now.endOf("day");
  }

  const validatedRange = [startDate, endDate];

  return validatedRange;
}

function ProjectsInner() {
  const [viewStates, setViewStates] = useState(Array(Object.keys(ViewStateEnum).length).fill(true));
  const [draft, dispatch] = useEditingContext();
  const { mode, settings } = draft;
  const isShowingAll = viewStates.reduce((accumulator, isOn) => accumulator && isOn, true);
  const isShowingRisks = viewStates[ViewStateEnum.Risks];
  const isShowingVelocity = viewStates[ViewStateEnum.Velocity];
  const isShowingQuality = viewStates[ViewStateEnum.Quality];
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  // *** DATE RANGE LOGIC ***
  const [searchParams, setSearchParams] = useSearchParams();

  const [dateRange, setDateRange] = useState([
    searchParams.get("start") == null
      ? now.subtract(90, "day").startOf("day")
      : dayjs(searchParams.get("start")).startOf("day"),
    searchParams.get("end") == null
      ? now.endOf("day")
      : dayjs(searchParams.get("end")).endOf("day"),
  ]);

  const setDateRangeWrapper = (range) => {
    const [currentStartDate, currentEndDate] = dateRange;

    const validatedRange = getValidDateRange(range);
    setDateRange(validatedRange);

    // If the end date has never been set, leave it unset
    if (currentEndDate.isSame(validatedRange[1]) && searchParams.get("end") == null) {
      setSearchParams({ start: validatedRange[0].format("YYYY-MM-DD") });
      // If the start date has never been set, leave it unset
    } else if (currentStartDate.isSame(validatedRange[0]) && searchParams.get("start") == null) {
      setSearchParams({ end: validatedRange[1].format("YYYY-MM-DD") });
    } else {
      // TODO: this is kind of weird, we should really be using the setting of "-30 days" etc
      // to figure out if we want to set the search parameters
      // It's weird because we aren't using the validated date range because we want to use
      // null as a way to indicate "until today".
      setSearchParams(
        range[1] == null
          ? { start: validatedRange[0].format("YYYY-MM-DD") }
          : {
              start: validatedRange[0].format("YYYY-MM-DD"),
              end: validatedRange[1].format("YYYY-MM-DD"),
            }
      );
    }
  };

  const setDateShortcut = (numberDaysPrior) => {
    // set null to indicate until today
    // TODO: this is kind of weird, we should really be using the setting of "-30 days" etc
    // to figure out if we want to set the search parameters
    setDateRangeWrapper([now.subtract(numberDaysPrior, "day").startOf("day"), null]);
  };

  // *** FETCH DATA ***
  const navigate = useNavigate();
  const { teamId: urlParamTeamId } = useParams();
  const { data: projectData, error: projectDataFetchError } = useApi({
    url: `/api/teams/${urlParamTeamId}`,
    defaultData: undefined,
    dependencies: [urlParamTeamId],
  });

  const { data: viewSettingsData, refresh: refreshViewSettingsData } = useApi({
    url: `/api/teams/${urlParamTeamId}/views/dashboard`,
    dependencies: [urlParamTeamId],
  });

  const { data: services } = useApi({
    url: projectData === null ? undefined : `/api/teams/${projectData.team_id}/integrations`,
    defaultData: null,
    dependencies: [projectData],
  });

  const { team_id: teamId, team_name: teamName, avatar_color: avatarColor } = projectData || {};

  const { apiFetch } = useApiFetch();
  useEffect(() => {
    (async () => {
      if (mode === EditingStates.Saving) {
        await apiFetch(`/api/teams/${teamId}/views/dashboard`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(settings),
        });

        setDoneSaving(dispatch);
        refreshViewSettingsData();
      }
    })();
  }, [mode]);

  // *** HANDLE NULL STATES **
  if (projectDataFetchError !== null) {
    // This automatically redirects to the first dashboard team
    navigate("/authentication/sign-in");
  }

  if (projectData === null || viewSettingsData === null || services === null) {
    return <DashboardLayout>Loading...</DashboardLayout>;
  }

  if (Object.keys(projectData).length === 0) {
    return <DashboardLayout>We&apos;ll let you know when your data is ready!</DashboardLayout>;
  }

  // *** CHIPS TO NAVIGATE DASHBOARD (All, Risks, Velocity, Quality) ***
  const toggleAll = () => {
    if (isShowingAll) {
      setViewStates(Array(Object.keys(ViewStateEnum).length).fill(false));
    } else {
      setViewStates(Array(Object.keys(ViewStateEnum).length).fill(true));
    }
  };

  const toggleViewState = (viewEnum) => {
    if (isShowingAll) {
      const allHiddenExceptGivenView = Array(Object.keys(ViewStateEnum).length).fill(false);
      allHiddenExceptGivenView[viewEnum] = true;
      setViewStates(allHiddenExceptGivenView);
    } else {
      const current = [...viewStates];
      current[viewEnum] = !current[viewEnum];
      setViewStates(current);
    }
  };

  const toggleButtons = Object.entries(ViewStateEnum).map(([viewName, idx]) => {
    const toggleButtonFilled = viewStates[idx] && !isShowingAll;
    return (
      <Chip
        size="small"
        color={toggleButtonFilled ? "primary" : "secondary"}
        variant={toggleButtonFilled ? "filled" : "outlined"}
        label={viewName}
        key={viewName}
        onClick={() => toggleViewState(idx)}
      />
    );
  });

  // *** JSX for header component (title, chips to navigate, date range, shortcuts on date range) ***

  const header = (
    <Grid container>
      {mode === EditingStates.Editing && (
        <>
          <Toolbar
            style={{
              backgroundColor: glass.background,
              border: glass.border,
              filter: glass.filter,
              backdropFilter: glass.backdropFilter,
              borderRadius: "10px 10px 0px 0px",
              position: "fixed",
              bottom: 0,
              width: miniSidenav
                ? pxToRem(window.innerWidth - 82)
                : pxToRem(window.innerWidth - 250),
              transition: "0.3s",
              zIndex: "99",
            }}
          />
          <Toolbar
            style={{
              position: "fixed",
              bottom: 0,
              width: miniSidenav
                ? pxToRem(window.innerWidth - 82)
                : pxToRem(window.innerWidth - 250),
              transition: "0.3s",
              zIndex: "100",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MDTypography>Editing default view</MDTypography>
            <Box
              sx={{
                flexGrow: 1,
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: pxToRem(180),
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  width: pxToRem(80),
                }}
                onClick={() => {
                  discardDraft(dispatch);
                }}
              >
                <MDTypography variant="h4">Discard</MDTypography>
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: pxToRem(80),
                  boxShadow: "none !important",
                }}
                onClick={() => {
                  startSaving(dispatch);
                }}
              >
                <MDTypography variant="h4">Save</MDTypography>
              </Button>
            </Box>
          </Toolbar>
        </>
      )}
      <Grid
        item
        xs={12}
        sx={{
          pt: 3.25,
          pl: 3,
          borderLeft: 0.5,
          borderBottom: 0.5,
          borderColor: border.light,
        }}
      >
        <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={2}>
          <TeamAvatarName teamName={teamName} avatarColor={avatarColor} />
          <MDTypography variant="h2">{teamName} &gt; Dashboard</MDTypography>
          {mode !== EditingStates.Editing && (
            <IconButton onClick={() => setIsEditing(dispatch)}>
              <Icon>edit</Icon>
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ pr: 3, pt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                localeText={{ start: "start", end: "end" }}
                format="MMM D, YYYY"
                sx={{ width: "290px" }}
                disableFuture
                value={dateRange}
                onChange={(newValue) => {
                  setDateRangeWrapper(newValue);
                }}
              />
            </LocalizationProvider>
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ py: 2, alignItems: "center" }}>
          {isShowingAll ? (
            <Chip size="small" color="primary" label="All" onClick={toggleAll} />
          ) : (
            <Chip
              size="small"
              variant="outlined"
              color="secondary"
              label="All"
              onClick={toggleAll}
            />
          )}
          {toggleButtons}
          <Box sx={{ flexGrow: 1 }} />
          {/* TODO: This is a bit hacky. Make more standard when we confirm design */}
          <MDTypography fontSize="13px" sx={{ color: text.subtitle }}>
            last
          </MDTypography>
          <Chip
            size="small"
            variant={
              isDesiredDifferenceDaysAndToday(dateRange[0], dateRange[1], 30)
                ? "filled"
                : "outlined"
            }
            color={
              isDesiredDifferenceDaysAndToday(dateRange[0], dateRange[1], 30)
                ? "primary"
                : "secondary"
            }
            label="30"
            onClick={() => {
              setDateShortcut(30);
            }}
          />
          <Chip
            size="small"
            variant={
              isDesiredDifferenceDaysAndToday(dateRange[0], dateRange[1], 60)
                ? "filled"
                : "outlined"
            }
            color={
              isDesiredDifferenceDaysAndToday(dateRange[0], dateRange[1], 60)
                ? "primary"
                : "secondary"
            }
            label="60"
            onClick={() => {
              setDateShortcut(60);
            }}
          />
          <Chip
            size="small"
            variant={
              isDesiredDifferenceDaysAndToday(dateRange[0], dateRange[1], 90)
                ? "filled"
                : "outlined"
            }
            color={
              isDesiredDifferenceDaysAndToday(dateRange[0], dateRange[1], 90)
                ? "primary"
                : "secondary"
            }
            label="90"
            onClick={() => {
              setDateShortcut(90);
            }}
          />
          {/* TODO: This is a bit hacky. Make more standard when we confirm design. Padding to align with the dateRangePicker */}
          <MDTypography fontSize="13px" sx={{ color: text.subtitle, paddingRight: 3 }}>
            days
          </MDTypography>
        </Stack>
      </Grid>
    </Grid>
  );

  let effectiveGitService;
  if (services.includes("github")) {
    effectiveGitService = serviceEnums.GitServiceEnum.Github;
  } else if (services.includes("gitlab")) {
    effectiveGitService = serviceEnums.GitServiceEnum.Gitlab;
  }

  return (
    <DashboardLayout>
      {header}
      {/* TODO: Format this in a more reasonable way when ready to turn light mode back on - currently just sticks out on the page */}
      {services.includes("shortcut") && <Overview teamId={teamId} />}
      {/* TODO: Build out key risks and insights in the future. Commented out since will not be included in v1 of release to David */}
      {/* Key Risks and Insights
      <RowKeyRisks />
      <Divider /> */}
      {/* At Risk Tickets */}
      {services.includes("shortcut") && (
        <Grid container>
          <Grid item xs={12}>
            <MDTypography variant="h3">At Risk Tickets</MDTypography>
          </Grid>
          <Grid item xs={12}>
            <AtRiskTicketsTable teamId={teamId} />
          </Grid>
        </Grid>
      )}
      {isShowingRisks && (services.includes("github") || services.includes("gitlab")) && (
        <Grid container>
          <Grid item xs={12}>
            <AtRiskPRsTable teamId={teamId} service={effectiveGitService} dateRange={dateRange} />
          </Grid>
        </Grid>
      )}
      {/* Lighthouse metrics */}
      {/* Velocity */}
      {isShowingVelocity && (
        <Grid container>
          <Grid item xs={12}>
            <Subtitle>Velocity</Subtitle>
          </Grid>
          {(services.includes("github") || services.includes("gitlab")) && (
            <>
              <Grid item xs={12}>
                <CycleTimeCard
                  teamId={teamId}
                  service={effectiveGitService}
                  dateRange={dateRange}
                  bucket={viewSettingsData?.cards?.[CardsEnum.CycleTime]?.bucket}
                />
              </Grid>
              <Grid item xs={services.includes("gitlab") ? 4 : 6}>
                <KpiPrCard
                  teamId={teamId}
                  service={effectiveGitService}
                  dateRange={dateRange}
                  bucket={viewSettingsData?.cards?.[CardsEnum.PrsOpened]?.bucket}
                />
              </Grid>
              <Grid item xs={services.includes("gitlab") ? 4 : 6}>
                <KpiPrSizeCard
                  teamId={teamId}
                  service={effectiveGitService}
                  dateRange={dateRange}
                  bucket={viewSettingsData?.cards?.[CardsEnum.PrSize]?.bucket}
                  graph={viewSettingsData?.cards?.[CardsEnum.PrSize]?.graph}
                />
              </Grid>
              {services.includes("gitlab") && ( // only supports gitlab deployments for now
                <Grid item xs={4}>
                  <KpiDeploymentsCard
                    teamId={teamId}
                    service={effectiveGitService}
                    dateRange={dateRange}
                    bucket={viewSettingsData?.cards?.[CardsEnum.DeploymentsCreated]?.bucket}
                  />
                </Grid>
              )}
            </>
          )}
          {services.includes("jira") ? (
            <>
              <Grid item xs={4}>
                <KpiIssuesCompletedCard
                  teamId={teamId}
                  dateRange={dateRange}
                  bucket={viewSettingsData?.cards?.[CardsEnum.IssuesCompleted]?.bucket}
                  points={viewSettingsData?.cards?.[CardsEnum.IssuesCompleted]?.points}
                />
              </Grid>
              <Grid item xs={4}>
                <KpiIssueCycleTimeCard
                  teamId={teamId}
                  dateRange={dateRange}
                  bucket={viewSettingsData?.cards?.[CardsEnum.IssueCycleTime]?.bucket}
                />
              </Grid>
              <Grid item xs={4}>
                <KpiPercentIssuesCompletedCard
                  teamId={teamId}
                  dateRange={dateRange}
                  bucket={viewSettingsData?.cards?.[CardsEnum.IssuesCompletedPercent]?.bucket}
                  points={viewSettingsData?.cards?.[CardsEnum.IssuesCompletedPercent]?.points}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      )}
      {/* Quality */}
      {isShowingQuality && (services.includes("github") || services.includes("gitlab")) && (
        <Grid container>
          <Grid item xs={12}>
            <Subtitle>Quality</Subtitle>
          </Grid>
          <Grid item xs={4}>
            <KpiReviewCard
              teamId={teamId}
              service={effectiveGitService}
              dateRange={dateRange}
              bucket={viewSettingsData?.cards?.[CardsEnum.Reviews]?.bucket}
            />
          </Grid>
          <Grid item xs={4}>
            <KpiNoReviewsCard
              teamId={teamId}
              service={effectiveGitService}
              dateRange={dateRange}
              bucket={viewSettingsData?.cards?.[CardsEnum.MergedWithoutReview]?.bucket}
            />
          </Grid>
          <Grid item xs={4}>
            <KpiReviewDepthCard
              teamId={teamId}
              service={effectiveGitService}
              dateRange={dateRange}
              bucket={viewSettingsData?.cards?.[CardsEnum.ReviewDepth]?.bucket}
              graph={viewSettingsData?.cards?.[CardsEnum.ReviewDepth]?.graph}
            />
          </Grid>
        </Grid>
      )}
      {/* Activity Log */}
      {services.includes("shortcut") && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="h3">Project Activity</MDTypography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={9}>
              <ProjectActivityLog teamId={teamId} />
            </Grid>
          </Grid>
        </>
      )}
      {mode === EditingStates.Editing && (
        <Toolbar
          style={{
            bottom: 0,
          }}
        />
      )}
    </DashboardLayout>
  );
}

function Projects() {
  return (
    <EditingContextProvider>
      <ProjectsInner />
    </EditingContextProvider>
  );
}

export default Projects;
