// Note: these SVG icons need to be set with a viewBox="0 0 41 41"
// TODO: Check if easy not to have this constraint? Doesn't matter much though...
const benchmarkIconPaths = {
  trophy:
    "M32.3418 0.269531C30.5418 0.269531 28.3418 2.26953 28.3418 4.26953H12.3418C12.3418 2.26953 10.1418 0.269531 8.3418 0.269531H0.341797V18.2695C0.341797 20.2695 2.3418 22.2695 4.3418 22.2695H8.7418C9.5418 26.2695 12.1418 29.6695 18.3418 30.2695V34.4295C12.3418 35.3495 12.3418 40.2695 12.3418 40.2695H28.3418C28.3418 40.2695 28.3418 35.3495 22.3418 34.4295V30.2695C28.5418 29.6695 31.1418 26.2695 31.9418 22.2695H36.3418C38.3418 22.2695 40.3418 20.2695 40.3418 18.2695V0.269531H32.3418ZM8.3418 18.2695H4.3418V4.26953H8.3418V18.2695ZM36.3418 18.2695H32.3418V4.26953H36.3418V18.2695Z",
  alert:
    "M18.2344 26.4844H22.2344V30.4844H18.2344V26.4844ZM18.2344 10.4844H22.2344V22.4844H18.2344V10.4844ZM20.2344 0.484375C9.17437 0.484375 0.234375 9.48438 0.234375 20.4844C0.234375 25.7887 2.34151 30.8758 6.09224 34.6265C7.94941 36.4837 10.1542 37.9569 12.5807 38.962C15.0072 39.9671 17.6079 40.4844 20.2344 40.4844C25.5387 40.4844 30.6258 38.3772 34.3765 34.6265C38.1272 30.8758 40.2344 25.7887 40.2344 20.4844C40.2344 17.8579 39.7171 15.2572 38.712 12.8307C37.7069 10.4042 36.2337 8.19941 34.3765 6.34224C32.5193 4.48507 30.3146 3.01188 27.888 2.00678C25.4615 1.00169 22.8608 0.484375 20.2344 0.484375ZM20.2344 36.4844C15.9909 36.4844 11.9212 34.7987 8.92067 31.7981C5.92008 28.7975 4.23438 24.7278 4.23438 20.4844C4.23438 16.2409 5.92008 12.1712 8.92067 9.17067C11.9212 6.17008 15.9909 4.48438 20.2344 4.48438C24.4778 4.48438 28.5475 6.17008 31.5481 9.17067C34.5487 12.1712 36.2344 16.2409 36.2344 20.4844C36.2344 24.7278 34.5487 28.7975 31.5481 31.7981C28.5475 34.7987 24.4778 36.4844 20.2344 36.4844Z",
  fire: "M28.6219 18.4932C28.1111 17.8266 27.4893 17.2488 26.9119 16.671C25.4241 15.3376 23.7363 14.382 22.3151 12.982C19.0062 9.73745 18.2734 4.38174 20.383 0.270508C18.2734 0.781634 16.4302 1.93722 14.8535 3.20393C9.10184 7.82628 6.83671 15.9821 9.54598 22.9823C9.63481 23.2045 9.72363 23.4267 9.72363 23.7156C9.72363 24.2045 9.39053 24.649 8.94639 24.8268C8.43562 25.049 7.90265 24.9157 7.48072 24.5601C7.34747 24.449 7.25865 24.3379 7.16982 24.1823C4.66042 21.0044 4.26069 16.4487 5.94843 12.8042C2.23984 15.8265 0.218995 20.9378 0.507687 25.7601C0.64093 26.8713 0.774173 27.9824 1.15169 29.0936C1.46259 30.4269 2.06218 31.7603 2.7284 32.9381C5.12676 36.7827 9.27949 39.5383 13.7431 40.0939C18.4954 40.6939 23.5809 39.8272 27.2228 36.5382C31.2867 32.8492 32.708 26.9379 30.6205 21.8711L30.3318 21.2933C29.8655 20.2711 28.6219 18.4932 28.6219 18.4932ZM21.6044 32.4937C20.9826 33.027 19.9611 33.6048 19.1617 33.827C16.6745 34.7159 14.1873 33.4715 12.7216 32.0047C15.3642 31.3825 16.9409 29.4269 17.4073 27.4491C17.7848 25.6712 17.0742 24.2045 16.7855 22.4934C16.519 20.8489 16.5634 19.4488 17.163 17.9155C17.5849 18.7599 18.0291 19.6044 18.5621 20.2711C20.272 22.4934 22.9591 23.4712 23.5365 26.4935C23.6253 26.8046 23.6697 27.1157 23.6697 27.4491C23.7363 29.2713 22.9369 31.2714 21.6044 32.4937ZM40.4805 22.4934H36.0391V9.15965H40.4805V22.4934ZM40.4805 31.3825H36.0391V26.9379H40.4805V31.3825Z",
};

export default benchmarkIconPaths;
