import PropTypes from "prop-types";

import darkModeColors from "assets/theme-dark/base/colors";

import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

const { background, border } = darkModeColors;

function Subtitle({ children }) {
  return (
    <Box
      sx={{
        py: 1,
        px: 3,
        borderLeft: 0.5,
        backgroundColor: background.subtitle,
        borderColor: border.light,
      }}
    >
      <MDTypography color="info" variant="h3">
        {children}
      </MDTypography>
    </Box>
  );
}

Subtitle.defaultProps = {
  children: "",
};

Subtitle.propTypes = {
  children: PropTypes.string,
};

export default Subtitle;
