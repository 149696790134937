import PropTypes from "prop-types";
// import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import darkModeColors from "assets/theme-dark/base/colors";

import KpiReviewCard from "layouts/projects/components/cards/KpiReviewCard";
import KpiPickupTimeCard from "layouts/projects/components/cards/KpiPickupTimeCard";
import MissionOverview from "layouts/missions/ReviewMission/components/MissionOverview";
import MissionDataGrid from "layouts/missions/ReviewMission/components/MissionDataGrid";
import TeamAvatarName from "components_si/TeamAvatarName";
import Subtitle from "components_si/Subtitle";
import services from "utils/services";
import dayjs from "dayjs";

const { border } = darkModeColors;

function ReviewMission({ teamId, service, teamName, avatarColor, configuration }) {
  return (
    <DashboardLayout>
      <Box
        sx={{
          pt: 3.25,
          pb: 3.25,
          pl: 2,
          borderBottom: 0.5,
          borderLeft: 0.5,
          borderColor: border.light,
        }}
      >
        <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={2}>
          <TeamAvatarName teamName={teamName} avatarColor={avatarColor} />
          <MDTypography variant="h2">{teamName} &gt; Mission &gt; Reduce pickup time</MDTypography>
        </Stack>
      </Box>
      <MissionOverview />
      <Grid container>
        <Grid item xs={12}>
          <Subtitle>Week to date</Subtitle>
        </Grid>
        <Grid item xs={12}>
          <MissionDataGrid
            teamId={teamId}
            service={service}
            startDate={dayjs(configuration.startDate).format("YYYY-MM-DD")}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Subtitle>Team results</Subtitle>
        </Grid>
        <Grid item xs={6}>
          <KpiReviewCard teamId={teamId} service={service} />
        </Grid>
        <Grid item xs={6}>
          <KpiPickupTimeCard teamId={teamId} service={service} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

// Typechecking props
ReviewMission.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  teamName: PropTypes.string.isRequired,
  avatarColor: PropTypes.string.isRequired,
  configuration: PropTypes.exact({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    target: PropTypes.number,
  }).isRequired,
};

export default ReviewMission;
