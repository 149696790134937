// TODO: Delete after At Risk Tickets table is migrated to new design

import PropTypes from "prop-types";

function RiskItem({ title, url }) {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {title}
    </a>
  );
}

RiskItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default RiskItem;
