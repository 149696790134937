import PropTypes from "prop-types";

import Avatar from "@mui/material/Avatar";

import darkModeColors from "assets/theme-dark/base/colors";

const { text } = darkModeColors;

// TODO: Could be a standard way to do this wrapper:
// https://mui.com/material-ui/guides/composition/
function TeamAvatarName({ teamName, avatarColor, ...rest }) {
  const { sx, ...restExceptsx } = rest;

  return (
    // Get up to the first two characters in the teamName to make an avatar acronym.
    <Avatar
      variant="rounded"
      sx={{ color: text.title, bgcolor: avatarColor, ...sx }}
      {...restExceptsx}
    >
      {teamName
        .match(/\b(\w)/g)
        .join("")
        .toUpperCase()
        .slice(0, 2)}
    </Avatar>
  );
}

TeamAvatarName.propTypes = {
  teamName: PropTypes.string.isRequired,
  avatarColor: PropTypes.string.isRequired,
};

export default TeamAvatarName;
