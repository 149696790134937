import { MILLISECONDS_IN_A_DAY } from "utils/timeUtils";
import { Risk, getOverallRiskDriver } from "./riskUtils";

// Warning risk level when you are this number of days towards the deadline
// TODO: this is a bit weird w/o an understanding of how long the task is supposed to take
// if the estimate is in story points instead of days.
const DAYS_TO_DEADLINE_THRESHOLD = 1;
// After this many days in progress, we raise the risk level
const DAYS_IN_PROGRESS_THRESHOLD = 3;
// After this many days without an update, we raise the risk level
const DAYS_SINCE_LAST_UPDATE_THRESHOLD = 3;
// After a certain story point size, we put up a warning that the task might be
// risky
const ESTIMATE_THRESHOLD = 5;

/**
 * @param {*} deadline - a string date that can be undefined / null. We don't
 *   pass in a Date object here because we want to check if it's undefined/null
 *   first.
 * @param {*} now - The Date we calculate risk against. We pass in now so that
 *   it's accurate against a time used for other calculations.
 * @returns an object with text and riskLevel
 */
function computeDeadlineRisk(deadline, now, threshold = DAYS_TO_DEADLINE_THRESHOLD) {
  let riskInfo;
  if (deadline === undefined || deadline === null) {
    riskInfo = { text: "No deadline", riskLevel: Risk.Unknown };
  } else {
    const deadlineDate = new Date(deadline);
    // can be negative (meaning they missed the deadline)
    const daysToDeadline = (deadlineDate - now) / MILLISECONDS_IN_A_DAY;
    const prettyPrintDays = Math.floor(Math.abs(daysToDeadline));
    // It looks weird to say "0 days to deadline" when we round so instead we
    // judge based on the pretty print days here
    // TODO: consider using convertMillisecondsToReadableTime instead, but
    // will require making that return the number value to do this check and not
    // sure if it handles negative.
    if (prettyPrintDays === 0) {
      riskInfo = {
        text: "Today is the deadline",
        riskLevel: Risk.Warning,
      };
    } else if (daysToDeadline < 0) {
      riskInfo = {
        text: `${prettyPrintDays} day${prettyPrintDays === 1 ? "" : "s"} past deadline`,
        riskLevel: Risk.Critical,
      };
    } else if (daysToDeadline <= threshold) {
      riskInfo = {
        text: `Deadline in ${prettyPrintDays} day${prettyPrintDays === 1 ? "" : "s"}`,
        riskLevel: Risk.Warning,
      };
    } else {
      riskInfo = {
        text: "On track for deadline",
        riskLevel: Risk.Good,
      };
    }
  }
  return riskInfo;
}

function computeDaysInProgressRisk(movedAt, now, threshold = DAYS_IN_PROGRESS_THRESHOLD) {
  const daysInProgress = (now - movedAt) / MILLISECONDS_IN_A_DAY;
  // assert(daysInProgress > 0);
  const prettyPrintDays = Math.round(daysInProgress);
  let riskInfo;
  if (daysInProgress >= threshold) {
    riskInfo = {
      text: `${prettyPrintDays} day${prettyPrintDays === 1 ? "" : "s"} in progress`,
      riskLevel: Risk.Warning,
    };
  } else {
    riskInfo = {
      text: `${prettyPrintDays} day${prettyPrintDays === 1 ? "" : "s"} in progress`,
      riskLevel: Risk.Good,
    };
  }
  return riskInfo;
}

/**
 * This considers any update (or at least any update as considered by shortcut).
 * We may want to change this to be a subset of updates that we consider
 * to be meaningful (e.g., only updates by one of the owners)
 * @param {*} lastUpdate
 * @param {*} now
 * @returns an object with text and riskLevel
 */
function computeDaysSinceLastUpdateRisk(
  lastUpdate,
  now,
  threshold = DAYS_SINCE_LAST_UPDATE_THRESHOLD
) {
  let riskInfo;
  const daysSinceLastUpdate = (now - lastUpdate) / MILLISECONDS_IN_A_DAY;
  const prettyPrintDays = Math.round(daysSinceLastUpdate);
  if (daysSinceLastUpdate >= threshold) {
    riskInfo = {
      text: `${prettyPrintDays} day${prettyPrintDays === 1 ? "" : "s"} since last update`,
      riskLevel: Risk.Unknown,
    };
  } else {
    riskInfo = {
      text: `${prettyPrintDays} day${prettyPrintDays === 1 ? "" : "s"} since last update`,
      riskLevel: Risk.Good,
    };
  }

  return riskInfo;
}

function computeEstimateRisk(estimate, threshold = ESTIMATE_THRESHOLD) {
  let riskInfo;

  if (estimate === undefined) {
    riskInfo = {
      text: "No estimate",
      riskLevel: Risk.Unknown,
    };
  } else if (estimate > threshold) {
    riskInfo = {
      text: `Large estimate: ${estimate}`,
      riskLevel: Risk.Warning,
    };
  } else {
    riskInfo = {
      text: "Good estimate",
      riskLevel: Risk.Good,
    };
  }

  return riskInfo;
}

/**
 * This function calculates the risk for stories
 *
 * Ticket risk at a high level:
 *
 * Red (immediate fire)
 * - Not done by deadline (includes unassigned / not started tasks)
 *
 * Yellow (warnings)
 * - Getting close to deadline (includes unassigned / not started)
 * - Has been in progress for longer than 3 days
 * - Has a high story point number
 *
 * Grey (not enough info, push to fix hygiene)
 * - In progress but hasn’t been updated for longer than 3 days
 * - Doesn’t have a deadline
 * - No estimate
 *
 * @param {*} inProgressStory - A story object which has the keys:
 *  deadline, moved_at, updated_at, and estimate. The story object passed here
 *  is assumed to be in progress.
 *
 * TODO: we should do a check actually
 *
 * @param {*} now - The Date we calculate risk against. We pass in now so that
 *   it's accurate against a time used for other calculations. Defaults to now
 *   if you don't pass it in.
 *
 * @returns an array of objects with the form:
 * ```
 *  {
 *    riskBreakdown: [
 *      {
 *        text: "Missing deadline"
 *        riskLevel: 0
 *      },
 *      ...
 *    ]
 *    riskDriverIndex: 1,
 *  }
 * ```
 * Where `riskDriverIndex` is an index of the item in the `riskBreakdown` array
 * that determines the overall risk level for the story.
 *
 * Each object corresponds to the story of the same index in the `stories`
 * param.
 */
function computeStoryRisks(inProgressStory, now = new Date()) {
  const riskBreakdown = [];

  // Order here matters as it corresponds to how important these different risks are
  riskBreakdown.push(computeDeadlineRisk(inProgressStory.deadline, now));
  riskBreakdown.push(computeDaysSinceLastUpdateRisk(new Date(inProgressStory.updated_at), now));
  riskBreakdown.push(computeDaysInProgressRisk(new Date(inProgressStory.moved_at), now));
  riskBreakdown.push(computeEstimateRisk(inProgressStory.estimate));

  return {
    riskBreakdown,
    riskDriverIndex: getOverallRiskDriver(riskBreakdown),
  };
}

export {
  computeStoryRisks,
  // Exported just for testing
  // TODO: use rewire or something to do this instead:
  // https://www.samanthaming.com/journal/2-testing-non-exported-functions/
  computeDeadlineRisk,
  computeDaysSinceLastUpdateRisk,
  computeDaysInProgressRisk,
  computeEstimateRisk,
};
