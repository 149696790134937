const avatar = {
  styleOverrides: {
    root: {
      transition: "all 200ms ease-in-out",
      height: "35px",
      width: "35px",
    },
  },
};

export default avatar;
