// We need this because there can only be one redirect uri configured on github
// Because we have different redirect uris depending on if you're in staging or
// production, we have a different client id here
//
// Note tha tthe client id is public, but the SECRET isn't supposed to be shared
function getGithubClientId() {
  if (process.env.REACT_APP_ENV === "production") {
    return "89f0041f99938cb248a9";
  }

  // This is used for staging (and maybe local, but didn't test)
  return "ad3f578bab5b145ef455";
}

// Example: https://github.com/login/oauth/authorize?client_id=89f0041f99938cb248a9&scope=repo
const GITHUB_OAUTH_APP_CONFIG = {
  authUrl: "https://github.com/login/oauth/authorize",
  params: {
    client_id: getGithubClientId(),
    scope: "repo,admin:repo_hook",
  },
};

export default function getAuthURL(config, stateVal) {
  const { params } = config;
  params.state = stateVal;

  const urlEncodedParams = new URLSearchParams(params).toString();
  return `${config.authUrl}?${urlEncodedParams}`;
}

export { GITHUB_OAUTH_APP_CONFIG };
