import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Icon from "@mdi/react";
import Box from "@mui/material/Box";
import { mdiHistory, mdiCloseCircleOutline, mdiUndoVariant } from "@mdi/js";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import InfoButton from "layouts/projects/components/InfoButton";
import { useApi, useApiFetch } from "utils/apiUtils";
import SIDataGrid from "components_si/SIDataGridPro";
import AuthorAvatarName from "components_si/AuthorAvatarName";
import DataPopup from "components_si/DataPopup";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import darkModeColors from "assets/theme-dark/base/colors";
import Snackbar from "@mui/material/Snackbar";
import services from "utils/services";
import { computePRRisks } from "../utils/prRiskUtils";
import { Risk } from "../utils/riskUtils";
import RiskFlags from "./RiskFlags";

const { background, border } = darkModeColors;

function getOpenPRRisksColumnDefinition(onClickMarkAsMitigated) {
  return [
    {
      field: "prGithubUsername",
      headerName: "Author",
      minWidth: 200,
      renderCell: (params) => (
        <AuthorAvatarName
          author={params.row.prGithubUsername}
          authorUrl={params.row.prGithubProfileUrl}
          authorAvatarUrl={params.row.prGithubAvatarUrl}
        />
      ),
    },
    {
      field: "prTitle",
      headerName: "Pull request",
      flex: 1,
      minWidth: 100,
    },
    // TODO: Building the following: i) Show prState in the last column, ii) On hover, show a checkmark or an 'x' instead, to dismiss the risk. The commented code below in the 'State' and 'Dismiss' columns are a start to creating this
    {
      field: "prState",
      headerName: "State",
      minWidth: 100,
      flex: 0.5,
      // maxWidth: 120,
      renderCell: (params) =>
        params.row.prState.charAt(0).toUpperCase() + params.row.prState.slice(1),
    },
    {
      // TODO: Make sorting based on Risk Level (or even: i) Risk Level, ii) Alphabetical by Risk Text)
      field: "riskLevel",
      headerName: "Alert",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => <RiskFlags riskBreakdown={params.row.riskAll} />,
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 50,
      maxWidth: 100,
      align: "center",
      // TODO: Would be nice to remove this from the show/hide column menu altogether
      hideable: false,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon path={mdiCloseCircleOutline} title="Dismiss" size={1} />}
          onClick={() => {
            onClickMarkAsMitigated(params.row.id);
          }}
          label="Dismiss"
        />,
      ],
    },
  ];
}

function getAllPRRisksColumnDefinition(onClickUndo) {
  return [
    {
      field: "prGithubUsername",
      headerName: "Author",
      minWidth: 200,
      renderCell: (params) => (
        <AuthorAvatarName
          author={params.row.prGithubUsername}
          authorUrl={params.row.prGithubProfileUrl}
          authorAvatarUrl={params.row.prGithubAvatarUrl}
        />
      ),
    },
    {
      field: "prTitle",
      headerName: "Pull request",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "prState",
      headerName: "State",
      minWidth: 100,
      flex: 0.5,
      renderCell: (params) =>
        params.row.prState.charAt(0).toUpperCase() + params.row.prState.slice(1),
    },
    {
      // TODO: Make sorting based on Risk Level (or even: i) Risk Level, ii) Alphabetical by Risk Text)
      field: "riskLevel",
      headerName: "Alert",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => <RiskFlags riskBreakdown={params.row.riskAll} />,
    },
    {
      field: "riskUpdatedAt",
      headerName: "Dismissed at",
      minWidth: 200,
      renderCell: (params) => dayjs(params.row.riskUpdatedAt).format("YYYY-MM-DD [at] HH:mm"),
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 50,
      maxWidth: 100,
      align: "center",
      // TODO: Would be nice to remove this from the show/hide column menu altogether
      hideable: false,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon path={mdiUndoVariant} title="Dismiss" size={1} />}
          onClick={() => {
            onClickUndo(params.row.id);
          }}
          label="Undo"
        />,
      ],
    },
  ];
}

// This would be better as a continuously updating time, but our granularlity is
// not small enough for this to really make a big difference.
const now = new Date();

function AtRiskPRsTable({ teamId, service, dateRange }) {
  const { apiFetch: fetch } = useApiFetch();
  const [startDate, endDate] = dateRange;
  const encodedStartDate = encodeURIComponent(startDate.toISOString());
  const encodedEndDate = encodeURIComponent(endDate.toISOString());
  const {
    data: inProgressPRs,
    refresh,
    loading,
  } = useApi({
    url: `/api/teams/${teamId}/prs_at_risk?service=${service}&start=${encodedStartDate}&end=${encodedEndDate}`,
    defaultData: [],
  });
  const defaultRiskSettings = {
    days_without_review_threshold: 1,
    days_in_review_threshold: 2,
    comments_threshold: 10,
  };
  const {
    data: riskSettingsData,
    refresh: _refreshSettings,
    loading: _loadingSettings,
  } = useApi({
    url: `/api/teams/${teamId}/risk`,
    defaultData: defaultRiskSettings,
  });

  // Ensures that if the database is set to null, we use defaultRiskSettings
  // TODO: Could be preferrable to coalesce these on the backend to handle null
  // That way when the help text becomes pulled from the db there's one source of truth.
  const riskSettings = {
    days_without_review_threshold:
      riskSettingsData.days_without_review_threshold !== null
        ? riskSettingsData.days_without_review_threshold
        : defaultRiskSettings.days_without_review_threshold,
    days_in_review_threshold:
      riskSettingsData.days_in_review_threshold !== null
        ? riskSettingsData.days_in_review_threshold
        : defaultRiskSettings.days_in_review_threshold,
    comments_threshold:
      riskSettingsData.comments_threshold !== null
        ? riskSettingsData.comments_threshold
        : defaultRiskSettings.comments_threshold,
  };
  const [isDataPopupOpen, setIsDataPopupOpen] = useState(false);
  const [currentSnackbarPR, setCurrentSnackbarPR] = useState(null);
  const shouldShowUndoSnackbar = currentSnackbarPR !== null;

  const onClickMarkAsMitigated = async (prId) => {
    // TODO: actually since risks are id'd by PR, other teams that have
    // the same access to that PR can dismiss it -- this shouldn't be on /teams
    await fetch(`/api/teams/${teamId}/prs_at_risk/${prId}`, {
      method: "POST",
      body: new URLSearchParams({
        changed_at: Date.now(),
        state: "dismissed",
        service,
      }),
    });
    refresh();
    setCurrentSnackbarPR(prId);
  };

  const onClickUndo = async (prId) => {
    // TODO: actually since risks are id'd by PR, other teams that have
    // the same access to that PR can dismiss it -- this shouldn't be on /teams
    await fetch(`/api/teams/${teamId}/prs_at_risk/${prId}`, {
      method: "POST",
      body: new URLSearchParams({
        changed_at: Date.now(),
        state: "active",
        service,
      }),
    });
    refresh();
  };

  const mitigatedRowsDataGrid = inProgressPRs.reduce((filtered, pr) => {
    // Filter out closed PRs because in our sample data (setori data) there are too many
    // due to us merging without reviews
    // TODO: Consider commenting out when onboarding a new client
    // if (pr.state !== "open") {
    //   return filtered;
    // }

    if (pr.state === "closed") {
      return filtered;
    }

    // Filter out non-dismissed risks
    if (pr.risk_state !== "dismissed") {
      return filtered;
    }

    const { riskBreakdown, riskDriverIndex } = computePRRisks(
      pr,
      now,
      riskSettings.days_without_review_threshold,
      riskSettings.days_in_review_threshold,
      riskSettings.comments_threshold
    );

    // Filter out PRs that don't have any risks
    if (riskBreakdown.length === 0) {
      return filtered;
    }

    const riskDriver = riskBreakdown[riskDriverIndex];

    // Filter out Good PRs
    // We can just filter these out on the API level if we want.
    // But they're here because I was thinking this would be an "in progress PRs"
    // view.
    if (riskDriver.riskLevel === Risk.Good) {
      return filtered;
    }

    filtered.push({
      id: pr.id,
      prGithubUsername: pr.github_username,
      prGithubProfileUrl: pr.github_profile_url,
      prGithubAvatarUrl: pr.github_avatar_url,
      prTitle: pr.title,
      prState: pr.state,
      prUrl: pr.html_url,
      riskLevel: riskDriver.riskLevel,
      riskAll: riskBreakdown,
      riskUpdatedAt: pr.risk_updated_at,
    });
    return filtered;
  }, []);

  const rowsDataGrid = inProgressPRs.reduce((filtered, pr) => {
    // Filter out closed PRs because in our sample data (setori data) there are too many
    // due to us merging without reviews
    // TODO: Consider commenting out when onboarding a new client
    // if (pr.state !== "open") {
    //   return filtered;
    // }

    if (pr.state === "closed") {
      return filtered;
    }

    // Filter out already dismissed PR risks
    if (pr.risk_state === "dismissed") {
      return filtered;
    }

    const { riskBreakdown, riskDriverIndex } = computePRRisks(
      pr,
      now,
      riskSettings.days_without_review_threshold,
      riskSettings.days_in_review_threshold,
      riskSettings.comments_threshold
    );

    // Filter out PRs that don't have any risks
    if (riskBreakdown.length === 0) {
      return filtered;
    }

    const riskDriver = riskBreakdown[riskDriverIndex];

    // Filter out Good PRs
    // We can just filter these out on the API level if we want.
    // But they're here because I was thinking this would be an "in progress PRs"
    // view.
    if (riskDriver.riskLevel === Risk.Good) {
      return filtered;
    }

    filtered.push({
      id: pr.id,
      prGithubUsername: pr.github_username,
      prGithubProfileUrl: pr.github_profile_url,
      prGithubAvatarUrl: pr.github_avatar_url,
      prTitle: pr.title,
      prState: pr.state,
      prUrl: pr.html_url,
      riskLevel: riskDriver.riskLevel,
      riskAll: riskBreakdown,
    });
    return filtered;
  }, []);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCurrentSnackbarPR(null);
  };

  return (
    <Grid container>
      <DataPopup
        title="Dismissed At Risk Pull Requests"
        definition="Open pull requests that have risks that were dismissed"
        icon={mdiHistory}
        isOpen={isDataPopupOpen}
        handleClose={() => {
          setIsDataPopupOpen(false);
        }}
      >
        <SIDataGrid
          columns={getAllPRRisksColumnDefinition(onClickUndo)}
          rows={mitigatedRowsDataGrid}
          onRowClick={(params) => window.open(params.row.prUrl)}
          loading={loading}
        />
      </DataPopup>
      <Snackbar
        key={currentSnackbarPR}
        open={shouldShowUndoSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        autoHideDuration={6000}
        message="Risk dismissed."
        action={
          <>
            <Button
              size="small"
              onClick={() => {
                onClickUndo(currentSnackbarPR);
                handleClose();
              }}
            >
              UNDO
            </Button>
            <IconButton sx={{ p: 0.5 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </>
        }
      />
      <Grid
        item
        xs={12}
        sx={{
          px: 3,
          borderLeft: 0.5,
          borderColor: border.light,
          backgroundColor: background.subtitle,
        }}
      >
        {/* TODO: Consider making a title component -- right now
        these titles are a bit inconsistent in terms of height because some have icons in them and some don't
        https://github.com/setorihats/setori-insights/pull/699 */}
        <Stack
          direction="row"
          sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <MDTypography color="info" variant="h3">
            At Risk Pull Requests
          </MDTypography>
          <Stack direction="row">
            <IconButton
              onClick={() => {
                setIsDataPopupOpen(true);
              }}
            >
              <SvgIcon>
                <path d={mdiHistory} />
              </SvgIcon>
            </IconButton>
            <Box
              sx={{
                py: 0.5,
                pl: 0.5,
                // TODO: this awkwward number is to align the help icon with the dismiss icons in the table below.
                // Probably it's better to just consistently align the dismiss icons with the help icon because
                // the help icon is just right aligned and the dismiss icons are centered within the column which is
                // more awkward to align to.
                pr: 1.2,
              }}
            >
              <InfoButton
                title="At Risk Pull Requests"
                message={
                  <>
                    <MDTypography variant="body2" gutterBottom>
                      A PR is flagged as at risk when:
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom>
                      <Box px={2}>
                        {/* TODO: Declare benhmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
                        <List sx={{ listStyleType: "disc" }}>
                          <ListItem sx={{ display: "list-item" }}>
                            <strong>Pickup risks:</strong> it has been waiting for review for longer
                            than {riskSettings.days_without_review_threshold}{" "}
                            {riskSettings.days_without_review_threshold === 1 ? "day" : "days"}
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            <strong>Review risks:</strong> it has been in review for longer than{" "}
                            {riskSettings.days_in_review_threshold}{" "}
                            {riskSettings.days_in_review_threshold === 1 ? "day" : "days"} and/or
                            has {riskSettings.comments_threshold}+{" "}
                            {riskSettings.comments_threshold === 1 ? "comment" : "comments"}
                          </ListItem>
                        </List>
                      </Box>
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom>
                      The most urgent risks are highlighted in red. They are the risks that still
                      have the opportunity to worsen (e.g., a PR is still in review and the review
                      time is still increasing).
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom>
                      Yellow risks are warnings of risks that have happened in the past (e.g., a PR
                      was eventually picked up for review but took longer than 1 day).
                    </MDTypography>
                  </>
                }
              />
            </Box>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ pt: 0 }}>
        <SIDataGrid
          autoHeight
          sx={{
            pb: 1,
            "& .MuiDataGrid-columnHeaders": {
              px: 2,
            },
            "& .MuiDataGrid-cell": {
              px: 3,
            },
          }}
          hideFooter
          onRowClick={(params) => window.open(params.row.prUrl)}
          columns={getOpenPRRisksColumnDefinition(onClickMarkAsMitigated)}
          rows={rowsDataGrid}
          noRowsText="Congrats! Your team has no risks"
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of Cycle Time Card
AtRiskPRsTable.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
};

AtRiskPRsTable.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
};

export default AtRiskPRsTable;
