import PropTypes from "prop-types";
import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDProgressBuffer from "components/MDProgressBuffer";
import MDTypography from "components/MDTypography";

import Icon from "@mdi/react";
import { mdiGithub, mdiOpenInNew } from "@mdi/js";

import { useApi } from "utils/apiUtils";

function Overview({ teamId }) {
  // Open a webpage in a new tab
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const { data: progressMetrics } = useApi({
    url: `/api/teams/${teamId}/progress_metrics`,
    defaultData: {},
    dependencies: [teamId],
  });

  const {
    started,
    unstarted,
    done,
    planned_start_date: plannedStartDate,
    deadline,
  } = progressMetrics;
  const total = Object.keys(progressMetrics).length === 0 ? 0 : started + unstarted + done;
  const startedPercentage = started === undefined ? 0 : Math.round((started / total) * 100);
  const donePercentage = done === undefined ? 0 : Math.round((done / total) * 100);
  const progressString = `${donePercentage}% Completed, ${startedPercentage}% In Progress`;

  const startDate = dayjs(plannedStartDate);
  const launchDate = dayjs(deadline);
  const daysToLaunch = launchDate.diff(dayjs(), "day");
  let timeIntervalString = `${launchDate.format("MMM D")}`;
  if (startDate !== null) {
    timeIntervalString = `${startDate.format("MMM D")} - ${launchDate.format("MMM D")}`;
  }

  return (
    <Grid
      container
      my={2}
      py={2}
      px={3}
      sx={{ border: 2, borderRadius: 2, borderColor: "#DFE1E4" }}
    >
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
          <MDTypography variant="body2">Owner:&nbsp;</MDTypography>
          <Avatar>GK</Avatar>
          <Link
            color="#1764C0"
            href="https://www.facebook.com/graceku"
            variant="body2"
            target="_blank"
            underline="always"
            rel="noopener"
          >
            <u>Grace Ku</u>
          </Link>
          <MDTypography variant="body2">&nbsp;|&nbsp;</MDTypography>
          <Link
            color="#1764C0"
            href="https://docs.google.com/document/d/1EJtO5JeaKF5SesPBqLERZGUz8LpXUl3iD5GxQuyeKo4/edit"
            variant="body2"
            target="_blank"
            rel="noopener"
          >
            <u>Docs</u>
          </Link>
          <Icon path={mdiOpenInNew} color="#1764C0" title="Email logo" size={1} />
          <Box px={1}>
            <Chip label="KPI: # Customers" />
          </Box>
          <Button
            color="warning"
            variant="contained"
            endIcon={<Icon path={mdiGithub} title="GitHub logo" size={1} />}
            onClick={() => openInNewTab("https://github.com/setorihats/setori-insights")}
          >
            Output:
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <CardHeader title="Days to Launch" style={{ textAlign: "center" }} />
      </Grid>
      <Grid item xs={9} />
      <Grid item xs={3} style={{ textAlign: "center" }}>
        <MDTypography variant="h1" color="success">
          {daysToLaunch}
        </MDTypography>
        <MDTypography variant="caption">{timeIntervalString}</MDTypography>
      </Grid>
      <Grid item xs={9} pt={2}>
        <MDProgressBuffer value={donePercentage} valueBuffer={donePercentage + startedPercentage} />
        <MDTypography variant="body2">{progressString}</MDTypography>
      </Grid>
    </Grid>
  );
}

Overview.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default Overview;
