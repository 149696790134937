// Idk why but importing this breaks this -- we already have this dependency
// if we have any mui pro libs
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from "@mui/x-license-pro";

import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SvgIcon from "@mui/material/SvgIcon";

import Box from "@mui/material/Box";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import TeamSelect from "examples/Sidenav/TeamSelect";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setLayout } from "context";

// Images
import brandSetoriDark from "assets/images/setori-logo-name.png";
import brandSetoriWhite from "assets/images/setori-logo-name-white.svg";

import SignIn from "layouts/signin";

import { mdiChartLine, mdiBullseyeArrow, mdiCog } from "@mdi/js";
import darkModeColors from "assets/theme-dark/base/colors";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Projects from "layouts/projects";
import Missions from "layouts/missions";
import Settings from "layouts/settings";
import IntegrationRedirect from "layouts/integrations/redirect";
import { useApi } from "utils/apiUtils";

const { icon } = darkModeColors;

// License key for Material UI Pro. We have 1 front-end developer license as of 2022-12-20
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

// As recommended by https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md
// And https://developer.auth0.com/resources/code-samples/spa/react-javascript/react-router-6-basic-authentication
const ProtectedProject = withAuthenticationRequired(Projects);
const ProtectedMission = withAuthenticationRequired(Missions);
const ProtectedSettings = withAuthenticationRequired(Settings);

// This takes all the declared routes and makes sure they're all protected
// Probably the best way to do this is make a visitor that collects all routes
const protectedRouteSpecs = routes.reduce((accumulator, route) => {
  if (route.route) {
    accumulator.push({ ...route, component: withAuthenticationRequired(route.component) });
  }

  if ("nested" in route) {
    route.nested.forEach((nestedRoute) => {
      accumulator.push({
        ...nestedRoute,
        component: withAuthenticationRequired(nestedRoute.component),
      });
    });
  }
  return accumulator;
}, []);

const allProtectedRouteComponents = protectedRouteSpecs.map((route) => (
  <Route exact path={route.route} element={<route.component />} key={route.key} />
));

function RedirectToAuth0Login() {
  const [, dispatch] = useMaterialUIController();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    setLayout(dispatch, "page");
    loginWithRedirect();
  }, [loginWithRedirect]);

  return "Redirecting...";
}

export default function App() {
  const navigate = useNavigate();
  const [controller, _dispatch] = useMaterialUIController();
  const { layout, darkMode } = controller;
  const { pathname } = useLocation();
  const [selectedTeamIdx, setSelectedTeamIdx] = useState(-1);
  const [isSettingsPage, setIsSettingsPage] = useState(false); // switches the sidebar

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const { data: teamData } = useApi({
    url: "/api/teams",
  });

  useEffect(() => {
    // TODO: should probably do a regex instead of a startsWith
    // TODO: since "/integrations" doesn't start with "/projects", idx is never set >= 0 so the first few sidenav options don't get added
    if (teamData != null && teamData.length > 0 && pathname.startsWith("/projects")) {
      const pathComponents = pathname.split("/");
      const [_pathRoot, _projectsPathName, teamId] = pathComponents;
      const idx = teamData.findIndex((team) => team.team_id === teamId);
      if (idx >= 0) {
        setSelectedTeamIdx(idx);
      }
      setIsSettingsPage(pathComponents.includes("settings"));
    }
  }, [teamData, pathname]);

  const sideNavList = [];
  if (isSettingsPage) {
    if (selectedTeamIdx >= 0) {
      const { team_id: teamId } = teamData[selectedTeamIdx];

      // sideNavList.push({
      //   type: "collapse",
      //   name: "Benchmarks",
      //   key: `/projects/${teamId}/settings/benchmarks`,
      //   route: `/projects/${teamId}/settings/benchmarks`,
      //   icon: (
      //     <SvgIcon fontSize="small">
      //       <path d={mdiCog} fill={icon.focus} />
      //     </SvgIcon>
      //   ),
      // });
      sideNavList.push({
        type: "collapse",
        name: "Dashboard",
        key: `/projects/${teamId}/settings/risks`,
        route: `/projects/${teamId}/settings/risks`,
        icon: (
          <SvgIcon fontSize="small">
            <path d={mdiChartLine} fill={icon.focus} />
          </SvgIcon>
        ),
      });
      sideNavList.push({
        type: "collapse",
        name: "Mission",
        key: `projects/${teamId}/settings/missions`,
        route: `/projects/${teamId}/settings/missions`,
        icon: (
          <SvgIcon fontSize="small">
            <path d={mdiBullseyeArrow} fill={icon.focus} />
          </SvgIcon>
        ),
      });
    }
  } else {
    if (selectedTeamIdx >= 0) {
      const { team_id: teamId, mission_type: missionType } = teamData[selectedTeamIdx];

      sideNavList.push({
        type: "collapse",
        name: "Dashboard",
        key: `projects/${teamId}`,
        route: `/projects/${teamId}`,
        icon: (
          <SvgIcon fontSize="small">
            <path d={mdiChartLine} fill={icon.focus} />
          </SvgIcon>
        ),
      });
      if (missionType !== null) {
        sideNavList.push({
          type: "collapse",
          name: "Mission",
          key: `projects/${teamId}/mission`,
          route: `/projects/${teamId}/mission`,
          icon: (
            <SvgIcon fontSize="small">
              <path d={mdiBullseyeArrow} fill={icon.focus} />
            </SvgIcon>
          ),
        });
      }
      sideNavList.push({ type: "divider", key: "divider" });
    }
    sideNavList.push(...routes);
    if (selectedTeamIdx >= 0) {
      const { team_id: teamId } = teamData[selectedTeamIdx];
      sideNavList.push({
        type: "collapse",
        name: "Settings",
        key: `projects/${teamId}/settings`,
        route: `/projects/${teamId}/settings`,
        icon: (
          <SvgIcon fontSize="small">
            <path d={mdiCog} fill={icon.focus} />
          </SvgIcon>
        ),
      });
    }
  }

  let brandImage;
  if (isSettingsPage) {
    brandImage = null; // don't render the brand image for settings page
  } else if (darkMode) {
    brandImage = brandSetoriWhite;
  } else {
    brandImage = brandSetoriDark;
  }

  const main = (
    <>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          brand={brandImage}
          isNestedMenu={isSettingsPage}
          nestedMenuTitle="Settings"
          routes={sideNavList}
        >
          {/* Only show the dropdown if team data has been loaded in and there is more than one team */}
          {selectedTeamIdx >= 0 && teamData != null && teamData.length > 1 ? (
            <Box
              sx={{
                pt: 1,
                pb: 1,
                px: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TeamSelect
                teamData={teamData}
                teamIdx={selectedTeamIdx}
                onTeamIdxChange={(idx) => {
                  setSelectedTeamIdx(idx);
                  // also stay on the same settings page (i.e. missions vs benchmarks)?
                  navigate(
                    `/projects/${teamData[idx].team_id}${isSettingsPage ? "/settings" : ""}`
                  );
                }}
              />
            </Box>
          ) : null}
        </Sidenav>
      )}
      <Routes>
        {/* We don't include this in the routes file because routes only contain protected routes (as in routes that require that you be logged in) */}
        <Route path="authentication/sign-in" element={<SignIn />} />
        {allProtectedRouteComponents}
        <Route path="projects/:teamId" element={<ProtectedProject />} />
        <Route path="projects/:teamId/mission" element={<ProtectedMission />} />
        <Route path="integrations/:integration" element={<IntegrationRedirect />} />
        <Route path="projects/:teamId/settings/" element={<ProtectedSettings />}>
          <Route path=":option" element={<ProtectedSettings />} />
        </Route>
        <Route path="/login" element={<RedirectToAuth0Login />} />
        <Route path="/" element={<RedirectToAuth0Login />} />
        {/* <Route path="*" element={<RedirectToAuth0Login />} /> */}
        {/*
          This route is not really itself a sign in page (that's what RedirectToAuth0Login is).

          It's for:
          - registering the sign in (the auth0 login page redirects to this page after a log in)
          - redirecting to the first project page if you're already logged in

          TODO: This is definitely a mess... At the very least, we can split these functionalities into separate routes.
         */}
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
      </Routes>
    </>
  );

  return <ThemeProvider theme={darkMode ? themeDark : theme}>{main}</ThemeProvider>;
}
