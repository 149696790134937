import PropTypes from "prop-types";
import dayjs from "dayjs";

import { useState, useEffect } from "react";
import { useApi } from "utils/apiUtils";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";
import MDTypography from "components/MDTypography";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import BenchmarkIcon from "components_si/BenchmarkIcon";
import {
  useEditingContext,
  EditingStates,
  getDraftCardSetting,
  editCardSetting,
  CardsEnum,
} from "components_si/EditingContext";
import services from "utils/services";
import GithubKpiCard from "./GithubKpiCard";

const CARD_DESCRIPTION = {
  title: "Merged w/o review",
  shortDefinition: "Number of pull requests (PRs) merged without a review",
  longDefinition: (
    <>
      <MDTypography variant="body2" gutterBottom>
        The number of pull requests (PRs) opened within a project, that have been merged without a
        review.
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        We compare you to industry benchmarks:
        <Box px={2}>
          {/* TODO: Declare benhmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>S-tier: </strong>No unreviewed merged PRs
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Average: </strong>1-5 PRs merged without being reviewed per week
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Poor: </strong> &gt; 5 PRs merged without being reviewed per week
            </ListItem>
          </List>
        </Box>
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        Reviewed PRs tend to be of higher quality and introduce less defects.
      </MDTypography>
    </>
  ),
  benchmarkTooltip: {
    stier: "No unreviewed merged PRs",
    average: "1-5 PRs merged without being reviewed per week",
    poor: "> 5 PRs merged without being reviewed per week",
  },
  unit: "pull requests",
  unitSingular: "pull request",
  highchartsTitle: "# of PRs merged w/o a review",
};

/**
 * @param {*} jsonBlob - A data object that must have date_point_start, lines_changed_weekly_avg_per_pr
 * @param {number} numPoints - Optional parameter, the number of data points to return. If not provided, returns all data points available in jsonBlob
 * @returns an object with an x- and a y-coordinate of length numPoints
 */
const getCardData = (jsonBlob, numPoints) => {
  if (jsonBlob === null) {
    return null;
  }

  const cardData = [];
  let jsonBlobTruncated = jsonBlob;

  if (numPoints !== undefined && numPoints < jsonBlob.length) {
    jsonBlobTruncated = jsonBlob.slice(jsonBlob.length - numPoints, jsonBlob.length);
  }

  const cardDataX = jsonBlobTruncated.map((x) => dayjs(x.date_point_start).valueOf());
  const cardDataY = jsonBlobTruncated.map((y) => parseInt(y.merged_no_reviews_count, 10));

  for (let i = 0; i < cardDataX.length; i++) {
    cardData[i] = [cardDataX[i], cardDataY[i]];
  }
  return cardData;
};

function KpiNoReviewsCard({ teamId, dateRange, bucket: bucketProp, service }) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  // TODO: should handle the null case as a loading state to prevent a flicker
  const [bucket, setBucket] = useState(null);
  useEffect(() => {
    setBucket(bucketProp);
  }, [bucketProp]);

  const getBucketWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving:
        return getDraftCardSetting(draft, CardsEnum.MergedWithoutReview)?.bucket ?? bucket;
      case EditingStates.Normal:
        return bucket;
      default:
        console.log("Invalid editing states mode");
        return bucket;
    }
  };

  const setBucketWrapper = (newBucket) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        editCardSetting(dispatch, CardsEnum.MergedWithoutReview, { bucket: newBucket });
        break;
      }
      case EditingStates.Normal: {
        setBucket(newBucket);
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setBucket(newBucket);
      }
    }
  };

  const [startDate, endDate] = dateRange;
  const encodedStartDate = encodeURIComponent(startDate.toISOString());
  const encodedEndDate = encodeURIComponent(endDate.toISOString());
  const { data } = useApi({
    url: `/api/teams/${teamId}/no_reviews_count?service=${service}&bucket=${getBucketWrapper()}&start=${encodedStartDate}&end=${encodedEndDate}`,
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/no_reviews_count?service=${service}&bucket=all&start=${encodedStartDate}&end=${encodedEndDate}`,
  });

  return (
    <GithubKpiCard
      cardType={CardsEnum.MergedWithoutReview}
      data={getCardData(data)}
      bucket={getBucketWrapper()}
      onSelectBucket={(currBucket) => {
        setBucketWrapper(currBucket);
      }}
      aggregateData={aggregateData?.[0]?.merged_no_reviews_count}
      renderBenchmark={(dataPoint, numWeeks) => (
        <Box pt={1}>
          <BenchmarkIcon
            tier={evaluateTier(dataPoint, MetricsEnum.MergedWithoutReview, numWeeks)}
            tooltip={CARD_DESCRIPTION.benchmarkTooltip}
          />
        </Box>
      )}
      description={CARD_DESCRIPTION}
      teamId={teamId}
      baseFilters={[
        {
          columnField: "state",
          operatorValue: "equals",
          value: "Merged",
        },
        {
          columnField: "review_time",
          operatorValue: "=",
          value: "0",
        },
      ]}
      service={service}
      actualStartDate={startDate}
    />
  );
}

KpiNoReviewsCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
};

KpiNoReviewsCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
};

export default KpiNoReviewsCard;
