/* eslint-disable */

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { init } from "@amplitude/analytics-browser";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

// TODO: Setup more react-based sentry logging
// https://docs.sentry.io/platforms/javascript/guides/react/?_ga=2.91871443.837446422.1669755367-187124655.1669755367
// https://docs.sentry.io/product/sentry-basics/integrate-frontend/upload-source-maps/
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // TODO: change this
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// Amplitude
init("4ffacda4c8e890333b15644a7b33b692");

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Auth0Provider
        domain="login.setori.ai"
        clientId="DwoVr25Wfrz0vt9uwCchHg3QICMCLpXk"
        redirectUri={`${window.location.origin}/authentication/sign-in`}
        audience="https://setori.ai/api"
      >
        <App />
      </Auth0Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
