// Base Styles
import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

const { background } = colors;
const { borderRadius } = borders;

const pickersLayout = {
  styleOverrides: {
    root: {
      backgroundColor: background.popup, // TThis is the color of the calendar
      borderRadius: borderRadius.lg,
    },
  },
};

export default pickersLayout;
