import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import { useMaterialUIController } from "context";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import MDTypography from "components/MDTypography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import { useApi } from "utils/apiUtils";

import BenchmarkIcon from "components_si/BenchmarkIcon";

import InfoButton from "layouts/projects/components/InfoButton";
import ChipDropdown from "layouts/projects/components/ChipDropdown";
import { mdiGithub, mdiGitlab } from "@mdi/js";
import darkModeColors from "assets/theme-dark/base/colors";
import { MetricsEnum, evaluateTier } from "utils/tierUtils";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";
import {
  SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVERING,
  NO_DATA_POINTS_SENTINEL,
  AGGREGATE_DATA_POINT_SENTINEL,
  shouldShowAggregate,
} from "utils/settingsUtils";
import {
  chartOptionsCycleTimeDarkMode,
  chartOptionsCycleTimeDarkModeOnHover,
  chartOptionsLightMode,
} from "layouts/projects/utils/optionsHighCharts";
import {
  useEditingContext,
  CardsEnum,
  EditingStates,
  editCardSetting,
  getDraftCardSetting,
} from "components_si/EditingContext";
import DataPopup from "components_si/DataPopup";
import services from "utils/services";
import CycleTimeDataGrid from "../CycleTimeDataGrid"; // Data pop-up

const { border } = darkModeColors;

dayjs.extend(utc);
dayjs.extend(isoWeek);

const METRICS_DESCRIPTION = {
  title: "Cycle time",
  shortDefinition: "Time from first line of code to merged PR",
  longDefinition: (
    <>
      <MDTypography variant="body2" gutterBottom>
        The period from when a developer starts writing new code (for say a new feature or a bug
        fix) to when this code is available to users.
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        We break down cycle time into:
        <Box px={2}>
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Coding time: </strong>Time from first commit to PR. If there is no review,
              time from first commit to merge
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Pickup time: </strong>Time from PR to first comment
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Review time: </strong>Time from first comment to merge
            </ListItem>
          </List>
        </Box>
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        We compare you to industry benchmarks:
        <Box px={2}>
          {/* TODO: Declare benhmarks as constants that is used everywhere. Pull this data here, so that everything stays in synch */}
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item" }}>
              <strong>S-tier: </strong>&lt; 12 hours coding time, &lt;6 hours pickup time, &lt;6
              hours review time, and &lt; 24 hours total time
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Average: </strong>12-36 hours coding time, 6-18 hours pickup time, 6-24 review
              time, and 24-72 hours total time
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <strong>Poor: </strong> &gt; 36 hours coding time, &gt;18 hours pickup time, &gt;24
              hours review time, and &gt; 72 hours total time
            </ListItem>
          </List>
        </Box>
      </MDTypography>
      <MDTypography variant="body2" gutterBottom>
        Teams with a shorter cycle time can better respond to customer needs and tend to ship
        smaller, more robust changes.
      </MDTypography>
      {/* Keeping this here in case we want to include that this is metric comes from DORA */}
      {/* <MDTypography variant="body2" gutterBottom>
              haven&apos;t invented it. The good folks at DevOps Research and Assessment (DORA) -
              a group at Google - surveyed thousands of software teams to distill the markers of
              high performance. Cycle time (change lead time) was a key metric identified in their work.
            </MDTypography> */}
    </>
  ),
  benchmarkTooltip: {
    cycle: {
      stier: "< 24 hours",
      average: "24-72 hours",
      poor: "> 72 hours",
    },
    coding: {
      stier: "< 12 hours",
      average: "12-36 hours",
      poor: "> 36 hours",
    },
    pickup: {
      stier: "< 6 hours",
      average: "6-18 hours",
      poor: "> 18 hours",
    },
    review: {
      stier: "< 6 hours",
      average: "6-24 hours",
      poor: "> 24 hours",
    },
  },
  highchartsTitle: "Average cycle time",
};

function getGraphData(dataJsonBlob, type) {
  let jsonBlob = [];
  if (dataJsonBlob !== null) {
    jsonBlob = dataJsonBlob;
  }

  const graphData = [];
  let graphDataY = [];

  const graphDataX = jsonBlob.map((x) => dayjs(x.date_point_start).valueOf());

  switch (type) {
    case "coding":
      graphDataY = jsonBlob.map((y) => (y?.coding_time_weekly_avg_seconds ?? 0) * 1000);
      break;
    case "pickup":
      graphDataY = jsonBlob.map((y) => (y?.pickup_time_weekly_avg_seconds ?? 0) * 1000);
      break;
    case "review":
      graphDataY = jsonBlob.map((y) => (y?.review_time_weekly_avg_seconds ?? 0) * 1000);
      break;
    case "cycle":
      graphDataY = jsonBlob.map((y) => (y?.cycle_time_weekly_avg_seconds ?? 0) * 1000);
      break;
    default:
      // eslint-disable-next-line no-console
      console.error("Not a valid case for the cycle time graph");
  }

  for (let i = 0; i < graphDataX.length; i++) {
    graphData[i] = [graphDataX[i], graphDataY[i]];
  }
  return graphData;
}

function getCycleAggregate(dataBlob, type) {
  if (dataBlob === null) {
    return 0;
  }

  if (dataBlob.length !== 1) {
    // eslint-disable-next-line no-console
    console.error("trying to get aggregate from data blob with more than one entry in it");
  }

  if (dataBlob.length !== 0) {
    const [aggregate] = dataBlob;

    const key = `${type}_time_weekly_avg_seconds`;
    if (!(key in aggregate)) {
      // eslint-disable-next-line no-console
      console.error("Not a valid period of cycle time");
    }

    return parseFloat(aggregate[key]);
  }
  return 0;
}

function getCycleDataPointFromDate(dataBlob, date, type) {
  if (dataBlob === null || dataBlob.length === 0 || date === NO_DATA_POINTS_SENTINEL) {
    return 0;
  }

  // TODO: Sometimes our data has times in it when it should only have a date
  // we should change this on the backend
  const dataPoint = dataBlob.find((item) => item.date_point_start === date);
  switch (type) {
    case "coding":
      return parseFloat(dataPoint?.coding_time_weekly_avg_seconds ?? 0);
    case "pickup":
      return parseFloat(dataPoint?.pickup_time_weekly_avg_seconds ?? 0);
    case "review":
      return parseFloat(dataPoint?.review_time_weekly_avg_seconds ?? 0);
    case "cycle":
      return parseFloat(dataPoint?.cycle_time_weekly_avg_seconds ?? 0);
    default:
      // eslint-disable-next-line no-console
      console.error("Not a valid period of cycle time");
      return 0;
  }
}

function CycleTimeCard({ teamId, dateRange, bucket: bucketProp, service, bucketDropdownOptions }) {
  const [draft, dispatch] = useEditingContext();
  const { mode } = draft;

  // TODO: should handle the null case as a loading state to prevent a flicker
  const [bucket, setBucket] = useState(null);
  const getBucketWrapper = () => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving:
        return getDraftCardSetting(draft, CardsEnum.CycleTime)?.bucket ?? bucket;
      case EditingStates.Normal:
        return bucket;
      default:
        console.log("Invalid editing states mode");
        return bucket;
    }
  };
  const setBucketWrapper = (newBucket) => {
    switch (mode) {
      case EditingStates.Editing:
      case EditingStates.Saving: {
        editCardSetting(dispatch, CardsEnum.CycleTime, { bucket: newBucket });
        break;
      }
      case EditingStates.Normal: {
        setBucket(newBucket);
        break;
      }
      default: {
        console.error("Invalid editing states mode");
        setBucket(newBucket);
      }
    }
  };

  // TODO: https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  useEffect(() => {
    setBucket(bucketProp);
  }, [bucketProp]);

  const [startDate, endDate] = dateRange;
  const encodedStartDate = encodeURIComponent(startDate.toISOString());
  const encodedEndDate = encodeURIComponent(endDate.toISOString());

  const { data } = useApi({
    url: `/api/teams/${teamId}/cycle_time?service=${service}&bucket=${getBucketWrapper()}&start=${encodedStartDate}&end=${encodedEndDate}`,
  });
  const { data: aggregateData } = useApi({
    url: `/api/teams/${teamId}/cycle_time?service=${service}&bucket=all&start=${encodedStartDate}&end=${encodedEndDate}`,
  });

  const [isHovering, setIsHovering] = useState(false);
  const [isDataPopupOpen, setIsDataPopupOpen] = useState(false);
  const [dataPointDate, setDataPointDate] = useState(NO_DATA_POINTS_SENTINEL);

  const resetToNotHoveringDataPointState = () => {
    if (
      aggregateData === null ||
      data === null ||
      data.length === 0 ||
      // Technically this should be !== 0 if data.length is !== 0 but just adding
      // this defensively
      aggregateData.length === 0
    ) {
      setDataPointDate(NO_DATA_POINTS_SENTINEL);
    } else if (SHOULD_SHOW_FULL_DATA_AGGREGATE_WHEN_NOT_HOVERING) {
      setDataPointDate(AGGREGATE_DATA_POINT_SENTINEL);
    } else {
      setDataPointDate(data[data.length - 1].date_point_start);
    }
  };

  useEffect(resetToNotHoveringDataPointState, [data, aggregateData]);

  // State for the filter in the Data Popup
  const [filt, setFilt] = useState(null);

  const handleClickCard = () => {
    setFilt([]);
    setIsDataPopupOpen(true);
  };
  const handleClickDataPoint = (x, index) => {
    let filterStartDate = dayjs(x).format("YYYY-MM-DD");
    let filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");

    // If it's the first item, it might not start at the beginning of the
    // time period, so use the passed in startDate
    if (index === 0) {
      filterStartDate = dayjs(startDate).format("YYYY-MM-DD");
    }

    if (getBucketWrapper() === "week") {
      filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");
    } else if (getBucketWrapper() === "month") {
      filterEndDate = dayjs(x).add(1, "month").format("YYYY-MM-DD");
    } else if (getBucketWrapper() === null) {
      // TODO: handle loading state
    } else {
      console.log("unsupported bucket type");
      filterEndDate = dayjs(x).add(7, "day").format("YYYY-MM-DD");
    }
    setIsDataPopupOpen(true);
    setFilt([
      {
        id: 1,
        columnField: "pr_created_at",
        operatorValue: "onOrAfter",
        value: filterStartDate,
      },
      {
        id: 2,
        columnField: "pr_created_at",
        operatorValue: "before",
        value: filterEndDate,
      },
    ]);
  };

  const handleMouseOverDataPoint = (x) => {
    setDataPointDate(dayjs(x).toISOString());
  };

  const handleClose = () => {
    setIsDataPopupOpen(false);
    // Note: Can't set this to [], since need to 'flush' the value of the array with null for the data point filters to work
    // TODO: Figure out why this is the case, not 100% sure why this works this way
    setFilt(null);
  };

  // Used to toggle between dark and light mode options for HighCharts
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const chartRefDarkMode = useRef(null);
  const chartRefDarkModeHover = useRef(null);

  // Reflow the chart on hover, to make sure that it is the right size (e.g., if the window was resized since last hover, or if the sidebar size was changed)
  // Only reflowing the two dark mode cycle time charts
  useEffect(() => {
    if (chartRefDarkMode.current.chart !== undefined) {
      chartRefDarkMode.current.chart.reflow();
    }

    if (chartRefDarkModeHover.current.chart !== undefined) {
      chartRefDarkModeHover.current.chart.reflow();
    }
  }, [isHovering]);

  const NESTED_CARDS = [
    { name: "Coding", metric: MetricsEnum.CodingTime },
    { name: "Pickup", metric: MetricsEnum.PickupTime },
    { name: "Review", metric: MetricsEnum.ReviewTime },
  ];
  const nestedCards = NESTED_CARDS.map(({ name, metric }) => {
    const lowerCaseName = name.toLowerCase();
    const effectiveDataPointValueInSeconds = shouldShowAggregate(dataPointDate)
      ? getCycleAggregate(aggregateData, lowerCaseName)
      : getCycleDataPointFromDate(data, dataPointDate, lowerCaseName);
    return (
      <Grid item xs={4} key={name}>
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            height: "125px",
            borderColor: border.light,
          }}
          px={2}
          py={1.5}
          mb={2}
        >
          <MDTypography color="info" variant="h3" pb={0.5}>
            {name}
          </MDTypography>
          <MDTypography variant="h2" sx={{ fontWeight: "normal" }} pb={1}>
            {convertMillisecondsToReadableTime(effectiveDataPointValueInSeconds * 1000)}
          </MDTypography>
          {dataPointDate !== NO_DATA_POINTS_SENTINEL && effectiveDataPointValueInSeconds !== 0 && (
            <BenchmarkIcon
              tier={evaluateTier(effectiveDataPointValueInSeconds, metric)}
              tooltip={METRICS_DESCRIPTION.benchmarkTooltip[lowerCaseName]}
            />
          )}
        </Box>
      </Grid>
    );
  });

  const effectiveCycleTimeDisplayValueInSeconds = shouldShowAggregate(dataPointDate)
    ? getCycleAggregate(aggregateData, "cycle")
    : getCycleDataPointFromDate(data, dataPointDate, "cycle");
  return (
    <>
      {/* The data popup. It is outside of CardAction not to trigger the onClick event */}
      <DataPopup
        title={METRICS_DESCRIPTION.title}
        definition={METRICS_DESCRIPTION.shortDefinition}
        icon={service === "gitlab" ? mdiGitlab : mdiGithub}
        isOpen={isDataPopupOpen}
        handleClose={handleClose}
      >
        <CycleTimeDataGrid teamId={teamId} service={service} filtInput={filt} />
      </DataPopup>
      <Card
        onPointerLeave={() => setIsHovering(false)}
        onPointerOver={() => setIsHovering(true)}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Question mark button in top-right of card. On top of the card and height of 0px to make the whole card clickable for CardActionArea */}
        <Box
          sx={{
            mt: 0.5,
            mb: 2,
            ml: 2,
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              height: "0px",
              justifyContent: "right",
              alignItems: "baseline",
              display: "absolute",
              // visibility: isHovering ? "block" : "block",
              // TODO: When the option is 'peeled', visibility will be hidden when not hovered
              visibility: isHovering || mode === EditingStates.Editing ? "block" : "hidden",
            }}
          >
            <ChipDropdown
              items={bucketDropdownOptions}
              selectedItem={getBucketWrapper()}
              onChange={(item) => setBucketWrapper(item)}
            />
          </Box>
          <Box
            sx={{
              height: "0px",
              justifyContent: "right",
              alignItems: "baseline",
              display: "flex",
              visibility: isHovering ? "block" : "hidden",
            }}
          >
            <InfoButton
              title={METRICS_DESCRIPTION.title}
              icon={mdiGithub}
              onClose={() => {
                setIsHovering(false);
              }}
              message={METRICS_DESCRIPTION.longDefinition}
            />
          </Box>
        </Box>
        <CardActionArea onClick={handleClickCard}>
          <Box>
            <Grid container pt={2} px={3}>
              <Grid item xs={3}>
                {/* Title and metric of the card */}
                <Box>
                  <Stack direction="row">
                    <MDTypography color="info" variant="h2">
                      {METRICS_DESCRIPTION.title}&nbsp;
                    </MDTypography>
                  </Stack>
                  <MDTypography variant="h1" pb={1}>
                    {convertMillisecondsToReadableTime(
                      effectiveCycleTimeDisplayValueInSeconds * 1000
                    )}
                  </MDTypography>
                  {dataPointDate !== NO_DATA_POINTS_SENTINEL &&
                    effectiveCycleTimeDisplayValueInSeconds !== 0 && (
                      <BenchmarkIcon
                        tier={evaluateTier(
                          effectiveCycleTimeDisplayValueInSeconds,
                          MetricsEnum.CycleTime
                        )}
                        tooltip={METRICS_DESCRIPTION.benchmarkTooltip.cycle}
                      />
                    )}
                </Box>
              </Grid>
              <Grid item xs={9} pr={2}>
                <Grid container spacing={2}>
                  {nestedCards}
                </Grid>
              </Grid>
            </Grid>
            {/* Note: Need to add both HighCharts in both views, since they are numbered by HighCharts and collide otherwise */}

            <Box sx={{ display: darkMode ? "block" : "none" }}>
              <Box sx={{ display: isHovering ? "none" : " block", pb: "20px" }}>
                <HighchartsReact
                  ref={chartRefDarkMode}
                  highcharts={Highcharts}
                  allowChartUpdate
                  options={chartOptionsCycleTimeDarkMode(
                    METRICS_DESCRIPTION.highchartsTitle,
                    getGraphData(data, "cycle"),
                    handleClickDataPoint,
                    handleMouseOverDataPoint,
                    resetToNotHoveringDataPointState
                  )}
                />
              </Box>
              <Box sx={{ display: isHovering ? "block" : " none" }}>
                <HighchartsReact
                  ref={chartRefDarkModeHover}
                  highcharts={Highcharts}
                  options={chartOptionsCycleTimeDarkModeOnHover(
                    METRICS_DESCRIPTION.highchartsTitle,
                    getGraphData(data, "cycle"),
                    handleClickDataPoint,
                    handleMouseOverDataPoint,
                    resetToNotHoveringDataPointState,
                    bucket
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: darkMode ? "none" : "block" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptionsLightMode(
                  METRICS_DESCRIPTION.highchartsTitle,
                  getGraphData(data, "cycle"),
                  handleClickDataPoint
                )}
              />
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </>
  );
}

// Setting default values for the props of Cycle Time Card
CycleTimeCard.defaultProps = {
  dateRange: [dayjs().subtract(7, "week").add(1, "day"), dayjs()],
  bucket: "week",
  bucketDropdownOptions: [
    { label: "weekly", value: "week" },
    { label: "monthly", value: "month" },
  ],
};

// Typechecking props for the Cycle Time Card
CycleTimeCard.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  bucket: PropTypes.string,
  bucketDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default CycleTimeCard;
