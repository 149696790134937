import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import darkModeColors from "assets/theme-dark/base/colors";

import KpiCodingTimeCard from "layouts/projects/components/cards/KpiCodingTimeCard";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import SvgIcon from "@mui/material/SvgIcon";
import InfoButton from "layouts/projects/components/InfoButton";
import TeamAvatarName from "components_si/TeamAvatarName";
import Subtitle from "components_si/Subtitle";
import inProgressIconPaths from "assets/images/small-logos/in-progress-icon-paths";
import { mdiCalendarStar } from "@mdi/js";
import services from "utils/services";
import dayjs from "dayjs";

import GraphTypeEnum from "layouts/projects/utils/GraphTypeEnum";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";
import MissionOverview from "./components/MissionOverview";
import MissionDataGrid from "./components/MissionDataGrid";

const { text, background, border, success, info } = darkModeColors;

// Move this to a utils function OR make a template for the missions
function getMissionStatus(startDate, endDate) {
  const currentDay = dayjs().valueOf();
  const missionStart = dayjs(startDate).valueOf();
  const missionEnd = dayjs(endDate).valueOf();

  if (currentDay < missionStart) {
    return {
      icon: <path d={inProgressIconPaths.circle} fill={info.main} />,
      label: "Not Started",
      calendarColor: info.main,
    };
  } else if (currentDay <= missionEnd) {
    return {
      icon: (
        <>
          <path d={inProgressIconPaths.circle} fill={success.main} />
          <path d={inProgressIconPaths.man} fill={success.main} />
        </>
      ),
      label: "In Progress",
      calendarColor: success.main,
    };
  } else {
    return {
      icon: <path d={inProgressIconPaths.complete} fill={success.main} />,
      label: "Completed",
      calendarColor: success.main,
    };
  }
}

function CodingMission({ teamId, teamName, avatarColor, service, configuration }) {
  const missionStatus = getMissionStatus(configuration.startDate, configuration.endDate);
  const includeMonth =
    dayjs(configuration.startDate).month() === dayjs(configuration.endDate).month();

  return (
    <DashboardLayout>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            pt: 3.25,
            pl: 3,
            borderLeft: 0.5,
            borderBottom: 0.5,
            borderColor: border.light,
          }}
        >
          <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={2}>
            <TeamAvatarName teamName={teamName} avatarColor={avatarColor} />
            <MDTypography variant="h2">
              {teamName} &gt; Mission &gt; Coding Time Mastery
            </MDTypography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ py: 2, alignItems: "center" }}>
            <Tooltip title="Mission state">
              <Chip
                icon={
                  <SvgIcon viewBox="0 0 13 13" fontSize="medium">
                    {missionStatus.icon}
                  </SvgIcon>
                }
                sx={{ px: 0.5, color: text.subtitle, backgroundColor: background.hover }}
                label={missionStatus.label}
              />
            </Tooltip>
            <Tooltip title="Mission duration">
              <Chip
                icon={
                  <SvgIcon fontSize="medium">
                    <path d={mdiCalendarStar} fill={missionStatus.calendarColor} />
                  </SvgIcon>
                }
                sx={{ px: 0.5, color: text.subtitle, backgroundColor: background.hover }}
                label={`${dayjs(configuration.startDate).format("MMM D")} - ${dayjs(
                  configuration.endDate
                ).format(includeMonth ? "D" : "MMM D")}`}
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Subtitle>Overview</Subtitle>
        </Grid>
        <Grid item xs={6}>
          <MissionOverview target={configuration.target} />
        </Grid>
        <Grid item xs={6}>
          {/** include date range here? */}
          <KpiCodingTimeCard
            teamId={teamId}
            service={service}
            graph={GraphTypeEnum.Scatter}
            targetScatter={configuration.target}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            px: 3,
            borderLeft: 0.5,
            borderColor: border.light,
            backgroundColor: background.subtitle,
          }}
        >
          {/* TODO: Consider making a title component -- right now
        these titles are a bit inconsistent in terms of height because some have icons in them and some don't
        https://github.com/setorihats/setori-insights/pull/699 */}
          <Stack
            direction="row"
            sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            <MDTypography color="info" variant="h3">
              Flagged Merge Requests
            </MDTypography>
            <Stack direction="row">
              <Box
                sx={{
                  py: 0.5,
                  pl: 0.5,
                }}
              >
                <InfoButton
                  title="Mission metrics"
                  message={
                    <>
                      <MDTypography variant="body2" gutterBottom>
                        This table includes all Merge Requests (MRs) with a coding time of{" "}
                        {convertMillisecondsToReadableTime(configuration.target * 1000)} or less
                        that were opened between the mission start and end date.
                      </MDTypography>
                      <MDTypography variant="body2" gutterBottom>
                        These MRs should be reviewed to understand what could have been done to
                        shorten their coding time (e.g., merge using feature flags).
                      </MDTypography>
                      <MDTypography variant="body2" gutterBottom>
                        While in some cases, coding time will optimally be above{" "}
                        {convertMillisecondsToReadableTime(configuration.target * 1000)}, most MRs
                        should be below this threshold.
                      </MDTypography>
                    </>
                  }
                />
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <MissionDataGrid teamId={teamId} service={service} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

// Typechecking props
CodingMission.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  avatarColor: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  configuration: PropTypes.exact({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    target: PropTypes.number,
  }).isRequired,
};

export default CodingMission;
