import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";
import MDTypography from "components/MDTypography";
import pxToRem from "assets/theme/functions/pxToRem";

function BasicSettingsLayout({
  title,
  description,
  headers,
  configurables,
  handleSave,
  saved,
  defaultRender,
}) {
  // TODO: Simplify this file - likely too many grids
  return (
    <Grid container spacing={8} direction="row" alignItems="center" sx={{ mt: pxToRem(20) }}>
      <Grid item xs />
      <Grid container item direction="column" spacing={10} xs={7}>
        <Grid item xs>
          <MDTypography variant="h1">{title}</MDTypography>
          <h3>{description}</h3>
        </Grid>
        {configurables.length > 0 ? (
          <Grid container item direction="column">
            <Grid container item spacing={4}>
              {headers}
            </Grid>
            <Grid item my={4}>
              {configurables}
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: pxToRem(80),
                  boxShadow: "none !important",
                }}
                onClick={() => handleSave()}
              >
                <MDTypography variant="h4">{saved ? "Saved!" : "Save"}</MDTypography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          defaultRender
        )}
      </Grid>
      <Grid item xs />
    </Grid>
  );
}

BasicSettingsLayout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.node).isRequired,
  configurables: PropTypes.arrayOf(PropTypes.node).isRequired,
  handleSave: PropTypes.func.isRequired,
  saved: PropTypes.bool.isRequired,
  defaultRender: PropTypes.node.isRequired,
};

export default BasicSettingsLayout;
