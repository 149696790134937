import dayjs from "dayjs";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import darkModeColors from "assets/theme-dark/base/colors";

import SIDataGrid from "components_si/SIDataGridPro";

// Utilities
import { useApi } from "utils/apiUtils";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";
import { MetricsEnum, TiersEnum, evaluateTier } from "utils/tierUtils";
import services from "utils/services";
import AuthorAvatarName from "components_si/AuthorAvatarName";

const { icon, success, warning, error } = darkModeColors;

// TODO: Move this to a utils function
function formatSecondsWithTierBackground(data, type) {
  const status = evaluateTier(data, type);
  if (status === TiersEnum.STier) {
    return (
      <Stack direction="row">
        {/* TODO: Think about making visibility hidden dynamic (e.g., based on user choice, or smartly not to overwhelm user with too many dots) */}
        {/* <Box visibility="hidden"> */}
        <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="3" fill={success.main} />
        </svg>
        {/* </Box> */}
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  if (status === TiersEnum.Average) {
    return (
      <Stack direction="row">
        <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="3" fill={warning.main} />
        </svg>
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  if (status === TiersEnum.Poor) {
    return (
      <Stack direction="row">
        {/* TODO: Think about making visibility hidden dynamic (e.g., based on user choice, or smartly not to overwhelm user with too many dots) */}
        <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="3" fill={error.main} />
        </svg>
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  if (status === TiersEnum.None) {
    return (
      <Stack direction="row">
        <Box visibility="hidden">
          {/* TODO: Think about making visibility hidden dynamic (e.g., based on user choice, or smartly not to overwhelm user with too many dots) */}
          <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="3" fill={icon.main} />
          </svg>
        </Box>
        <Box pl={0.5}>{`${convertMillisecondsToReadableTime(data * 1000)}`}</Box>
      </Stack>
    );
  }
  return <Box>Loading...</Box>;
}

export default function ReviewsDataGrid({ teamId, filtInput, service }) {
  const { data, loading } = useApi({
    url: `/api/teams/${teamId}/reviews_computed_table?service=${service}`,
    defaultData: [],
  });

  const columns = [
    {
      field: "reviewer_name",
      headerName: "Reviewer",
      flex: 1,
      minWidth: 130,
      maxWidth: 200,
      renderCell: (params) => (
        <AuthorAvatarName
          author={params.row.reviewer_name}
          authorAvatarUrl={params.row.reviewer_avatar_url}
        />
      ),
    },
    // {
    //   field: "date_week_start",
    //   headerName: "Week initial review submitted",
    //   flex: 1,
    //   minWidth: 180,
    //   maxWidth: 200,
    // },
    {
      field: "first_review_submit_date",
      headerName: "Initial review date",
      flex: 1,
      type: "date",
      minWidth: 200,
      maxWidth: 250,
      renderCell: (params) =>
        dayjs(params.row.first_review_submit_date).format("YYYY-MM-DD [at] HH:mm"),
    },
    {
      field: "pr_title",
      headerName: "PR title",
      flex: 1,
      minWidth: 400,
    },
    // {
    //   field: "count_changes_requested_reviews",
    //   headerName: '# of "changes requested" reviews',
    //   flex: 1,
    //   minWidth: 180,
    //   maxWidth: 200,
    // },
    // {
    //   field: "count_approved_reviews",
    //   headerName: '# of "approved" reviews',
    //   flex: 1,
    //   minWidth: 180,
    //   maxWidth: 200,
    // },
    {
      field: "count_reviews",
      headerName: "# of reviews",
      minWidth: 120,
    },
    // {
    //   field: "pr_author_name",
    //   headerName: "PR author",
    //   flex: 1,
    //   minWidth: 180,
    //   maxWidth: 250,
    //   renderCell: (params) => (
    //     <AuthorAvatarName
    //       author={params.row.pr_author_name}
    //       authorAvatarUrl={params.row.pr_author_avatar_url}
    //     />
    //   ),
    // },
    {
      field: "total_comments",
      headerName: "Comments/review",
      minWidth: 170,
    },
    // This is expensive to calculate so commenting it out for now
    // {
    //   field: "total_words",
    //   headerName: "Words/review",
    //   minWidth: 120,
    // },
    {
      field: "pickup_time",
      headerName: "Reviewer's pickup time",
      type: "number",
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      renderCell: (params) =>
        formatSecondsWithTierBackground(params.row.pickup_time, MetricsEnum.PickupTime),
    },
  ];
  // If the filter is null, do not render the component
  if (!filtInput) {
    return null;
  }
  return (
    <SIDataGrid
      columns={columns}
      rows={data}
      onRowClick={(params) => window.open(params.row.pr_html_url)}
      initialState={{
        filter: {
          filterModel: {
            items: filtInput,
          },
        },
      }}
      sx={{ border: 0 }}
      loading={loading}
      showExportAndFilter
    />
  );
}

ReviewsDataGrid.defaultProps = {
  filtInput: [],
};

ReviewsDataGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
  filtInput: PropTypes.arrayOf(PropTypes.object),
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
};
