// Material Dashboard 2 React Base Styles
import colors from "assets/theme-dark/base/colors";

const { icon, transparent } = colors;

const iconButton = {
  styleOverrides: {
    root: {
      color: icon.main,
      "&:hover": {
        color: icon.focus,
        backgroundColor: transparent.main,
        cursor: "default",
      },
    },
  },
};

export default iconButton;
