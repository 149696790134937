import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { Risk, riskToBadgeColor } from "../utils/riskUtils";

function RiskFlags({ riskBreakdown }) {
  const rows = [];

  // Display a maximum of three risks for each PR, not to overwhelm user
  const maxChips = Math.min(riskBreakdown.length, 3);

  // TODO: Replace key with a risk ID
  for (let i = 0; i < maxChips; i++) {
    // Do not display risks that are 'Good' (i.e., that are not risks)
    if (riskBreakdown[i].riskLevel !== Risk.Good) {
      rows.push(
        <Chip
          variant="outlined"
          icon={
            <svg width={15} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="3" fill={riskToBadgeColor(riskBreakdown[i].riskLevel)} />
            </svg>
          }
          key={i}
          label={riskBreakdown[i].text}
          color="secondary"
          // TODO: Replace hardcoded fontSize and fontFamily when land on final design
          sx={{ fontSize: "14px", fontFamily: "Roboto", marginRight: "12px" }}
        />
      );
    }
  }

  return <Box>{rows}</Box>;
}

RiskFlags.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  riskBreakdown: PropTypes.any.isRequired,
};

export default RiskFlags;
