import PropTypes from "prop-types";
// import dayjs from "dayjs";
import { useState } from "react";

// Utilities
import { useApi } from "utils/apiUtils";
import { convertMillisecondsToReadableTime } from "utils/timeUtils";

// Setori Components
import DataPopup from "components_si/DataPopup";
import { mdiGithub, mdiGitlab } from "@mdi/js";
import AuthorAvatarName from "components_si/AuthorAvatarName";
import SIDataGrid from "components_si/SIDataGridPro";
import services from "utils/services";
import PickupsDataGrid from "./PickupsDataGrid";

const description = {
  title: "Pickup time",
  shortDefinition: "Time from PR open to first review",
};

export default function MissionDataGrid({ teamId, service, startDate }) {
  const { data: missionData } = useApi({
    url: `/api/teams/${teamId}/pickup_mission?service=${service}`,
    defaultData: [],
  });

  const columns = [
    {
      field: "reviewer_name",
      headerName: "Reviewer",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <AuthorAvatarName
          author={params.row.reviewer_name}
          authorAvatarUrl={params.row.reviewer_avatar_url}
        />
      ),
    },
    {
      field: "number_of_pickups",
      headerName: "# of pickups",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "average_pickup_time",
      headerName: "Average pickup time",
      flex: 1,
      minWidth: 250,
      renderCell: (params) =>
        convertMillisecondsToReadableTime(params.row.average_pickup_time * 1000),
    },
  ];
  const [isDataPopupOpen, setIsDataPopupOpen] = useState(false);
  // State for the filter in the Data Popup
  const [filt, setFilt] = useState(null);

  // TODO: When sprint dates are more flexible, will also need to make this date filter more flexible as well
  const handleClickCard = (reviewerName, dateOnOrAfter) => {
    setFilt([
      {
        id: 1,
        columnField: "reviewer_name",
        operatorValue: "equals",
        value: reviewerName,
      },
      {
        id: 2,
        columnField: "pr_created_at",
        operatorValue: "onOrAfter",
        value: dateOnOrAfter,
      },
    ]);
    setIsDataPopupOpen(true);
  };

  const handleClose = () => {
    setIsDataPopupOpen(false);
    // Note: Can't set this to [], since need to 'flush' the value of the array with null for the data point filters to work
    // TODO: Figure out why this is the case, not 100% sure why this works this way
    setFilt(null);
  };

  return (
    <>
      <DataPopup
        title={description.title}
        definition={description.shortDefinition}
        icon={service === "github" ? mdiGithub : mdiGitlab}
        isOpen={isDataPopupOpen}
        handleClose={handleClose}
      >
        <PickupsDataGrid teamId={teamId} service={service} filtInput={filt} />
      </DataPopup>
      <SIDataGrid
        autoHeight
        sx={{
          py: 1,
          px: 2,
        }}
        columns={columns}
        rows={missionData}
        hideFooter
        onRowClick={(params) => handleClickCard(params.row.reviewer_name, startDate)}
        getRowId={(row) => row.reviewer_name}
      />
    </>
  );
}

MissionDataGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(services.GitServiceEnum)).isRequired,
  startDate: PropTypes.string.isRequired,
};
