import { useEffect } from "react";

import { useLocation, NavLink, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

// @mui material components
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";

// Material Dashboard 2 React context
import { useMaterialUIController, setMiniSidenav, setTransparentSidenav } from "context";

import { useAuth0 } from "@auth0/auth0-react";
import { useApiFetch } from "utils/apiUtils";

function Sidenav({ brand, isNestedMenu, nestedMenuTitle, routes, children, ...rest }) {
  const { logout } = useAuth0();
  const { apiFetch: fetch } = useApiFetch();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const navigate = useNavigate();

  const toggleSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, !(window.innerWidth < 1200));
    }

    /**
     The event listener that's calling the handleMiniSidenav function when loading the page
     This makes sure that the MiniSidenav is shown for screens smaller than 1200 pixels on load
    */
    window.addEventListener("load", handleMiniSidenav);

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("load", handleMiniSidenav);
      window.removeEventListener("resize", handleMiniSidenav);
    };
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      // The icon color here is giving an error when you use it
      // (we don't use titles right now so it's fine)
      returnValue = (
        <MDTypography
          key={key}
          display="block"
          variant="h4"
          color="icon"
          fontWeight="bold"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider key={key} sx={{ mx: 3 }} />;
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, miniSidenav, darkMode }}
    >
      <Box
        sx={{
          pt: 1,
          pb: 1,
          // TODO: Weird number so it aligns with the icons below -- make things align here in a more normal way
          pl: 3.5,
          pr: 4,
        }}
        onClick={isNestedMenu ? () => navigate("/projects/") : toggleSidenav} // perf is better on this compared to going to "/"
      >
        <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={1}>
          <MDTypography variant="h1" color="secondary">
            <Icon sx={{ height: 40, display: "flex", alignItems: "center" }}>
              {isNestedMenu ? "arrow_back" : "menu"}
            </Icon>
          </MDTypography>
          {!miniSidenav ? (
            <Box component={NavLink} to="/" display="flex" alignItems="center">
              {brand && <Box component="img" src={brand} alt="Brand" height="40px" />}
              {isNestedMenu ? <MDTypography variant="h2">{nestedMenuTitle}</MDTypography> : ""}
            </Box>
          ) : null}
        </Stack>
      </Box>
      <List>
        {children}
        {renderRoutes}
        <SidenavCollapse
          name="Log Out"
          icon={<Icon fontSize="small">login</Icon>}
          active={false}
          onClick={async () => {
            await fetch("/api/sign-out");
            logout({ returnTo: window.location.origin });
          }}
        />
      </List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  brand: "",
  isNestedMenu: false,
  nestedMenuTitle: "",
  children: null,
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  brand: PropTypes.string,
  isNestedMenu: PropTypes.bool,
  nestedMenuTitle: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node,
};

export default Sidenav;
