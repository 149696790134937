import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

function AuthorAvatarName({ author, authorAvatarUrl }) {
  return (
    <Box display="flex" alignItems="center" lineHeight={1}>
      <Avatar src={authorAvatarUrl} />
      <Box ml={1} lineHeight={1}>
        {author}
      </Box>
    </Box>
  );
}

AuthorAvatarName.propTypes = {
  author: PropTypes.string.isRequired,
  authorAvatarUrl: PropTypes.string.isRequired,
};

export default AuthorAvatarName;
